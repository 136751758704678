var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "changSession-container",
      attrs: {
        title: "转接",
        width: "30%",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form, "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客服组：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  on: { change: _vm.selectGroup },
                  model: {
                    value: _vm.group,
                    callback: function ($$v) {
                      _vm.group = $$v
                    },
                    expression: "group",
                  },
                },
                _vm._l(_vm.groupList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "客服人员：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.member,
                    callback: function ($$v) {
                      _vm.member = $$v
                    },
                    expression: "member",
                  },
                },
                _vm._l(_vm.memberList, function (item) {
                  return _c(
                    "el-option",
                    {
                      key: item.id,
                      attrs: { label: item.serviceName, value: item.userId },
                    },
                    [
                      _c("span", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(item.serviceName)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            float: "right",
                            color: "#8492a6",
                            "font-size": "13px",
                          },
                        },
                        [_vm._v(_vm._s(item.statusInfo))]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "处理备注:" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 2,
                  placeholder: "请输入内容",
                  maxlength: "150",
                  "show-word-limit": "",
                },
                model: {
                  value: _vm.remarks,
                  callback: function ($$v) {
                    _vm.remarks = $$v
                  },
                  expression: "remarks",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.selectOk()
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }