import Layout from '@/layout';
//商家互动
var articleManagementRouter = {
  path: '/articleManagement',
  component: Layout,
  redirect: '/articleManagement/list',
  alwaysShow: true,
  name: 'articleManagement',
  meta: {
    title: '文章管理-平台端'
  },
  children: [{
    path: 'articleMessage',
    component: function component() {
      return import('@/views/articleManagement/articleMessage.vue');
    },
    name: 'articleMessage',
    meta: {
      title: '文章内容',
      icon: 'list'
    }
  }, {
    path: 'articleLike',
    component: function component() {
      return import('@/views/articleManagement/articleLike.vue');
    },
    name: 'articleLike',
    meta: {
      title: '文章点赞',
      icon: 'list'
    },
    hidden: true
  }, {
    path: 'articleReport',
    component: function component() {
      return import('@/views/articleManagement/reportComplaints/list.vue');
    },
    name: 'articleReport',
    meta: {
      title: '文章投诉举报',
      icon: 'list'
    }
  }, {
    path: 'articleReportDetail',
    component: function component() {
      return import('@/views/articleManagement/reportComplaints/detail.vue');
    },
    name: 'articleReportDetail',
    meta: {
      title: '举报明细列表',
      icon: 'list'
    },
    hidden: true
  }, {
    path: 'messageSettings',
    component: function component() {
      return import('@/views/merchantInteraction/messageSettings.vue');
    },
    name: 'messageSettings',
    meta: {
      title: '留言设置',
      icon: 'list',
      tabName: 'messageSettings'
    }
  }, {
    path: 'messageReportType',
    component: function component() {
      return import('@/views/merchantInteraction/messageReportType.vue');
    },
    name: 'messageReportType',
    meta: {
      title: '举报类型',
      icon: 'list',
      tabName: 'messageReportType'
    }
  }, {
    path: 'reportComplaints',
    component: function component() {
      return import('@/views/merchantInteraction/reportComplaints/list.vue');
    },
    name: 'reportComplaints',
    meta: {
      title: '摄像头内容举报',
      icon: 'list',
      tabName: 'reportComplaints'
    }
  }, {
    path: 'prohibitionsLexicon',
    component: function component() {
      return import('@/views/merchantInteraction/lexicon/prohibitionsLexicon.vue');
    },
    name: 'prohibitionsLexicon',
    meta: {
      title: '违禁词库',
      icon: 'list',
      tabName: 'prohibitionsLexicon'
    }
  }]
};
export default articleManagementRouter;