import "core-js/modules/es6.regexp.match";
import _slicedToArray from "/root/.jenkins/workspace/yuexin_vue_239_backup/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es7.object.entries";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.number.constructor";
import Vue from 'vue';
import crypto from 'crypto';
require("./date-utils.min");
Vue.mixin({
  data: function data() {
    return {
      nowWeek: '',
      nowDate: '',
      nowTime: ''
    };
  },
  methods: {
    // 格式：6-20位，数字/英文/特殊字符
    chekPwd: function chekPwd(password) {
      var reg = /!|@|#|\$|%|\^|&|\*|\-|\+|=|<|_|>|\/|:|;|\'|\"|\||\\|`|~/;
      // 特殊字符判断
      var isT = false;
      if (reg.test(password)) {
        isT = true;
      }
      // 中文判断
      var iscn = false;
      if (/.*[\u4e00-\u9fa5]+.*$/.test(password)) {
        iscn = true;
      }
      if (iscn) {
        return false;
      }
      // 字母判断
      var isen = false;
      if (/^[A-Za-z0-9]+$/.test(password)) {
        isen = true;
      }
      if (!isT && !isen) {
        // this.$message({
        //   message: '要是数字、英文及特殊字符',
        //   type: 'warning'
        // });
        return false;
      }
      var len = password.length;
      var isLen = false;
      // console.log("len:",len)
      if (len >= 6 && len <= 20) {
        isLen = true;
      }
      if (!isLen) {
        // this.$message({
        //   message: '直播密码位数为6-20',
        //   type: 'warning'
        // });
        return false;
      }
      return true;
    },
    IsPhoneFun: function IsPhoneFun() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent;
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info);
      //如果包含“Mobile”（是手机设备）则返回true
      return isPhone;
    },
    setNowTimes: function setNowTimes() {
      var myDate = new Date();
      // console.log(myDate)
      var wk = myDate.getDay();
      var yy = String(myDate.getFullYear());
      var mm = myDate.getMonth() + 1;
      var dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate());
      var hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours());
      var min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes());
      var sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds());
      var weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
      var week = weeks[wk];
      this.nowDate = yy + '-' + mm + '-' + dd;
      this.nowTime = hou + ':' + min + ':' + sec;
      this.nowWeek = week;
    },
    // 获取当前时间 
    _fuCurCalen: function _fuCurCalen() {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      var h = date.getHours();
      var ms = date.getMinutes();
      var s = date.getSeconds();
      return y + '年' + m + '月' + d + '日';
    },
    // 过去第7天的时间
    getlastDay: function getlastDay() {
      var a = new Date() - 3600 * 1000 * 24 * 7;
      return this.formatDateVal(a);
    },
    /**
     * 保留几位有效数字
     */
    signFigures: function signFigures(num) {
      var dec = 1; // 保留两位有效数值 (n-1)
      var toExponential = num.toExponential(dec); // （转成科学计数法）
      var max = Number(toExponential); // 转成普通数字类型
      return max;
    },
    /* 前一个月份第一天 */getCurrentMonthFirst: function getCurrentMonthFirst() {
      var date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - 1);
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return date.getFullYear() + '-' + month + '-' + day;
    },
    /* 当前月份最后一天 */getCurrentMonthLast: function getCurrentMonthLast() {
      var date = new Date();
      var currentMonth = date.getMonth();
      var nextMonth = ++currentMonth;
      var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
      var oneDay = 1000 * 60 * 60 * 24;
      var lastTime = new Date(nextMonthFirstDay - oneDay);
      var month = parseInt(lastTime.getMonth() + 1);
      var day = lastTime.getDate();
      if (month < 10) {
        month = '0' + month;
      }
      if (day < 10) {
        day = '0' + day;
      }
      return date.getFullYear() + '-' + month + '-' + day;
    },
    // 当前月份
    currentMonth: function currentMonth() {
      var now = new Date();
      var y = now.getFullYear();
      var m = now.getMonth() + 1;
      return y + '-' + (m > 10 ? m : '0' + m);
    },
    // 前一天
    preDate: function preDate() {
      var curDate = new Date();
      var preDate = new Date(curDate.getTime() - 24 * 60 * 60 * 1000); // 前一天
      return preDate;
    },
    // 前一个月A款
    preMonthA: function preMonthA() {
      var myDate = new Date();
      myDate.setMonth(myDate.getMonth() - 1);
      var month = myDate.getMonth() + 1;
      var date = myDate.getDate();
      return myDate.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);
    },
    // 前一个月b款
    preMonth: function preMonth() {
      var nowdate = new Date();
      nowdate.setMonth(nowdate.getMonth() - 1);
      var y = nowdate.getFullYear();
      var m = nowdate.getMonth() + 1;
      return y + '-' + m;
    },
    /* 单位的转换 */getCapacityByT: function getCapacityByT(size) {
      var num = 1024;
      var ret = {
        v: 0,
        type: 'G' // 单位
      };
      if (!size) return ret;
      if (size < 1024) {
        ret.v = size;
        return ret;
      }
      ret = {
        v: (size / num).toFixed(2),
        type: 'T'
      };
      return ret;
    },
    encryption: function encryption(key, data) {
      // 图形密码加密aes
      console.log(key, data, 'key, data)----------------------');
      var iv = Buffer.alloc(16);
      var cipher = crypto.createCipheriv('aes-256-cbc', key, iv);
      var output = cipher.update(data, 'utf8', 'base64');
      output += cipher.final('base64');
      return output;
    },
    uuid: function uuid(length) {
      length = length || 8;
      return crypto.randomBytes(parseInt(length / 2)).toString('hex').toUpperCase();
    },
    isFullTextFill: function isFullTextFill(str) {
      var len = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
      if (!str) return '0000';
      str = String(str);
      if (str.length < len) {
        for (var i = 0; i < len - str.length;) {
          str = '0' + str;
        }
      }
      return str;
    },
    // 身份证脱敏
    getIdCarSubStr: function getIdCarSubStr(str) {
      if (str) {
        if (str.length < 12) return str;
        var subStr1 = str.substr(0, 6);
        var subStr2 = str.substr(str.length - 6, 6);
        var subStr = subStr1 + '****' + subStr2;
        return subStr;
      }
    },
    base64: function base64(string) {
      return window.btoa(unescape(encodeURIComponent(string)));
    },
    // 过期时间
    isExpire: function isExpire(expireTime) {
      if (!expireTime) return ' ';
      var timestamp = new Date().getTime();
      var newTime = expireTime - timestamp;
      if (newTime <= 0) {
        // 过期
        return true;
      }
    },
    // 不足N天
    isExpireWithNdays: function isExpireWithNdays(expireTime, n) {
      if (!expireTime) return false;
      var timestamp = new Date().getTime();
      var newTime = expireTime - timestamp;
      if (newTime <= n * 24 * 60 * 60 * 1000) {
        // 不足N天
        return true;
      }
    },
    // 判断字符的长度
    getStrLength: function getStrLength(strVal) {
      var namelength = strVal;
      var len = 0;
      for (var i = 0; i < namelength.length; i++) {
        if (namelength.charCodeAt(i) > 127 || namelength.charCodeAt(i) == 94) {
          len += 2;
        } else {
          len++;
        }
      }
      if (len > 20) {
        // 中文最多只能输入10个字，其他最多只能输入20个字符
        return true;
      }
    },
    // 两个浮点数求和
    accAdd: function accAdd(num1, num2) {
      var r1, r2, m;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      return Math.round(num1 * m + num2 * m) / m;
    },
    // 两个浮点数相减
    accSub: function accSub(num1, num2) {
      var r1, r2, m, n;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      n = r1 >= r2 ? r1 : r2;
      return (Math.round(num1 * m - num2 * m) / m).toFixed(n);
    },
    // 相除
    accDiv: function accDiv(num1, num2) {
      if (!num1 || !num2) return;
      var t1, t2, r1, r2;
      try {
        t1 = num1.toString().split('.')[1].length;
      } catch (e) {
        t1 = 0;
      }
      try {
        t2 = num2.toString().split('.')[1].length;
      } catch (e) {
        t2 = 0;
      }
      r1 = Number(num1.toString().replace('.', ''));
      r2 = Number(num2.toString().replace('.', ''));
      return (r1 / r2 * Math.pow(10, t2 - t1)).toFixed(2);
    },
    // 相乘
    accMul: function accMul(num1, num2) {
      var m = 0;
      var s1 = num1.toString();
      var s2 = num2.toString();
      try {
        m += s1.split('.')[1].length;
      } catch (e) {}
      ;
      try {
        m += s2.split('.')[1].length;
      } catch (e) {}
      ;
      return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m);
    },
    /* 保留2位小数 */onlyOneNumber: function onlyOneNumber(val) {
      if (isNaN(val)) {
        return 0;
      } else {
        return parseInt(val * 100) / 100;
      }
    },
    /* // 保留6位小数  */onlySixNumber: function onlySixNumber(val) {
      if (isNaN(val)) {
        return 0;
      } else {
        return parseInt(val * 1000000) / 1000000;
      }
    },
    /*    // 进度条长度 %  */barWith: function barWith(val1, val2) {
      if (isNaN(val1) || isNaN(val2)) {
        return 0;
      } else {
        var num = Number(val1) / Number(val2) * 100;
        if (num >= 100) {
          return 100;
        } else {
          return Number(val1) / Number(val2) * 100;
        }
      }
    },
    curentTime: function curentTime(type) {
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hh = now.getHours();
      var mm = now.getMinutes();
      var ss = now.getSeconds();
      var clock = year + '-';
      if (month < 10) {
        clock += '0';
      }
      clock += month + '-';
      if (day < 10) {
        clock += '0';
      }
      clock += day + ' ';
      if (hh < 10) {
        clock += '0';
      }
      clock += hh + ':';
      if (mm < 10) clock += '0';
      clock += mm + ':';
      if (ss < 10) clock += '0';
      clock += ss;
      if (type == 1) {
        return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
      } else if (type == 2) {
        return day < 10 ? '0' + day : day;
      } else {
        return clock;
      }
    },
    formatDateVal0: function formatDateVal0(val) {
      return new Date(val).toFormat('YYYY-MM');
    },
    formatDateVal: function formatDateVal(val) {
      /*  //格式化日期 */
      if (val) {
        return new Date(val).toFormat('YYYY-MM-DD');
      }
    },
    formatDateVal2: function formatDateVal2(val) {
      return new Date(val).toFormat('YYYY.MM.DD');
    },
    formatDateVal3: function formatDateVal3(val) {
      return new Date(val).toFormat('MM.DD');
    },
    formatDateTimeVal: function formatDateTimeVal(val) {
      /*  //格式化日期 */
      if (!val) return '';
      return new Date(val).toFormat('YYYY-MM-DD HH24:MI:SS');
    },
    formatDateTimeVal2: function formatDateTimeVal2(val) {
      /* //格式化日期 */
      if (!val) return '';
      return new Date(val).toFormat('YYYY-MM-DD-HH24MISS');
    },
    formatDateTimeVal3: function formatDateTimeVal3(val) {
      /* //格式化日期 */
      if (!val) return '';
      return new Date(val).toFormat('MM-DD HH24:MI:SS');
    },
    formatDateTimeVal4: function formatDateTimeVal4(val) {
      /*  //格式化日期 */
      if (!val) return '';
      return new Date(val).toFormat('HH24:MI');
    },
    formatDateToTimestamp: function formatDateToTimestamp(val) {
      if (!val) return Date.now();
      return new Date(val).getTime();
    },
    formatTimeVal: function formatTimeVal(val) {
      /*  //格式化日期 */
      if (!val) return '';
      return new Date(val).toFormat('HH24-MI');
    },
    countDownTransform: function countDownTransform(props) {
      Object.entries(props).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];
        // Adds leading zero
        var digits = value < 10 ? "0".concat(value) : value;
        props[key] = "".concat(digits);
      });
      return props;
    },
    // 算时长
    formatTime: function formatTime(obj) {
      var second = this.accDiv(obj, 1000);
      var d = 0;
      var h = 0;
      var m = 0;
      if (second > 86400) {
        d = Math.floor(second / 86400);
        second = second % 86400;
      }
      if (second > 3600) {
        h = Math.floor(second / 3600);
        second = second % 3600;
      }
      if (second > 60) {
        m = Math.floor(second / 60);
        second = second % 60;
      }
      var result = '';
      if (d <= 0) {
        d = 0;
      }
      if (h <= 0) {
        h = 0;
      }
      if (window.localStorage.getItem('lang') == 'en') {
        result = d + 'day' + h + 'hours';
      } else {
        result = d + '天' + h + '小时';
      }
      return result;
    },
    fmtTime1: function fmtTime1(timestamp) {
      if (!timestamp) return '';
      if (typeof timestamp === 'string') timestamp = new Date(timestamp).getTime();
      var s = 1000;
      var m = 60 * s;
      var h = 60 * m;
      var d = 24 * h;
      var date = Date.now() - timestamp;
      if (date / d >= 1) return Math.floor(date / d) + '天前';
      if (date / h >= 1) return Math.floor(date / h) + '小时前';
      if (date / m >= 1) return Math.floor(date / m) + '分钟前';
      if (date / s > 1) return Math.floor(date / s) + '秒前';
      return '1分钟前';
    },
    /** *参数都是以周一为基准的
    //上周的开始时间
    console.log(getTimeWeek(7));
    //上周的结束时间
    console.log(getTimeWeek(1));
    //本周的开始时间
    console.log(getTimeWeek(0));
    //本周的结束时间
    console.log(getTimeWeek(-6));***/
    getTimeWeek: function getTimeWeek(n) {
      var now = new Date();
      var year = now.getFullYear();
      // 因为月份是从0开始的,所以获取这个月的月份数要加1才行
      var month = now.getMonth() + 1;
      var date = now.getDate();
      var day = now.getDay();
      // console.log(date);
      // 判断是否为周日,如果不是的话,就让今天的day-1(例如星期二就是2-1)
      if (day !== 0) {
        n = n + (day - 1);
      } else {
        n = n + day;
      }
      if (day) {
        // 这个判断是为了解决跨年的问题
        if (month > 1) {
          month = month;
        }
        // 这个判断是为了解决跨年的问题,月份是从0开始的
        else {
          year = year - 1;
          month = 12;
        }
      }
      now.setDate(now.getDate() - n);
      year = now.getFullYear();
      month = now.getMonth() + 1;
      date = now.getDate();
      // console.log(n);
      var s = year + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date);
      return s;
    },
    fmtTime2: function fmtTime2(timestamp) {
      if (!timestamp) return '';
      if (typeof timestamp === 'string') timestamp = new Date(timestamp).getTime();
      var s = 1000;
      var m = 60 * s;
      var h = 60 * m;
      var d = 24 * h;
      var date = timestamp - Date.now();
      if (date / d >= 1) return Math.floor(date / d) + '天';
      if (date / h >= 1) return Math.floor(date / h) + '小时';
      if (date / m >= 1) return Math.floor(date / m) + '分钟';
      // if (date / s > 1) return Math.floor(date / s) + '秒'
      return '最后1分钟';
    },
    onCopy: function onCopy(e) {
      this.$layer.open({
        content: '复制成功',
        skin: 'msg',
        time: 2000 // 2秒
      });
    },
    // onError (e) {
    //   this.$layer.open({
    //     content: '复制出错，请手动选择复制',
    //     skin: 'msg',
    //     time: 2000 // 2秒
    //   })
    // },
    /* 是否是超级管理员 */
    loginType: function loginType() {
      var type = window.sessionStorage.getItem('loginType');
      return type === '0' ? 'true' : 'false';
    },
    isWeiXin: function isWeiXin() {
      // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
      var ua = window.navigator.userAgent.toLowerCase();
      // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    getSystem: function getSystem() {
      // 获取系统类型
      var systemType = '';
      if (window.navigator.userAgent.indexOf('Android') != -1) {
        systemType = 'android';
      } else if (window.navigator.userAgent.indexOf('iPhone') != -1) {
        systemType = 'ios';
      } else if (window.navigator.userAgent.indexOf('Mac') != -1) {
        systemType = 'mac';
      } else if (window.navigator.userAgent.indexOf('Windows') != -1) {
        systemType = 'win';
      } else if (window.navigator.userAgent.indexOf('Linux') != -1) {
        systemType = 'linux';
      } else {
        systemType = 'wx';
      }
      return systemType;
    },
    isAndroid: function isAndroid() {
      // 获取手机类型
      var u = navigator.userAgent;
      var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; // android终端
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        return 1;
      } else if (isiOS) {
        return 2;
      }
    },
    // 字节转换g
    stringByG: function stringByG(num, type) {
      var g = num / 1024 / 1024 / 1024;
      var res = 0;
      var m = num / 1024 / 1024;
      var k = num / 1024;
      if (g >= 1) {
        // 大于1G
        res = g.toFixed(2);
        return type == 1 ? parseFloat(res) + 'GB' : parseFloat(res);
      }
      if (m >= 1) {
        res = m.toFixed(2);
        return type == 1 ? parseFloat(res) + 'MB' : parseFloat(res);
      }
      if (k >= 1) {
        res = k.toFixed(2);
        return type == 1 ? parseFloat(res) + 'KB' : parseFloat(res);
      }
      return '0';
    },
    // BTD数准换K W
    stringToKOrW: function stringToKOrW(num) {
      var k = num / 1000;
      var res = 0;
      var w = num / 10000;
      if (w >= 1) {
        // 大于1w
        if (window.localStorage.getItem('lang') == 'zh_CN') {
          res = w.toFixed(4);
          return parseFloat(res) + '万';
        } else {
          res = k.toFixed(3);
          return parseFloat(res) + 'K';
        }
      }
      if (k >= 1) {
        // 大于1K
        res = k.toFixed(3);
        return window.localStorage.getItem('lang') == 'zh_CN' ? parseFloat(res) + '千' : parseFloat(res) + 'K';
      }
      return this.onlySixNumber(num);
    },
    mathCeil: function mathCeil(data1, data2) {
      // 分页取得页码
      return Math.ceil(parseInt(data1) / parseInt(data2));
    },
    isPhone: function isPhone(val) {
      var PHONE_RE = /^1(3|4|5|6|7|8|9)\d{9}$/;
      return PHONE_RE.test(val);
    },
    /* 检验中文 */isZhCn: function isZhCn(val) {
      var patthrn = /.*[\u4e00-\u9fa5]+.*$/;
      return patthrn.test(val);
    },
    /* 以字母或数字开头 下划线 @ 减号 4-128 */isUserName: function isUserName(val) {
      var patthrn = /^[a-zA-Z0-9][0-9a-zA-Z_@-]{3,127}$/;
      return patthrn.test(val);
    },
    /* 以字母或数字开头 下划线 @ 减号 4-128 */isUserNameN: function isUserNameN(val) {
      var patthrn = /^[a-zA-Z0-9][0-9a-zA-Z_@-][\u4e00-\u9fa5]{3,127}$/;
      return patthrn.test(val);
    },
    // 校验邮箱
    isEmail: function isEmail(val) {
      var EMAIL_RE = /^[a-z0-9\!\#\$\%\&\'\*\+\/\=\?\^\_\`\{\|\}\~\-]+(?:\.[a-z0-9\!\#\$\%\&\'\*\+\/\=\?\^\_\`\{\|\}\~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?$/i;
      return EMAIL_RE.test(val);
    },
    // isPassword (password) {
    //   return /^\S(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$)(?!^.*[\u4E00-\u9FA5].*$)(!@#$%^&*){4,16}$/.test(password)
    //   // return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,15}$/.test(password)
    // },
    isidNo: function isidNo(idNo) {
      return /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/.test(idNo);
    },
    isNumber: function isNumber(value) {
      return isNaN(value) ? '0' : parseFloat(value);
    },
    fmtPhone: function fmtPhone(phone) {
      return phone ? phone.slice(0, 3) + '****' + phone.slice(-4) : '';
    },
    fmtAddress: function fmtAddress(address) {
      return address ? address.slice(0, 10) + '****' + address.slice(-10) : '';
    },
    fmtEmail: function fmtEmail(email) {
      var e = email.split('@');
      return email ? e[0].slice(0, 1) + '*****@' + e[1] : '';
    },
    getLessTime: function getLessTime(tmp) {
      if (!tmp) tmp = new Date().getTime();
      var time = tmp - new Date().getTime() > 0 ? tmp - new Date().getTime() : 0;
      return time;
    },
    isIdCard: function isIdCard(s) {
      if (s.length >= 15) {
        // 身份证
        return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(s);
      } else {
        if (s.substring(0, 1) == 'H' || s.substring(0, 1) == 'M') {
          // 回乡证
          return /(H|M)(\d{5,10})$/.test(s);
        } else {
          // 台胞证
          return /(^\d{8})$/.test(s);
        }
      }
    },
    getRequestParams: function getRequestParams() {
      var url = window.location.hash;
      var theRequest = new Object();
      if (url.indexOf('?') != -1) {
        var str = url.split('?')[1];
        var strs = str.split('&');
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]);
        }
      }
      return theRequest;
    },
    /**
     * @param me this 指向
     * @param text 按钮显示的文字
     * @param time 按钮倒计时时间
     * @param dis 按钮disable设置
     * @author wds
     *  */
    setIntervalTime: function setIntervalTime(time) {
      // 按钮倒计时
      var interval = window.setInterval(function () {
        time = time + 's';
        --time;
        if (time < 0) {
          window.clearInterval(interval);
        }
      }, 1000);
    },
    // 将后端返回的数据,转换成select的option的项目
    convertSelectOptionItem: function convertSelectOptionItem(items, labelKey, valueKey) {
      if (items.length > 0) {
        items.forEach(function (item) {
          item.label = item[labelKey];
          item.value = item[valueKey];
        });
        return items;
      }
    }
  }
});