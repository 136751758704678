import _createForOfIteratorHelper from "/root/.jenkins/workspace/yuexin_vue_239_backup/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.starts-with";
//
//
//
//
//
//
//
//
//
//
//

import pathToRegexp from 'path-to-regexp';
export default {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route(route) {
      // if you go to the redirect page, do not update the breadcrumbs
      if (route.path.startsWith('/redirect/')) {
        return;
      }
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  computed: {
    visitedViews: function visitedViews() {
      return this.$store.state.tagsView.visitedViews;
    }
  },
  methods: {
    getBreadcrumb: function getBreadcrumb() {
      var _this = this;
      // only show routes with meta.title
      // console.log(this.visitedViews)
      var matched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      });
      var first = matched[0];
      if (!this.isDashboard(first)) {
        if (first.name == "Plat" || first.name == "Shop" || first.name == "Agency" || first.name == "Attract") {
          // 首页去除多余面包屑：首页/首页 => 首页
          matched = [matched[1]];
        } else {
          // 非首页添加首页面包屑：xx/xx => 勾选的首页or默认首页/xx/xx
          matched = [{
            path: this.$store.state.permission.homeRoute,
            meta: {
              title: '首页'
            }
          }].concat(matched);
        }
        //去除首页
        if (matched && matched.length > 0 && matched[0].meta && matched[0].meta.title == "首页") matched.splice(0, 1);
      }
      setTimeout(function () {
        if (matched) {
          var _iterator = _createForOfIteratorHelper(_this.visitedViews),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var item = _step.value;
              if (item.path === _this.$route.path) {
                matched[matched.length - 1] && matched[matched.length - 1].meta && (matched[matched.length - 1].meta.lastTitle = item.title);
                break;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          _this.levelList = matched.filter(function (item) {
            return item.meta && item.meta.title && item.meta.breadcrumb !== false;
          });
        }
      }, 1000);
    },
    isDashboard: function isDashboard(route) {
      var name = route && route.name;
      if (!name || name == "Plat" || name == "Shop" || name == "Agency" || name == "Attract") {
        return false;
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
        path = item.path;
      if (redirect) {
        this.$router.push(redirect);
        return;
      }
      this.$router.push(this.pathCompile(path));
    }
  }
};