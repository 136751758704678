/** When your routing table is too long, you can split it into small modules**/
// 流媒体管理-平台 
import Layout from '@/layout';
var mediaRouter = {
  path: '/medianew',
  component: Layout,
  redirect: '/media/pushlist',
  name: 'MediaNew',
  meta: {
    title: '新流媒体管理-平台',
    icon: 'component'
  },
  children: [{
    path: '/medianew/cameralist_new',
    component: function component() {
      return import('@/views/media/cameralist_new/cameralist');
    },
    name: 'CameraNew',
    meta: {
      title: '新摄像头列表',
      icon: 'role'
    }
  }, {
    path: '/medianew/advertisementVideoMag',
    component: function component() {
      return import('@/views/media/advertisementVideoMag/index');
    },
    hidden: false,
    name: 'AdvertisementVideoMag',
    meta: {
      title: '广告视频管理',
      icon: 'role'
    }
  }, {
    path: '/medianew/advertisementVideoMag/addADVideos',
    component: function component() {
      return import('@/views/media/advertisementVideoMag/addADVideos');
    },
    name: 'AddADVideos',
    meta: {
      title: '添加广告视频',
      icon: 'role'
    },
    hidden: true
  }, {
    path: '/medianew/advertisementVideoMag/videoSetup',
    component: function component() {
      return import('@/views/media/advertisementVideoMag/videoSetup');
    },
    name: 'VideoSetup',
    meta: {
      title: '视频设置',
      icon: 'role'
    },
    hidden: true
  }, {
    path: '/medianew/cameraLiveList',
    component: function component() {
      return import('@/views/media/cameralist_new/cameraLiveList');
    },
    name: 'MonitorList',
    meta: {
      title: '监控列表',
      icon: 'list'
    }
  }, {
    path: '/medianew/companylist',
    component: function component() {
      return import('@/views/media/companylist/companylistNew');
    },
    name: 'CompanyNew',
    meta: {
      title: '企业列表',
      icon: 'group'
    }
  }, {
    path: '/medianew/mediaCompanyDetail',
    component: function component() {
      return import('@/views/media/companylist/mediaCompanyDetail');
    },
    name: 'MediaCompanyDetail',
    activeMenu: '/media/pushlist',
    meta: {
      title: '企业详情',
      noCache: true
    },
    hidden: true
  }, {
    path: '/medianew/categorylist_new',
    component: function component() {
      return import('@/views/media/categorylist_new/categorylist');
    },
    name: 'CategoryNew',
    meta: {
      title: '新类别管理',
      icon: 'group'
    }
  }, {
    path: '/live/companylist',
    component: function component() {
      return import('@/views/live/companylist/companylist');
    },
    name: 'Companylist',
    meta: {
      title: '企业直播',
      icon: 'peoples'
    }
  }, {
    path: '/live/cameralist',
    component: function component() {
      return import('@/views/live/cameralist/cameralist');
    },
    name: 'Cameralist',
    meta: {
      title: '摄像头直播',
      icon: 'role'
    }
  }, {
    path: '/live/webPullUrl',
    component: function component() {
      return import('@/views/live/cameralist/webPullUrl');
    },
    hidden: true,
    name: 'WebPullUrl',
    meta: {
      title: '直播监控视频',
      icon: 'role'
    }
  }, {
    path: '/medianew/monitorVideo',
    component: function component() {
      return import('@/views/media/monitorVideo/index');
    },
    hidden: false,
    name: 'MonitorVideo',
    meta: {
      title: '监控视频',
      icon: 'role',
      noCache: true
    }
  }, {
    path: '/medianew/liveHistory',
    component: function component() {
      return import('@/views/media/liveHistory/index');
    },
    hidden: false,
    name: 'LiveHistory',
    meta: {
      title: '历史直播',
      icon: 'role',
      noCache: true
    }
  }, {
    path: '/medianew/screenshot',
    component: function component() {
      return import('@/views/media/mediaScreenshot/index');
    },
    hidden: false,
    name: 'mediaScreenshot',
    meta: {
      title: '截图管理',
      icon: 'role'
    }
  }, {
    path: '/medianew/liveList',
    component: function component() {
      return import('@/views/media/liveList');
    },
    name: 'LiveList',
    meta: {
      title: '直播列表',
      icon: 'role'
    }
  }, {
    path: '/medianew/liveCamera',
    component: function component() {
      return import('@/views/media/liveCamera');
    },
    name: 'LiveCamera',
    hidden: true,
    meta: {
      title: '直播摄像头',
      icon: 'role'
    }
  }, {
    path: '/medianew/liveStatistics',
    component: function component() {
      return import('@/views/media/liveStatistics');
    },
    name: 'LiveStatistics',
    meta: {
      title: '统计总览',
      icon: 'role'
    }
  }]
};
export default mediaRouter;