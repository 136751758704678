var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-picture", staticStyle: { "margin-left": "10px" } },
    [
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "el-icon-loading",
      }),
      _vm._v(" "),
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShow1(),
              expression: "isShow1()",
            },
          ],
          ref: "upload",
          staticClass: "upload-file",
          attrs: {
            id: "upload",
            headers: _vm.headers,
            accept: ".mp4,.mov",
            action: "#",
            "file-list": _vm.fileList,
            "on-success": _vm.handleAvatarSuccess,
            "auto-upload": true,
            "before-upload": _vm.beforeAvatarUpload,
            "on-change": _vm.handleUploadChange,
            "on-progress": _vm.handleUploadProgress,
            limit: 1,
            multiple: false,
            "on-error": _vm.error,
            "show-file-list": false,
            "http-request": _vm.httpRequest,
          },
        },
        [
          _c("img", {
            staticStyle: { width: "27px", "margin-left": "8px" },
            attrs: { src: require("./sendvideo.png") },
          }),
        ]
      ),
      _vm._v(" "),
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShow2(),
            expression: "isShow2()",
          },
        ],
        staticStyle: { width: "27px", "margin-left": "8px" },
        attrs: { src: require("./sendvideo.png") },
        on: { click: _vm.noSessionTip },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }