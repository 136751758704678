//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
  <date-picker @searchFn="dateSearch"></date-picker>
  dateSearch 当前页面的日期查询函数，需要接收参数，区分跟页面的查询函数，日期选择后的值，数组类型
  dateSearch(val) {
    this.form.createdTime = val
    this.current = 1
    this.getData()
  }
*/
export default {
  name: 'DatePicker',
  components: {},
  props: {
    // 回显日期
    timeDate: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  watch: {
    timeDate: function timeDate(val, old) {
      console.log(val);
      this.dateValue = val;
    }
  },
  data: function data() {
    return {
      dateValue: this.timeDate,
      pickerOptions: {
        disabledDate: function disabledDate(time) {
          // return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '最近30天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近60天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 60);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近90天',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      } //快捷周期
    };
  },
  methods: {
    handleSearch: function handleSearch() {
      this.$emit('searchFn', this.dateValue);
    },
    setDate: function setDate(val) {
      this.dateValue = val;
    },
    clearDate: function clearDate() {
      this.dateValue = [];
    }
  }
};