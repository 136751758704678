/** When your routing table is too long, you can split it into small modules **/
// 发现
import Layout from '@/layout';
var findRouter = {
  path: '/find2',
  component: Layout,
  redirect: '/find2/set',
  name: 'Find2',
  meta: {
    title: '站长管理',
    icon: 'tree'
  },
  children: [{
    path: '/find2/set',
    component: function component() {
      return import('@/views/find2');
    },
    name: 'F2FindSet',
    meta: {
      title: '内容设置',
      icon: 'tree'
    }
  }, {
    path: '/find2/siteuser',
    component: function component() {
      return import('@/views/find2/siteuser');
    },
    name: 'F2SiteUser',
    meta: {
      title: '站长列表',
      icon: 'tree'
    }
  }, {
    path: '/find2/contentList/index',
    component: function component() {
      return import('@/views/find2/contentList/index');
    },
    name: 'F2ContentList',
    meta: {
      title: '内容列表'
    }
  }, {
    path: '/find2/like',
    component: function component() {
      return import('@/views/find2/like');
    },
    name: 'F2Like',
    meta: {
      title: '点赞列表'
    },
    hidden: true
  }, {
    path: '/find2/comment',
    component: function component() {
      return import('@/views/find2/comment/comment');
    },
    name: 'F2Comment',
    meta: {
      title: '内容评论',
      icon: 'tree'
    }
  }, {
    path: '/find2/content',
    component: function component() {
      return import('@/views/find2/classify/content');
    },
    name: 'F2Classify',
    meta: {
      title: '内容分类',
      icon: 'tree'
    }
  }, {
    path: '/find2/lexicon',
    component: function component() {
      return import('@/views/find/lexicon/list');
    },
    name: 'F2Lexicon',
    meta: {
      title: '违禁词库',
      icon: 'tree'
    }
  }

  //*/
  ]
};
export default findRouter;