// 订单列表
import request from '@/utils/request';

// 发货接口
export function electronicSubmit(data) {
  return request({
    url: '/order/sys/order/electronic/submit',
    method: 'post',
    data: data
  });
}

// web-订单列表-商铺
export function getOrderList(data) {
  return request({
    url: '/order/sys/order/list',
    method: 'post',
    data: data
  });
}
// web-订单列表-平台
export function getPlatOrderList(data) {
  return request({
    url: '/order/sys/order/sys/list',
    method: 'post',
    data: data
  });
}
export function exportOrderData(data) {
  return request({
    url: '/sysadmin/excel/platform/orderList/excel',
    method: 'post',
    data: data
  });
}
//web-客服（平台/商铺）-订单列表
export function getAppOrderList(params) {
  return request({
    url: '/order/sys/order/list?memberId=' + params.memberId + '&orderStatus=' + params.orderStatus + '&current=' + params.current + '&size=' + params.size,
    method: 'get'
  });
}

// web-客服-订单统计
export function serviceCensusSum(params) {
  return request({
    url: "/order/sys/order/member/census",
    method: 'get',
    params: params
  });
}

// web-订单发货列表
export function getDeliverList(params) {
  return request({
    url: "/order/sys/order/deliver/list",
    method: 'get',
    params: params
  });
}
export function getDeliverListPost(data) {
  return request({
    url: "/order/sys/order/deliver/list",
    method: 'post',
    data: data
  });
}

//web - 订单发货
export function getDeliver(data) {
  return request({
    url: '/order/sys/order/deliver',
    method: 'post',
    data: data
  });
}

//web - 订单发货-new
export function getDeliverNew(data, obj) {
  var url = obj ? '/nearby/admin/order/logisticsDelivery' : '/order/sys/order/deliver';
  return request({
    url: url,
    method: 'post',
    data: data
  });
}

//web-订单详情
export function getOrderId(orderSn) {
  return request({
    url: "/order/sys/order/platform/".concat(orderSn),
    method: 'get'
  });
}

//web-订单统计-商铺
export function censusSum(params) {
  return request({
    url: "/order/sys/order/census",
    method: 'get',
    params: params
  });
}
//web-订单统计-平台
export function censusPlatSum() {
  return request({
    url: "/order/sys/order/sys/census",
    method: 'get'
  });
}
//web-订单合并
export function orderMerge(data) {
  return request({
    url: "/order/sys/order/orderMerge",
    method: 'post',
    data: data
  });
}

//web-修改发票信息
export function invoiceId(id, data) {
  return request({
    url: "/order/sys/order/invoice/".concat(id),
    method: 'put',
    data: data
  });
}

//web-修改收货地址
export function addressId(id, data) {
  return request({
    url: "/order/sys/order/address/".concat(id),
    method: 'put',
    data: data
  });
}

//web-关闭订单
export function closeOrder(data) {
  return request({
    url: "/order/sys/order/close",
    method: 'put',
    data: data
  });
}
//web-备注订单
export function recordOrder(data) {
  return request({
    url: "/order/sys/order/record",
    method: 'post',
    data: data
  });
}
//web-删除订单
export function delectOrder(data) {
  return request({
    url: "/order/sys/order/delect",
    method: 'put',
    data: data
  });
}

//web-订单金额修改
export function updateCost(data) {
  return request({
    url: "/order/sys/order/updateCost",
    method: 'post',
    data: data
  });
}

//web-取消订单
export function cancelOrder(data) {
  return request({
    url: "/order/sys/order/cancel",
    method: 'put',
    data: data
  });
}

//物流信息查询接口 - 订单追踪
export function logisticsInfo(params) {
  return request({
    url: "/sysadmin/area/logistics/info",
    method: 'get',
    params: params
  });
}

//获取所有发票内容
export function invoiceList() {
  return request({
    url: "/sysadmin/invoice/list",
    method: 'get'
  });
}

//web订单操作记录列表
export function getRecordList(id) {
  return request({
    url: "/order/sys/order/getRecordAll/".concat(id),
    method: 'get'
  });
}
// 客服聊天模块-获取售后信息列表
export function getUserRefundOrderList(data) {
  return request({
    url: '/order/saleService/service/userRefundOrderList',
    method: 'post',
    data: data
  });
}

//  订单列表 导出订单
export function getOrderListExcel(data) {
  return request({
    url: '/sysadmin/excel/shop/orderList/excel',
    method: 'post',
    data: data
  });
}
//  订单列表 订单配货单  导出订单
export function getShippingAddressExcel(params) {
  return request({
    url: '/sysadmin/excel/shop/shippingAddress/excel',
    method: 'get',
    params: params
  });
}
//  订单列表 商品配货单  导出订单
export function getGoodsShippingAddressExcel(params) {
  return request({
    url: '/sysadmin/excel/shop/goodsShippingAddress/excel',
    method: 'get',
    params: params
  });
}
// 获取相似订单（用于合并发货）
//orderIds 格式：数组，每个元素就是代表订单id
export function orderSimilarOrder(orderIds) {
  var p = "";
  for (var i = 0; i < orderIds.length; i++) {
    if (p != "") p += "&";
    p += "childOrderIds=" + orderIds[i];
  }
  return request({
    url: '/order/sys/order/similarOrder?' + p,
    method: 'get'
  });
}

// 合并发货
/*
data结构
[
  {
    "childOrderId": [
      "1433337185330802690"
    ]
  },{
    "childOrderId": [
      "1433337185330802690","1432910918156648450","1432912431004352514"
    ]
  }
]
*/
export function orderSimilarOrderDeliver(data) {
  return request({
    url: '/order/sys/order/deliver/list',
    method: 'post',
    data: data
  });
}

//  过滤无效发货订单
export function chekInvalidChildOrderList(orderIds) {
  var p = "";
  for (var i = 0; i < orderIds.length; i++) {
    if (p != "") p += "&";
    p += "childOrderIds=" + orderIds[i];
  }
  return request({
    url: '/order/sys/order/invalid/childOrderList?' + p,
    method: 'get'
  });
}

//物流信息查询接口-获取子订单物流休息
export function orderDetailLogistics(orderId) {
  return request({
    url: '/order/order/orderDetailLogistics/child/' + orderId,
    method: 'get'
  });
}
// 获取子订单物流标题信息
export function getChildOrderLogisticsTab(id) {
  return request({
    url: '/order/sys/order/getChildOrderLogisticsTab?orderId=' + id,
    method: 'get'
  });
}
export function getChildOrderLogisticsTab2(expressCompanyCode, expressCode) {
  return request({
    url: '/order/order/orderDetailLogistics/child/?expressCompanyCode=' + expressCompanyCode + '&expressCode=' + expressCode,
    method: 'get'
  });
}
// 客服浮窗订单列表
export function wechatOrderList(data) {
  return request({
    url: '/sysadmin/order/wechatOrderList',
    method: 'post',
    params: data
  });
}
// 客服浮窗订单列表-统计
export function wechatOrderListStatus(data) {
  return request({
    url: '/sysadmin/order/status',
    method: 'post',
    params: data
  });
}

//订单物流信息修改
export function updateOrderLogisticsCode(data) {
  return request({
    url: '/order/sys/order/orderLogisticsCode',
    method: 'put',
    data: data
  });
}