import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getStore, getToken } from '@/utils/auth';
import { getHeaderMessageList, unReadHeaderCount } from '@/api/personalCenter/messageList';
import defaultSettings from '@/settings';
export default {
  name: 'HeaderMessage',
  data: function data() {
    return {
      unreadNum: 0,
      //未读消息数量
      // 列表数据
      messageList: [],
      isLogin: false,
      userId: '',
      WebSocketReconnectI: 0 //记录重连接次数
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;
    setTimeout(function () {
      // console.log('登录之后立即加载顶部的消息通知栏') 
      _this.isLogin = true;
      var userInfo = JSON.parse(getStore('userInfo'));
      _this.userId = userInfo.id;
      // console.log('登录之后getToken', getToken())
      if (_this.userId) {
        _this.getWebSocketMsg();
      }
      _this.getHeaderMsgList();
      var that = _this;
      _this.bus.$on('updateHeaderMessage', function (data) {
        // console.log('updateHeaderMessage',data)
        if (data == 1) {
          that.getHeaderMsgList();
        }
      });
    }, 2000);
  },
  created: function created() {
    // 接口请求不要放这里，为了缓解主页显示压力
  },
  methods: {
    // 跳转到消息通知列表页
    linkList: function linkList() {
      this.$router.push({
        path: '/personalCenter/messageCenter'
      });
    },
    // 跳转到消息通知详情页
    linkDetail: function linkDetail(obj) {
      var detail = JSON.stringify(obj);
      this.$router.push({
        path: '/personalCenter/messageCenterDetail',
        query: {
          detail: detail
        }
      });
    },
    // 获取头部的消息通知栏列表
    getHeaderMsgList: function getHeaderMsgList() {
      var _this2 = this;
      getHeaderMessageList().then(function (res) {
        // console.log('消息通知栏列表', res)
        if (_this2.$processingResData(res)) {
          _this2.messageList = res.data;
        }
      });
      this.unReadCount();
    },
    // 未读消息数量
    unReadCount: function unReadCount() {
      var _this3 = this;
      unReadHeaderCount().then(function (res) {
        // console.log('未读消息数量', res)
        if (_this3.$processingResData(res)) {
          _this3.unreadNum = res.data;
        }
      });
    },
    // 获取中间的域名或IP地址
    getDomain: function getDomain(url) {
      var domain = url.split('/'); //以“/”进行分割

      if (domain[2]) {
        domain = domain[2];
      } else {
        domain = ''; //如果url不正确就取空
      }
      return domain;
    },
    // 获取WebSocket实时消息通知
    getWebSocketMsg: function getWebSocketMsg() {
      var that = this;
      if (typeof WebSocket == 'undefined') {
        // console.log(
        //   '当您当前使用的浏览器无法接收实时消息通知，建议使用谷歌浏览器'
        // )
        that.$message.warning('您当前使用的浏览器无法接收实时消息通知，建议使用谷歌浏览器');
      } else {
        // 实例化socket，接收后端所推送的消息websocket接口链接
        // let websocketUrl =
        //   'ws://' +
        //   this.getDomain(defaultSettings.websocketBaseURL) +
        //   '/sysadmin/messageServer/'
        var websocketUrl = defaultSettings.websocketBaseURL + 'sysadmin/messageServer/';
        var socketUrl = websocketUrl + this.userId + '?websocket-token=' + getToken();
        // this.socket = new WebSocket(socketUrl, [getToken()])
        this.socket = new WebSocket(socketUrl);
        // 监听socket打开
        this.socket.onopen = function () {
          // console.log('WebSocket已打开')
        };
        // 监听socket消息接收
        this.socket.onmessage = function (msg) {
          // console.log('监听socket消息接收 msg', msg)
          // 转换为json对象
          // const data = JSON.parse(msg.data)
          // 调用实时刷新消息通知列表的方法
          if (that.isLogin && msg.data && msg.data != '连接成功') {
            var data = JSON.parse(msg.data);
            //如果收到退出
            /*
            {
              "content": "用户账户异常",
              "msgNotifyType": 17,
              "titleName": "用户账户异常",
              "userIds": [
                "1379617678753685506"
              ]
            }
            */
            if (data.msgNotifyType && data.msgNotifyType == 17) {
              that.$alert('当前帐号异常，请与管理员联系', '警告', {
                confirmButtonText: '确定',
                callback: function callback(action) {
                  that.$store.dispatch('user/logout').then(function () {
                    that.$router.push('/login');
                    that.bus.$emit('logoutStatus', 1); //跨组件传值给客服组件
                  });
                }
              });
            } else if (data.msgNotifyType && data.msgNotifyType == 18) {
              that.$alert('当前帐号被禁用，请与管理员联系', '警告', {
                confirmButtonText: '确定',
                callback: function callback(action) {
                  that.$store.dispatch('user/logout').then(function () {
                    that.$router.push('/login');
                    that.bus.$emit('logoutStatus', 1); //跨组件传值给客服组件
                  });
                }
              });
            } else {
              //如果是收到信息
              that.messageList.unshift({
                id: data.id,
                titleName: data.titleName,
                content: data.content,
                status: data.status,
                createdTime: data.createdTime
              });
              // getHeaderMessageList().then((res) => {
              //   // console.log('消息通知栏列表', res)
              //   if (this.$processingResData(res)) {
              //     this.messageList = res.data
              //   }
              // })
              that.unreadNum++;
            }
          }
        };
        // 监听socket错误
        this.socket.onerror = function () {
          // that.$message.error('服务器WebSocket错误，无法接收实时消息通知')
          console.log('服务器WebSocket错误，无法接收实时消息通知');
        };
        // 监听socket关闭
        this.socket.onclose = function () {
          console.log('WebSocket已关闭');
          if (that.WebSocketReconnectI++ < 4) {
            //如果重连4次就不连接
            console.log('尝试重新连接#' + that.WebSocketReconnectI);
            that.getWebSocketMsg();
          }
        };
      }
    }
  },
  watch: {}
};