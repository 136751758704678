// 活动列表-门店

import Layout from '@/layout';
var shopoperationRouter = {
  path: '/shopoperation',
  component: Layout,
  redirect: '/shopoperation/operationactivitylist',
  alwaysShow: true,
  name: 'ShopOperation',
  meta: {
    title: '门店运营',
    icon: 'role'
  },
  children: [{
    path: 'operationactivitylist',
    component: function component() {
      return import('@/views/shopoperation/operationactivitylist/nearbystores.vue');
    },
    name: 'nearbystoresShopList',
    meta: {
      title: '活动列表-附近门店',
      icon: 'peoples'
    }
  }, {
    path: '/nearbystoresShopList/nearbystoresShopListDetail',
    component: function component() {
      return import('@/views/shopoperation/operationactivitylist/nearbystoresdetail.vue');
    },
    name: 'nearbystoresShopListDetail',
    meta: {
      title: '折扣活动详情',
      noCache: true
    },
    hidden: true
  }, {
    path: 'ActivityStores',
    component: function component() {
      return import('@/views/shopoperation/activeStoresList/activeliststores.vue');
    },
    name: 'ActivityStores',
    meta: {
      title: '活动门店列表',
      icon: 'peoples'
    }
  }, {
    path: '/nearbystoresShopList/ShopEditdiscountactivity',
    component: function component() {
      return import('@/views/shopoperation/operationactivitylist/shopeditdiscountactivity.vue');
    },
    name: 'ShopEditdiscountactivity',
    meta: {
      title: '新增折扣活动',
      noCache: true
      // activeMenu: '/shopoperation/shopeditdiscountactivity'
    },
    hidden: true
  }]
};
export default shopoperationRouter;