import Layout from '@/layout';
var orderPlatRouter = {
  path: '/orderPlat',
  component: Layout,
  redirect: '/orderPlat/order-list',
  name: 'orderPlat',
  meta: {
    title: '订单管理-平台',
    icon: 'component'
  },
  children: [{
    path: 'phoneOrders',
    component: function component() {
      return import('@/views/phone/phone-list');
    },
    name: 'PhoneOrders',
    meta: {
      title: '话费订单',
      icon: 'list'
    }
  }, {
    path: 'phoneDetail',
    component: function component() {
      return import('@/views/phone/phone-list/detail');
    },
    name: 'PhoneDetail',
    meta: {
      title: '话费详情',
      icon: 'documentation',
      noCache: true
    },
    hidden: true
  }, {
    path: 'phoneSetting',
    component: function component() {
      return import('@/views/phone/phone-list/setting');
    },
    name: 'PhoneSetting',
    meta: {
      title: '充值设置',
      icon: 'edit'
    },
    hidden: true
  }, {
    path: 'planeList',
    component: function component() {
      return import('@/views/plane');
    },
    name: 'PlaneList',
    meta: {
      title: '飞机票订单',
      icon: 'list'
    }
  }, {
    path: '/planeDetail',
    component: function component() {
      return import('@/views/plane/plane-detail');
    },
    name: 'PlaneDetail',
    meta: {
      title: '飞机票详情',
      icon: 'list',
      noCache: true,
      activeMenu: '/orderPlat/planeList'
    },
    hidden: true
  }, {
    path: 'ticketList',
    component: function component() {
      return import('@/views/ticket/ticketList');
    },
    name: 'TicketList',
    meta: {
      title: '火车票订单',
      icon: 'list'
    }
  }, {
    path: 'ticketDetail',
    component: function component() {
      return import('@/views/ticket/ticketDetail');
    },
    name: 'TicketDetail',
    meta: {
      title: '火车票详情',
      noCache: true,
      activeMenu: '/orderPlat/ticketList'
    },
    hidden: true
  }, {
    path: '/orderPlat/order-list',
    component: function component() {
      return import('@/views/orderPlat/order-list');
    },
    name: 'OrderPlatList',
    meta: {
      title: '订单列表',
      icon: 'list'
    }
  }, {
    path: '/orderPlat/order-set',
    component: function component() {
      return import('@/views/orderPlat/order-set/order-set');
    },
    name: 'orderSet',
    meta: {
      title: '订单设置',
      icon: 'role'
    }
  }, {
    path: '/orderPlat/bill-set',
    component: function component() {
      return import('@/views/orderPlat/order-set/bill-set');
    },
    name: 'billSet',
    meta: {
      title: '发票内容',
      icon: 'role'
    }
  }, {
    path: '/orderPlat/order-detail',
    component: function component() {
      return import('@/views/orderPlat/order-list/order-detail');
    },
    name: 'OrderPlatDetail',
    meta: {
      // title: '订单详情',
    },
    hidden: true
  }, {
    path: '/orderPlat/order-traver',
    component: function component() {
      return import('@/views/orderPlat/order-list/component/order-traver');
    },
    name: 'OrderPlatRaver',
    meta: {
      // title: '订单追踪',
    },
    hidden: true
  }, {
    path: '/orderPlat/order-cancel',
    component: function component() {
      return import('@/views/orderPlat/order-list/order-cancel');
    },
    name: 'OrderPlatCancel',
    meta: {
      // title: '取消订单',
    },
    hidden: true
  }, {
    path: '/orderPlat/order-apply',
    component: function component() {
      return import('@/views/orderPlat/order-apply/index');
    },
    name: 'orderPlatApply',
    meta: {
      title: '发票申请',
      icon: 'role'
    }
  }]
};
export default orderPlatRouter;