// 优惠券管理 - 平台
import Layout from '@/layout';
var couponPlatRouter = {
  path: '/couponPlat',
  component: Layout,
  redirect: '/couponPlat/couponList',
  alwaysShow: true,
  name: 'CouponPlat',
  meta: {
    title: '优惠券管理-平台',
    icon: 'documentation'
  },
  children: [{
    path: '/couponPlat/couponList',
    component: function component() {
      return import('@/views/couponPlat/couponList');
    },
    name: 'CouponPlatList',
    meta: {
      title: '优惠券列表',
      icon: 'list'
    }
  }, {
    path: '/couponPlat/couponDetail',
    component: function component() {
      return import('@/views/couponPlat/couponDetail');
    },
    name: 'CouponPlatDetail',
    hidden: true,
    meta: {
      title: '优惠券明细',
      icon: 'excel',
      activeMenu: '/couponPlat/couponList'
    }
  }, {
    path: '/couponPlat/couponCreate',
    component: function component() {
      return import('@/views/couponPlat/couponCreate');
    },
    name: 'CouponPlatCreate',
    hidden: true,
    meta: {
      title: '优惠券编辑',
      icon: 'edit'
    }
  },
  // --== 门店优惠券 ==--
  {
    path: '/couponPlat/storeList',
    component: function component() {
      return import('@/views/couponPlat/storeList');
    },
    name: 'CouponPlatStoreList',
    meta: {
      title: '门店优惠券',
      icon: 'list'
    }
  }, {
    path: '/couponPlat/storeListAdd',
    component: function component() {
      return import('@/views/couponPlat/storeListAdd');
    },
    name: 'CouponPlatStoreListAdd',
    hidden: true,
    meta: {
      title: '添加优惠券',
      icon: 'edit'
    }
  }, {
    path: '/couponPlat/storeListDetailed',
    component: function component() {
      return import('@/views/couponPlat/storeListDetailed');
    },
    name: 'CouponPlatstoreListDetailed',
    hidden: true,
    meta: {
      title: '门店优惠券领取明细',
      icon: 'edit'
    }
  }, {
    path: '/couponPlat/orderInfo',
    component: function component() {
      return import('@/views/couponPlat/orderInfo');
    },
    name: 'CouponPlatstoreListOrderInfo',
    hidden: true,
    meta: {
      title: '订单详情',
      noCache: true,
      icon: 'edit'
    }
  }]
};
export default couponPlatRouter;