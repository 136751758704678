/** When your routing table is too long, you can split it into small modules **/
// 平台端-首页
import Layout from '@/layout';
var platHomeRouter = {
  path: '/plat',
  component: Layout,
  redirect: '/plat/home',
  name: 'Plat',
  meta: {
    title: '首页-平台',
    icon: 'dashboard'
  },
  children: [{
    path: 'home',
    component: function component() {
      return import('@/views/platHome/home');
    },
    name: 'PlatHome',
    meta: {
      title: '首页',
      noCache: true,
      isCache: true,
      icon: 'dashboard',
      affix: true
    }
  }, {
    path: 'autoSign',
    component: function component() {
      return import('@/views/platHome/autoSign');
    },
    name: 'AutoSign',
    meta: {
      title: '自动签署',
      noCache: true,
      isCache: true,
      icon: 'dashboard'
    },
    hidden: true
  }]
};
export default platHomeRouter;