import Layout from '@/layout';
var searchPlatRouter = {
  path: '/searchPlat',
  component: Layout,
  redirect: '/searchPlat/list',
  // 我们暂时不需要重定向
  alwaysShow: true,
  name: 'SearchPlat',
  meta: {
    title: '搜索',
    icon: 'component'
  },
  children: [{
    path: '/hotSearchSet',
    component: function component() {
      return import('@/views/search/hotSearchSet.vue');
    },
    name: 'HotSearchSet',
    meta: {
      title: '热搜设置',
      icon: 'list'
    }
  }]
};
export default searchPlatRouter;