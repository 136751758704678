var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "chat-v-container tag9",
      style: { "background-image": "url(" + _vm.bakimg + ")" },
      on: { click: _vm.c1 },
    },
    [_c("img", { attrs: { src: require("./play.png") } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }