/** When your routing table is too long, you can split it into small modules **/
// 站长管理
import Layout from '@/layout';
var agentUserRouter = {
  path: '/agent',
  component: Layout,
  alwaysShow: true,
  redirect: '/agent/contentList/index',
  name: 'agentUserManagement',
  meta: {
    title: '内容管理-站长',
    icon: 'peoples'
  },
  children: [{
    path: '/agent/contentList/index',
    component: function component() {
      return import('@/views/agent/contentList/index');
    },
    name: 'AgentContentList',
    meta: {
      title: '内容列表'
    }
  }, {
    path: '/agent/like',
    component: function component() {
      return import('@/views/agent/like');
    },
    name: 'AgentLike',
    meta: {
      title: '点赞列表'
    },
    hidden: true
  }, {
    path: '/agent/fav',
    component: function component() {
      return import('@/views/agent/fav');
    },
    name: 'AgentFa',
    meta: {
      title: '收藏列表'
    },
    hidden: true
  }, {
    path: '/agent/comment',
    component: function component() {
      return import('@/views/agent/comment/comment');
    },
    name: 'AgentComment',
    meta: {
      title: '内容评论',
      icon: 'tree'
    }
  }, {
    path: '/agent/fans',
    component: function component() {
      return import('@/views/agent/fans/index');
    },
    name: 'AgentFans',
    meta: {
      title: '粉丝列表',
      icon: 'tree'
    }
  }]
};
export default agentUserRouter;