import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
var Base64 = {
  //加密
  encode: function encode(str) {
    if (str === undefined || str === "" || str === null) {
      return str;
    }
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
      return String.fromCharCode('0x' + p1);
    }));
  },
  //解密
  decode: function decode(str) {
    if (str === undefined || str === "" || str === null) {
      return str;
    }
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
};
export default Base64;