// 商铺管理
import Layout from '@/layout';
var companyRouter = {
  path: '/company',
  component: Layout,
  redirect: '/company/companyInfo',
  alwaysShow: true,
  name: 'Company',
  meta: {
    title: '企业管理-商铺',
    icon: 'role'
  },
  children: [{
    path: 'companyInfo',
    component: function component() {
      return import('@/views/companyPlat/companyDetail');
    },
    name: 'CompanyInfo',
    meta: {
      title: '企业信息',
      icon: 'peoples',
      noCache: true
    }
  }, {
    path: 'companyInfoNew',
    component: function component() {
      return import('@/views/companyPlat/companyDetailNew');
    },
    name: 'CompanyInfoNew',
    meta: {
      title: '企业信息新',
      icon: 'peoples',
      noCache: true
    }
  }, {
    path: 'companyInfoEdit',
    component: function component() {
      return import('@/views/companyPlat/editCompany');
    },
    name: 'CompanyInfoEdit',
    meta: {
      title: '企业信息编辑',
      icon: 'peoples',
      activeMenu: '/company/companyInfo'
    },
    hidden: true
  }, {
    path: 'editLicense',
    component: function component() {
      return import('@/views/companyPlat/editLicense');
    },
    name: 'EditLicense',
    meta: {
      title: '其他证照-编辑',
      noCache: true,
      activeMenu: '/company/companyInfo'
    },
    hidden: true
  }, {
    path: 'subAccount',
    component: function component() {
      return import('@/views/company/subAccount');
    },
    name: 'SubAccount',
    meta: {
      title: '子账号管理',
      icon: 'peoples'
    }
  }, {
    path: 'setPermission',
    component: function component() {
      return import('@/views/company/setPermission');
    },
    name: 'setPermission',
    meta: {
      title: '权限设置',
      icon: 'peoples'
    },
    hidden: true
  }, {
    path: 'editSubAccount',
    component: function component() {
      return import('@/views/company/editSubAccount');
    },
    name: 'EditSubAccount',
    meta: {
      title: '编辑子账号',
      icon: 'peoples'
    },
    hidden: true
  }, {
    path: 'shopApply',
    component: function component() {
      return import('@/views/company/shopApply');
    },
    name: 'CompanyShopApply',
    meta: {
      title: '商铺入驻申请',
      icon: 'peoples'
    }
    // hidden: true
  }, {
    path: 'editMediaDetail',
    component: function component() {
      return import('@/views/companyPlat/editMediaDetail');
    },
    name: 'shopEditMediaDetail',
    meta: {
      title: '流媒体编辑',
      noCache: true
    },
    hidden: true
  }]
};
export default companyRouter;