var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dialogVisible
    ? _c(
        "div",
        { staticClass: "videoPlayer-container" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "视频播放",
                visible: _vm.dialogVisible,
                modal: _vm.modal,
                width: _vm.getMibileQuery(1) ? "100%" : "970px",
                center: true,
                top: _vm.getMibileQuery(1) ? "2vh" : "5vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogVisible = $event
                },
                close: function ($event) {
                  return _vm.closeCallback()
                },
              },
            },
            [
              _c("div", { staticClass: "flex_x" }, [
                _c(
                  "video",
                  {
                    attrs: {
                      id: "mse",
                      autoplay: "true",
                      playsinline: "",
                      controls: "controls",
                      width: _vm.width,
                      height: _vm.height,
                    },
                  },
                  [
                    _c("source", {
                      attrs: { src: _vm.url, type: "video/mp4" },
                    }),
                    _vm._v(
                      "\n      your browser does not support the video tag\n    "
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }