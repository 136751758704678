import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//

export default {
  name: 'App',
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      isRouterAlive: true
    };
  },
  mounted: function mounted() {
    setInterval(function () {
      document.querySelectorAll(".el-cascader-node__label").forEach(function (el) {
        el.onclick = function () {
          if (this.previousElementSibling) this.previousElementSibling.click();
        };
      });
    }, 1000);
  },
  methods: {
    reload: function reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  }
};