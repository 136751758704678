import Layout from '@/layout';
var headLineRouter = {
  path: '/headLine',
  component: Layout,
  redirect: '/headLine/list',
  // 我们暂时不需要重定向
  alwaysShow: true,
  name: 'HeadLine',
  meta: {
    title: '鑫头条',
    icon: 'documentation'
  },
  children: [{
    path: '/newsClassification',
    component: function component() {
      return import('@/views/headLine/newsClassification.vue');
    },
    name: 'NewsClassification',
    meta: {
      title: '新闻分类',
      icon: 'dashboard'
    }
  }, {
    path: '/newsList',
    component: function component() {
      return import('@/views/headLine/newsList.vue');
    },
    name: 'NewsList',
    meta: {
      title: '新闻列表',
      icon: 'list',
      isCache: true,
      noCache: true
    }
  }, {
    path: '/addHeadline',
    component: function component() {
      return import('@/views/headLine/addHeadline.vue');
    },
    name: 'AddNews',
    meta: {
      title: '新增新闻',
      icon: 'form'
    },
    hidden: true
  }, {
    path: '/previewHeadline',
    component: function component() {
      return import('@/views/headLine/previewHeadline.vue');
    },
    name: 'PreviewHeadline',
    meta: {
      title: '预览新闻',
      icon: 'form'
    },
    hidden: true
  }]
};
export default headLineRouter;