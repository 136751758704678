//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getStore } from '@/utils/auth';
export default {
  name: 'item',
  components: {},
  props: {
    isStore: {
      //是不是门店
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: {}
    },
    statusName: {}
  },
  created: function created() {},
  data: function data() {
    return {
      isStore: false
    };
  },
  methods: {
    //算出总价格
    goodsPriceSum: function goodsPriceSum(arr) {
      var num = 0;
      for (var i = 0; i < arr.length; i++) {
        var goodsPrice = parseFloat(arr[i].goodsPrice);
        // let nums=arr[i].nums
        num += goodsPrice;
      }
      return num;
    },
    // 到点服务
    storeMoOne: function storeMoOne(data, sh) {
      if (sh == 2) {
        // 售后
        if (this.isStore) {
          if (this.isPlat()) {
            if (data.serviceMode == 1) {
              this.$router.push({
                path: '/orderPlat/order-service/aftermarket-detail?id=' + data.id + '&status=2',
                query: {
                  id: data.id
                }
              });
            } else {
              this.$router.push({
                path: '/orderPlat/order-service/storeDeliveryAfterPlatDetails?id=' + data.id + '&status=0',
                query: {
                  id: data.id
                }
              });
            }
          } else {
            if (data.serviceMode == 1) {
              this.$router.push({
                path: '/order-service/storeSaleDetail?id=' + data.id + '&status=2' + '&isSales=' + true,
                query: {
                  id: data.id
                }
              });
            } else {
              this.$router.push({
                path: '/order-service/storeDeliveryAfterDetails?id=' + data.id + '&status=0' + '&isSales=' + true,
                query: {
                  id: data.id
                }
              });
            }
          }
        } else {
          this.$router.push({
            path: '/orderPlat/order-service/returned-goods-detail?id=' + data.id,
            query: {
              id: data.id
            }
          });
        }
        return;
      }
      // 订单详情
      if (this.isStore) {
        if (this.isPlat()) {
          this.$router.push({
            path: '/storeManagementPlat/storeOrderDetailPlat',
            query: {
              id: data.id
            }
          });
        } else {
          // 到店
          if (data.serviceMode == 1) {
            this.$router.push({
              path: '/storeManagement/storeOrderDetail',
              query: {
                id: data.id,
                pageType: 2
              }
            });
          } else {
            this.$router.push({
              path: '/storeManagement/deliveyrDetail',
              query: {
                id: data.id,
                orderSn: data.orderSn,
                pageType: 2
              }
            });
          }
        }
      } else {
        this.$router.push({
          path: '/orderPlat/order-detail',
          query: {
            orderSn: data.orderSn
          }
        });
      }
    },
    goDetails: function goDetails(data) {
      var sh = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      this.storeMoOne(data, sh);
    },
    // 商品详情
    goGDetails: function goGDetails(data, item) {
      var isStore = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      // 门店
      if (isStore) {
        if (this.isPlat()) {
          if (data.serviceMode == 1) {
            this.$router.push({
              path: '/storeManagementPlat/storeGoodsDetails',
              query: {
                id: item.id,
                type: 3,
                status: 1,
                serviceMode: 1,
                page: 'goods',
                enterpriseId: item.enterpriseId
              }
            });
          } else {
            this.$router.push({
              path: '/storeManagementPlat/StoreDeliveryGoodsDetailsPlat',
              query: {
                id: item.id,
                type: 3,
                status: 1,
                enterpriseId: item.enterpriseId
              }
            });
          }
        } else {
          if (data.serviceMode == 1) {
            this.$router.push({
              path: '/storeManagement/goodsDetail',
              query: {
                id: item.id,
                type: 3,
                status: 1,
                serviceMode: 1,
                page: 'goods'
              }
            });
          } else {
            this.$router.push({
              path: '/storeManagement/storeDeliveryGoodsDetails',
              query: {
                id: item.id,
                type: 2,
                status: '',
                serviceMode: 2,
                page: 'goods'
              }
            });
          }
        }
        // 商铺
      } else {
        this.$router.push({
          path: '/productPlat/detailsProduct',
          query: {
            id: item.goodsId,
            type: 3,
            pageType: 'detail'
          }
        });
      }
    }
  },
  filters: {
    /* 时间转换 */
    timeTranHM: function timeTranHM(value) {
      return !value ? '-' : tool.date('Y-m-d H:i', value);
    }
  }
};