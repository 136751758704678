import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import { pickerOptions } from '@/utils/index';
import * as filters from "../filters"; // global filters
// 插件统一放到这里
export default {
  install: function install(Vue) {
    Vue.prototype.bus = new Vue();
    // register global utility filters
    Object.keys(filters).forEach(function (key) {
      Vue.filter(key, filters[key]);
    });
    /* 
        修改title插件/方法
        调用直接this.$setTitle('当前想要改的title',当前路由this.$route) 
        setUniversalTitle是通用新增编辑详情的处理
        此方法修改不包含面包屑
    */
    Vue.prototype.$setTitle = function (title, route) {
      var _this = this;
      setTimeout(function () {
        var editRoute = Object.assign({}, route);
        title && (editRoute.title = title);
        _this.$store.dispatch('tagsView/updateVisitedView', editRoute);
      }, 0);
    };
    Vue.prototype.$setUniversalTitle = function (name, type, route) {
      var _this2 = this;
      setTimeout(function () {
        var editRoute = Object.assign({}, route);
        name && (editRoute.title = type == 1 ? "\u65B0\u589E".concat(name) : type == 2 ? "\u7F16\u8F91".concat(name) : "".concat(name, "\u8BE6\u60C5"));
        _this2.$store.dispatch('tagsView/updateVisitedView', editRoute);
      }, 0);
    };
    Vue.prototype.$pickerOptions = pickerOptions; // 日期选择 快捷日期对象配置
    Vue.prototype.$processingResData = function (res) {
      if (res.code === '000000') {
        return true;
      } else if (res.code === '080000') {
        res.mesg && this.$message.success(res.mesg);
        return false;
      } else {
        res.mesg && this.$message.error(res.mesg);
        return false;
      }
    };
    Vue.prototype.$PRD = Vue.prototype.$processingResData;
    Vue.directive('alterELDialogMarginTop' /*修改elementUI中el-dialog顶部的距离,传入值eg:{marginTop:'5vh'} */, {
      inserted: function inserted(el, binding, vnode) {
        el.firstElementChild.style.marginTop = binding.value.marginTop;
      }
    });

    //相同的提示信息，一定时间内只弹一次
    ///*
    var _success = Vue.prototype.$message.success;
    var obj = {};
    Vue.prototype.$message.success = function (msg) {
      var ischek = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (!ischek) {
        _success(msg);
        return;
      }
      if (obj[msg]) {
        if (new Date().getTime() - obj[msg] > 1000) {
          obj[msg] = new Date().getTime();
          _success(msg);
        } else {
          obj[msg] = new Date().getTime();
          if (APP_ENV != 'prod') {
            console.log("[相同的提示信息忽略]");
          }
        }
      } else {
        obj[msg] = new Date().getTime();
        _success(msg);
      }
    };
    var _error = Vue.prototype.$message.error;
    Vue.prototype.$message.error = function (msg) {
      var ischek = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      if (!ischek) {
        _error(msg);
        return;
      }
      if (obj[msg]) {
        if (new Date().getTime() - obj[msg] > 1000) {
          obj[msg] = new Date().getTime();
          _error(msg);
        } else {
          obj[msg] = new Date().getTime();
          if (APP_ENV != 'prod') {
            console.log("[相同的提示信息忽略]");
          }
        }
      } else {
        obj[msg] = new Date().getTime();
        _error(msg);
      }
    }; //*/
  }
};