/** When your routing table is too long, you can split it into small modules**/
// 流媒体管理-企业
import Layout from '@/layout';
var mediaFirmRouter = {
  path: '/mediaBussinessMgt',
  component: Layout,
  redirect: '/mediaBussinessMgt',
  alwaysShow: true,
  name: 'MediaBussinessMgt',
  meta: {
    title: '流媒体管理-企业',
    icon: 'component'
  },
  children: [{
    path: 'setLive',
    component: function component() {
      return import('@/views/mediaBussinessMgt/setLive');
    },
    name: 'SetLive',
    meta: {
      title: '直播设置',
      icon: 'peoples'
    }
  }, {
    path: 'advertisementVideoMag',
    component: function component() {
      return import('@/views/mediaBussinessMgt/advertisementVideoMag/index');
    },
    name: 'AdvertisementVideoMagPlat',
    meta: {
      title: '广告视频管理',
      icon: 'list'
    }
  }, {
    path: 'advertisementVideoMag/addADVideos',
    component: function component() {
      return import('@/views/mediaBussinessMgt/advertisementVideoMag/addADVideos');
    },
    name: 'AddADVideosPlat',
    meta: {
      title: '添加广告视频',
      icon: 'list'
    },
    hidden: true
  }, {
    path: 'cameralist',
    component: function component() {
      return import('@/views/mediaBussinessMgt/cameralist/cameralist');
    },
    name: 'mediaCameraBussinessMgt',
    meta: {
      title: '摄像头列表',
      icon: 'peoples'
    }
  }, {
    path: 'address',
    component: function component() {
      return import('@/views/mediaBussinessMgt/address/index');
    },
    name: 'mediaAddress',
    meta: {
      title: '地址管理',
      icon: 'list'
    }
  }, {
    path: 'liveList',
    component: function component() {
      return import('@/views/mediaBussinessMgt/liveList');
    },
    hidden: false,
    name: 'ShopMonitorList',
    meta: {
      title: '监控列表',
      icon: 'role'
    }
  }, {
    path: 'monitorVideo',
    component: function component() {
      return import('@/views/mediaBussinessMgt/monitorVideo/index');
    },
    hidden: false,
    name: 'MonitorVideoBussiness',
    meta: {
      title: '监控视频',
      icon: 'role'
    }
  }, {
    path: 'liveHistory',
    component: function component() {
      return import('@/views/mediaBussinessMgt/liveHistory/index');
    },
    hidden: false,
    name: 'LiveHistoryBussiness',
    meta: {
      title: '历史直播',
      icon: 'role'
    }
  }, {
    path: '/screenshot',
    component: function component() {
      return import('@/views/mediaBussinessMgt/mediaScreenshot/index');
    },
    hidden: false,
    name: 'mediaScreenshotBussiness',
    meta: {
      title: '截图管理',
      icon: 'role'
    }
  }, {
    path: '/mediaCompanyDetail',
    component: function component() {
      return import('@/views/media/companylist/mediaCompanyDetail');
    },
    name: 'shopMediaCompanyDetail',
    meta: {
      title: '企业详情',
      noCache: true
    }
  }, {
    path: '/shopLiveCamera',
    component: function component() {
      return import('@/views/media/liveCamera');
    },
    name: 'shopLiveCamera',
    meta: {
      title: '直播管理',
      icon: 'role'
    }
  }, {
    path: '/shopLiveStatistics',
    component: function component() {
      return import('@/views/media/liveStatistics');
    },
    name: 'ShopLiveStatistics',
    meta: {
      title: '统计总览',
      icon: 'role'
    }
  }]
};
export default mediaFirmRouter;