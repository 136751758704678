var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderlist-item-phone-container" },
    [
      _c("div", { staticClass: "row1" }, [
        _c("div", { staticClass: "label" }, [
          _vm._v("\n        订单编号: "),
          _c(
            "span",
            {
              staticClass: "sn",
              on: {
                click: function ($event) {
                  return _vm.goDetails(_vm.data.orderSn)
                },
              },
            },
            [_vm._v(_vm._s(_vm.data.orderSn))]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v red" }, [
          _vm._v(_vm._s(_vm.data.orderStatusName)),
        ]),
      ]),
      _vm._v(" "),
      _c("ToPad", { attrs: { data: _vm.data.toData } }),
      _vm._v(" "),
      _c("div", { staticClass: "row1" }, [
        _c("div", { staticClass: "label" }, [
          _c("img", {
            staticClass: "fj",
            attrs: { src: require("./svg/fj.svg") },
          }),
          _vm._v(" "),
          _vm.data.carrierName && _vm.data.carrierName != ""
            ? _c("span", [
                _vm._v(
                  " " +
                    _vm._s(_vm.data.carrierName) +
                    _vm._s(_vm.data.ticketNumber)
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "v" }, [
          _vm._v("¥" + _vm._s(_vm.data.orderPayAmount)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row1 down-line" }, [
        _c("div", { staticClass: "downt" }, [
          _vm._v("下单时间：" + _vm._s(_vm.data.createdTime)),
        ]),
        _vm._v(" "),
        _vm.data.orderCloseTime && _vm.data.orderCloseTime != ""
          ? _c("div", { staticClass: "downtip" }, [
              _vm._v(
                "订单将于 " + _vm._s(_vm.data.orderCloseTime) + " 自动关闭"
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }