/** When your routing table is too long, you can split it into small modules **/
// 站长管理
import Layout from '@/layout';
var customerRouter = {
  path: '/customerPool',
  component: Layout,
  redirect: '/customerPool/list',
  name: 'customerPool',
  meta: {
    title: '企业客户池',
    icon: 'list'
  },
  children: [{
    path: '/customerPool/list',
    component: function component() {
      return import('@/views/custormer/index');
    },
    name: 'customerList',
    meta: {
      title: '企业客户池',
      icon: 'list'
    }
  }
  // {
  //   path: '/customerPool/list',
  //   component: () => import('@/views/custormer/index'),
  //   name: 'customerList',
  //   meta: {
  //     title: '企业客户池',
  //     icon: 'list'
  //   }
  // },
  ]
};
export default customerRouter;