/** When your routing table is too long, you can split it into small modules **/
// 商铺端-首页
import Layout from '@/layout';
var shopHomeRouter = {
  path: '/shop',
  component: Layout,
  redirect: '/shop/home',
  name: 'Shop',
  meta: {
    title: '首页-商铺',
    icon: 'dashboard'
  },
  children: [{
    path: 'home',
    component: function component() {
      return import('@/views/shopHome/home');
    },
    name: 'ShopHome',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }, {
    path: '/shop/storeStat',
    component: function component() {
      return import('@/views/shopHome/storeStat');
    },
    name: 'CompanyStroreStat',
    meta: {
      title: '门店统计',
      noCache: true,
      activeMenu: '/shop/home'
    },
    hidden: true
  }]
};
export default shopHomeRouter;