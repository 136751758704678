/**
 * @description : 视频截图
 * @param {String} url : 视频地址
 * @param {Number} currentTime : 视频帧数
 * @param {download} hasDownload : 是否下载
 * @result : 返回base64，打开下载则下载
 */
export function videoSnapshot(url) {
  var currentTime = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var hasDownload = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return new Promise(function (resolve, reject) {
    var canvas = document.createElement('canvas'); //  创建canvas 用来截图
    var video = document.createElement('video'); //  创建video 用来存放被截图的视频
    video.setAttribute('crossOrigin', 'anonymous'); //  支持跨域
    /*
    //好像没有用
    https://www.cnblogs.com/Neilisme/p/9412315.html
    */
    video.setAttribute('muted', 'muted');
    document.body.appendChild(video); //  把视频插入页面里
    video.style.visibility = 'hidden'; //  视频不显示
    video.setAttribute('src', url); //  设置video路径

    // 监听视频播放
    video.onplay = function () {
      // 暂停
      video.pause();
      // 指定播放时间 视频播放的当前位置（以秒计）
      video.currentTime = currentTime;
      // 设置视频容器的宽高播放 如果设置一项会自动按照比例生成  这里是高固定,宽自动
      video.height = video.clientHeight;
      // 设置canvas的截图大小,如果没给定宽高值会取视频容器的宽高
      canvas.width = video.clientWidth;
      canvas.height = video.height;
      /*  
        使用定时器为了视频跳转到某一帧的时候视频进行缓冲,等视频加载完成之后进行截图
        如果截图是黑屏说明视频未加载完成就开始截屏了
      */
      setTimeout(function () {
        //  使用canvas进行绘画 视频画面
        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
        var b64 = canvas.toDataURL('image/png');
        if (hasDownload) {
          var link = document.createElement('a');
          link.href = b64;
          link.setAttribute('download', '视频截图.png');
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
        }
        resolve(b64);
        document.body.removeChild(video);
      }, 1000);
    };

    // 当视频准备就绪的时候 
    video.onloadeddata = function () {
      // 播放它
      try {
        video.play();
      } catch (e) {
        console.log(e);
      }
    };
  });
}