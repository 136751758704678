import request from '@/utils/request';
// 主页数据
export function getVipUser(data) {
  return request({
    url: '/sysadmin/userDetail/all',
    method: 'get',
    data: data
  });
}
export function getFlowTypeList() {
  return request({
    url: '/sysadmin//wallet/getFlowTypeList',
    method: 'get'
  });
}

// 主页查询
export function checkUser(data) {
  return request({
    url: '/sysadmin/userDetail/advancedQuery',
    method: 'post',
    data: data
  });
}
// 会员信息导出
export function checkUserExport(data) {
  return request({
    url: '/sysadmin/userDetail/export',
    method: 'post',
    data: data
  });
}
// 主页编辑
export function editUser(data) {
  return request({
    url: "/sysadmin/userDetail/" + data.id,
    method: 'put',
    data: data
  });
}
// 主页详情
export function getDetails(data) {
  return request({
    url: '/sysadmin/userDetail/' + data.id,
    method: 'get'
  });
}
//会员-修改密码
export function changePassword(data) {
  return request({
    url: '/sysadmin/members/updatePassword',
    method: 'put',
    data: data
  });
}
//手动发放待发放积分
/*
{
  "id": "string",
  "remark": "string"
}
*/
export function grantPoint(data) {
  return request({
    url: '/sysadmin/pointWait/grantPoint',
    method: 'put',
    data: data
  });
}
// 取消积分发放
/*
{
  "orderSn": "string",
  "remark": "string"
}
*/
export function cancelPoint(data) {
  return request({
    url: '/sysadmin/pointWait/cancelPoint',
    method: 'put',
    data: data
  });
}

// 根据订单号查询相应的积分信息
/*
{
  orderSn=订单编号,
  orderType=订单类型，1-商城购物,2-火车票,3-机票,4-酒店,6-话费充值返积分,7-门店购物,
  userId=用户id 
}
*/
export function pointInfoByOrderSn(params) {
  return request({
    url: '/sysadmin/userPointsList/pointInfoByOrderSn',
    method: 'get',
    params: params
  });
}

// 积分
export function getUserJf(data) {
  return request({
    url: '/sysadmin/userPointsList/' + data.id,
    method: 'get'
  });
}
// 获取积分分类 
export function typeStat(userId) {
  return request({
    url: '/sysadmin/userPointsList/type/stat/' + userId,
    method: 'get'
  });
}
// 积分查询
export function checkJf(data) {
  return request({
    url: '/sysadmin/userPointsList/advancedQuery',
    method: 'post',
    data: data
  });
}

// 余额
export function getUserYue(data) {
  return request({
    url: '/sysadmin/balanceList/' + data.id,
    method: 'get'
  });
}
// 余额查询
export function checkYue(data) {
  return request({
    url: '/sysadmin/balanceList/advancedQuery',
    method: 'post',
    data: data
  });
}

// 悦鑫豆
export function getUserYxd(data) {
  return request({
    url: '/sysadmin/yuexinBean/' + data.id,
    method: 'get'
  });
}
// 悦鑫豆记录统计接口
export function frozenStat(userId) {
  return request({
    url: '/sysadmin/yuexinBean/user/frozen/stat/' + userId,
    method: 'get'
  });
}
// 解封悦鑫豆类型统计 
export function userFrozenStat(userId) {
  return request({
    url: '/sysadmin/yuexinBean/user/unseal/type/' + userId,
    method: 'get'
  });
}
//  封存悦鑫豆类型统计 
export function userFrozenType(userId) {
  return request({
    url: '/sysadmin/yuexinBean/user/frozen/type/' + userId,
    method: 'get'
  });
}
//  收益悦鑫豆类型统计 
export function userProfitType(userId) {
  return request({
    url: '/sysadmin/yuexinBean/user/frozen/profit/type/' + userId,
    method: 'get'
  });
}
// 封存记录 
export function frozenPage(data) {
  return request({
    url: '/sysadmin/yuexinBean/user/frozen/page',
    method: 'post',
    data: data
  });
}
// 获取封存计划列表
export function listBeanRulePage(params) {
  return request({
    url: 'sysadmin/yuexinBean/listBeanRulePage',
    method: 'get',
    params: params
  });
}
// 悦鑫豆查询
export function checkYxd(data) {
  return request({
    url: '/sysadmin/yuexinBean/advancedQuery',
    method: 'post',
    data: data
  });
}

// 获取收货地址
export function userAddress(params) {
  return request({
    url: "/sysadmin/userAddress/getAllWithPage/".concat(params.userId),
    method: 'get',
    params: params
  });
}

// 获取银行卡列表
export function getBankCard(params) {
  return request({
    url: '/sysadmin/walletUserBankcard/cashOutList',
    method: 'get',
    params: params
  });
}

// 获取订单信息列表
export function getindent(data) {
  return request({
    url: "/order/sys/order/memberList",
    method: 'post',
    data: data
  });
}

// 获取购物车列表
export function getcart(data) {
  return request({
    url: "/order/cart/advancedQuery",
    method: 'post',
    data: data
  });
}

// 获取门店购物车列表
export function getStoreCart(data) {
  return request({
    url: "/nearby/admin/cart/user/page",
    method: 'post',
    data: data
  });
}

// 收藏夹-商品
export function favoritegoods(parameter, tabVal) {
  if (tabVal == 1) {
    return request({
      url: "/sysadmin/userFavorite/userFavoriteGoods/".concat(parameter.userId, "?current=").concat(parameter.current, "&size=").concat(parameter.size),
      method: 'get'
    });
  } else {
    return request({
      url: "/sysadmin/userFavorite/nearbyCommodity/page/",
      method: 'post',
      data: parameter
    });
  }
}

// 收藏夹-内容
export function getContentData(parameter, tabVal) {
  if (tabVal == 2) {
    return request({
      url: "/sysadmin/userFavorite/userFavoriteContent/".concat(parameter.userId, "?current=").concat(parameter.current, "&size=").concat(parameter.size),
      method: 'get'
    });
  } else {
    return request({
      url: "/sysadmin/master/collect/user/page/",
      method: 'post',
      data: parameter
    });
  }
}
// 发票抬头
export function userPage(data) {
  return request({
    url: "/sysadmin/invoiceApply/user/page",
    method: 'post',
    data: data
  });
}

// 话费订单
export function phoneCharge(data) {
  return request({
    url: "/sysadmin/phoneCharge/advanced",
    method: 'post',
    data: data
  });
}
// 机票订单
export function searchOrderList(params) {
  return request({
    url: "/order/background/searchOrderList",
    method: 'get',
    params: params
  });
}

// 优惠券
export function getcoupon(data) {
  var url = '';
  url = "/sysadmin/userCoupon/userCouponInfo";
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
export function getcouponShop(data) {
  var url = '';
  url = "/nearby/admin/shop/coupon/user/coupon/page";
  return request({
    url: url,
    method: 'post',
    data: data
  });
}
// 粉丝
export function getfans(params, type) {
  if (type == 3) {
    return request({
      url: '/sysadmin/master/user/follow/page',
      method: 'post',
      data: params
    });
  } else {
    return request({
      url: '/sysadmin/userDetail/fans/' + params.id,
      method: 'get',
      params: params
    });
  }
}
//乘客信息
export function getPassengerData(data) {
  return request({
    url: '/ticket/passengers/getPassengersInfoList',
    method: 'post',
    data: data
  });
}
export function getGrade() {
  return request({
    url: '/sysadmin/grade/all',
    method: 'get'
  });
}

// 还原接口
export function restoreuser(data) {
  return request({
    url: '/sysadmin/blacklist/reductionAll',
    method: 'post',
    data: data
  });
}

// 足迹
export function footmark(params, tabVal) {
  // 商铺
  if (tabVal == 1) {
    return request({
      url: '/sysadmin/userFootmark/list',
      method: 'get',
      params: params
    });
    // 门店
  } else {
    return request({
      url: '/nearby/admin/commodityFootmark/user/page',
      method: 'post',
      data: params
    });
  }
}

//获取积分余额  
export function getIntegral(data) {
  return request({
    url: '/sysadmin/userPointsList/TotalPoints?userId=' + data.id,
    method: 'get'
  });
}
//获取悦鑫豆余额  ====== 不用了
export function getBean(data) {
  return request({
    url: '/sysadmin/yuexinBean/TotalBean?userId=' + data.id,
    method: 'get'
  });
}

//获取悦鑫豆余额 
export function getProfitBean(userId) {
  return request({
    url: '/sysadmin/yuexinBean/user/profit/stat/' + userId,
    method: 'get'
  });
}
// 导出悦鑫豆封存记录 
export function exportFrozen(data) {
  return request({
    url: '/sysadmin/yuexinBean/export/frozen/',
    method: 'post',
    data: data
  });
}
// 流水导出
export function exportBean(data) {
  return request({
    url: '/sysadmin/yuexinBean/export/bean',
    method: 'post',
    data: data
  });
}
//获取收支记录总额分类
export function getProfitBeanAll(userId) {
  return request({
    url: '/sysadmin/yuexinBean/user/profit/type/stat/all/' + userId,
    method: 'get'
  });
}
//获取收支记录可用分类
export function getProfitBeanUse(userId) {
  return request({
    url: '/sysadmin/yuexinBean/user/profit/type/stat/use/' + userId,
    method: 'get'
  });
}

//钱包余额-获取列表
export function getWalletBalanceData(params) {
  return request({
    url: '/sysadmin/wallet/getWalletFlowRecordList',
    method: 'get',
    params: params
  });
}
//钱包余额-余额
export function getBalance(data) {
  return request({
    url: '/sysadmin/wallet/getUserWallet/' + data.userId,
    method: 'get'
  });
}
//粉丝收益-获取列表
export function getFansEarningsData(data) {
  return request({
    url: '/sysadmin/fans/profitList',
    method: 'post',
    data: data
  });
}
// 网页访问记录 
export function visitRecordRecord(data) {
  return request({
    url: '/sysadmin/officialWebsite/visitRecord/advanceQuery',
    method: 'post',
    data: data
  });
}

//粉丝收益-余额
export function getFansEarnings(data) {
  return request({
    url: '/sysadmin/fans/TotalMoney?userId=' + data.userId,
    method: 'get'
  });
}
//关注的店铺-获取列表
export function getFocusedShops(params, type) {
  // 商品
  if (type == 1) {
    return request({
      url: '/sysadmin/userFavorite/listUserFavoriteShop',
      method: 'get',
      params: params
    });
    // 门店
  } else {
    return request({
      url: '/sysadmin/userFavorite/user/follow/nearbyShop/page',
      method: 'post',
      data: params
    });
  }
}

// 会员详情订单分页
export function getOrderMemberPage(params) {
  return request({
    url: '/nearby/admin/order/memberPage',
    method: 'get',
    params: params
  });
}
// 任务中心列表数据
export function getTaskCenterData(params) {
  return request({
    url: '/sysadmin/admin/task/list',
    method: 'get',
    params: params
  });
}
// 火车票/trainAdmin/user/order/page  
export function taskList(data) {
  console.log(data);
  return request({
    url: '/ticket/trainAdmin/user/order/page',
    method: 'post',
    data: data
  });
}
// 任务中心-修改接口 
export function editTask(data) {
  return request({
    url: '/sysadmin/admin/task',
    method: 'put',
    data: data
  });
}

//积分管理-列表数据
export function getPointList(params) {
  return request({
    url: '/sysadmin/userPointsList/record',
    method: 'get',
    params: params
  });
}
//积分管理-列表数据-导出
export function exportPointList(params) {
  return request({
    url: '/sysadmin/excel/point/record',
    method: 'get',
    params: params
  });
}
//积分管理-查询会员信息
export function queryVipInfo(params) {
  return request({
    url: '/sysadmin/userDetail/personalInfo',
    method: 'get',
    params: params
  });
}
//积分管理-手动操作积分
export function operatePoint(data) {
  return request({
    url: '/sysadmin/userPointsList/systemAdd',
    method: 'post',
    data: data
  });
}

// 获取邀请码是否开启  
export function dictionaryInvite(params) {
  return request({
    url: '/sysadmin/system/dictionary/invite',
    method: 'get',
    params: params
  });
}

// 设置邀请码是否开启   
export function inviteSetting(data) {
  return request({
    url: '/sysadmin/system/dictionary/inviteSetting',
    method: 'post',
    data: data
  });
}

//背景图审核列表
export function getblackgroundList(params) {
  return request({
    url: '/sysadmin/blackground/page',
    method: 'get',
    params: params
  });
}
// /blackground/{id}

//背景图审核审核详情
export function getblackgroundInfo(id) {
  return request({
    url: '/sysadmin/blackground/' + id,
    method: 'get'
  });
}
//批量审核
export function putBlackgroundAudit(data) {
  return request({
    url: '/sysadmin/blackground/audit',
    method: 'put',
    data: data
  });
}
//背景图-待审核列表
export function getblackgroundWaitList() {
  return request({
    url: '/sysadmin/blackground/waitList',
    method: 'get'
  });
}
//登录日志
export function VisitRecordList(data) {
  return request({
    url: '/sysadmin/visitRecord/advanceQuery',
    method: 'post',
    data: data
  });
}
// 登录日志,访问时间间隔设置
export function VisitRecordSetting(data) {
  return request({
    url: '/sysadmin/system/dictionary/visitSetting',
    method: 'post',
    data: data
  });
}
// 获取 登录日志,访问时间间隔设置
export function GetVisitSetting() {
  return request({
    url: '/sysadmin/system/dictionary/getVisitSetting',
    method: 'get'
  });
}

//查询订单未发放积分信息[新需求接口]
export function getPointsWait(params) {
  return request({
    url: '/sysadmin/pointWait/pointsWait',
    method: 'get',
    params: params
  });
}

//查询订单未发放积分信息[新需求接口]
export function getGrantPointInfo(orderSn) {
  return request({
    url: '/sysadmin/pointWait/grantPoint/info',
    method: 'get',
    params: {
      orderSn: orderSn
    }
  });
}
//手动发放待发放积分[新需求接口]
export function putGrantPoint(data) {
  return request({
    url: '/sysadmin/pointWait/grantPoint',
    method: 'put',
    data: data
  });
}

// 获取待积分管理信息[新需求接口]
/*
{
  "orderSn": 订单编号,
  "paramList": [
    {
      "categoryId":分类id
      "changeType": 交易类型,1-购物返积分,2-买火车票返积分,3-买机票返积分,4-酒店返积分,5-悦鑫豆转积分,6-话费充值返积分,7-门店购物返积分,8-通用积分(粉丝邀请，签到)
    }
  ]
}
*/
export function pointWaitManageInfo(data) {
  /*return request({
    url: '/sysadmin/pointWait/pointWait/manageInfo',
    method: 'post',
    data
  })*/
  return request({
    url: '/sysadmin/userPointsList/points/manageInfo/order',
    method: 'post',
    data: data
  });
}
export function pointWaitManageInfoDai(data) {
  return request({
    url: '/sysadmin/pointWait/pointWait/manageInfo/order',
    method: 'post',
    data: data
  });
}

// 待发放积分记录新增[新需求接口]
/*
{
  "categoryId": "string", //来源类型，购物返积分时传入，就是商品一级分类
  "changeType": 0, //交易类型,1-购物返积分,2-买火车票返积分,3-买机票返积分,4-酒店返积分,5-悦鑫豆转积分,6-话费充值返积分,7-门店返积分,8-通用积分(目前签到和粉丝)
  "operateType": 0,// 收支类型\r\n1.购买获得\r\n2.退款扣除\r\n3.系统操作
  "orderSn": "string", //订单号
  "points": 0, //具体积分值
  "pointsAdd": 0, //积分支出类型,-1支出，1收入
  "remark": "string",
  "userId": "string" //会员id
}
*/
export function pointWaitPointWaitAdd(data) {
  return request({
    url: '/sysadmin/pointWait/pointWait/add',
    method: 'post',
    data: data
  });
}

//查询积分明细列表[新需求开发]

export function userPointsListPointsPage(params) {
  return request({
    url: '/sysadmin/userPointsList/points/page',
    method: 'get',
    params: params
  });
}

// 获取积分明细管理信息[新需求接口]
/*
{
  "changeType": 0,
  "orderSn": "string",
  "paramList": [
    {
      "categoryId": "string",
      "changeType": 0
    }
  ]
}
*/
export function pointsManageInfoNew(data) {
  return request({
    url: '/sysadmin/userPointsList/points/manageInfo',
    method: 'post',
    data: data
  });
}
// 添加积分明细
/*
{
  "changeType": 0,
  "generateType": 0,
  "orderSn": "string",
  "points": 0,
  "pointsAdd": 0,
  "remark": "string",
  "sourceType": "string",
  "userId": "string"
}
*/
export function addUserPointsList(data) {
  return request({
    url: '/sysadmin/userPointsList',
    method: 'post',
    data: data
  });
}
//获取订单详情积分发放统计
export function statisticsOrderPoint(orderSn) {
  return request({
    url: '/sysadmin/userPointsList/statistics/orderPoint?orderSn=' + orderSn,
    method: 'get'
  });
}

//注销列表
export function memberDestroyList(data) {
  return request({
    url: '/app/memberDestroy/list',
    method: 'post',
    data: data
  });
}