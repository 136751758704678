import image from "./ImageView.vue";
var showImage = {
  install: function install(Vue) {
    var showImageConstructor = Vue.extend(image);
    var instance = new showImageConstructor();
    instance.$mount(document.createElement('div'));
    document.body.appendChild(instance.$el);
    /**
     * 弹出图片
     * @param String url  当前显示的图片
     * @param Array imglist  图片列表，会出现上一页和下一页。注：可以为空
     */
    Vue.prototype.$showImage = function (url, imglist) {
      instance.dialogVisible = true;
      instance.dialogImageUrl = url;
      if (!imglist) imglist = [url];
      instance.imglist = imglist;
      instance.setIdx(url);
    };
  }
};
export default showImage;