var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tooltip" },
    [
      _c("el-tooltip", { attrs: { effect: "dark", placement: "right" } }, [
        _c(
          "div",
          { attrs: { slot: "content" }, slot: "content" },
          _vm._l(_vm.messages, function (item, index) {
            return _c("p", { key: index }, [_vm._v(_vm._s(item))])
          }),
          0
        ),
        _vm._v(" "),
        _c("i", {
          staticClass: "el-icon-question",
          staticStyle: { color: "#409eff", "margin-left": "5px" },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }