/** When your routing table is too long, you can split it into small modules **/
// 商铺端-交易数据中心
import Layout from '@/layout';
var shopStatisticsRouter = {
  path: '/shopStatistics',
  component: Layout,
  redirect: '/shopStatistics/statistics',
  name: 'ShopStatistics',
  meta: {
    title: '交易数据中心-商铺',
    icon: 'dashboard'
  },
  hidden: true,
  children: [{
    path: 'statistics',
    component: function component() {
      return import('@/views/shopHome/statistics');
    },
    name: 'ShopStatisticsCenter',
    meta: {
      title: '交易数据中心',
      icon: 'dashboard'
    },
    hidden: true
  }]
};
export default shopStatisticsRouter;