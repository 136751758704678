/** When your routing table is too long, you can split it into small modules **/
// 个人中心
import Layout from '@/layout';
var personalCenterRouter = {
  path: '/personalCenter',
  component: Layout,
  alwaysShow: true,
  redirect: '/personalCenter/userCenter',
  name: 'PersonalCenter',
  meta: {
    title: '个人中心',
    icon: 'people'
  },
  hidden: true,
  children: [
  /*{
    path: '/personalCenter/userCenter',
    component: () => import('@/views/personalCenter/userCenter'),
    name: 'UserCenter',
    meta: { title: '用户信息', icon: 'tree' },
    hidden: true
  },*/
  {
    path: '/personalCenter/userCenterSite',
    component: function component() {
      return import('@/views/personalCenter/userCenterSite');
    },
    name: 'UserCenterSite',
    meta: {
      title: '用户信息',
      icon: 'tree'
    },
    hidden: true
  }, {
    path: '/personalCenter/messageCenter',
    component: function component() {
      return import('@/views/personalCenter/messageCenter');
    },
    name: 'MessageCenter',
    meta: {
      title: '消息通知',
      icon: 'tree'
    },
    hidden: true
  }, {
    path: '/personalCenter/messageCenterDetail',
    component: function component() {
      return import('@/views/personalCenter/messageCenterDetail');
    },
    name: 'MessageCenterDetail',
    meta: {
      title: '消息通知详情',
      noCache: true,
      activeMenu: '/personalCenter/messageCenterDetail'
    },
    hidden: true
  }, {
    path: '/personalCenter/userCenter',
    component: function component() {
      return import('@/views/personalCenter/userCenter');
    },
    name: 'UserCenter',
    meta: {
      title: '用户信息',
      icon: 'tree'
    },
    hidden: true
  }]
};
export default personalCenterRouter;