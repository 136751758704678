import Layout from '@/layout';
var shopsmanageterraceRouter = {
  path: '/shopsmanageterrace',
  component: Layout,
  redirect: '/shopsmanageterrace/shopslist',
  alwaysShow: true,
  name: 'Shopsmanageterrace',
  meta: {
    title: '商铺管理-平台',
    icon: 'role'
  },
  children: [{
    path: 'shopslist',
    component: function component() {
      return import('@/views/shopsmanageterrace/shopslist.vue');
    },
    name: 'Shopslist',
    meta: {
      title: '商铺列表',
      icon: 'peoples'
    }
  }, {
    path: 'shopsdetail',
    component: function component() {
      return import('@/views/shopsmanageterrace/shopsdetail.vue');
    },
    name: 'Shopsdetail',
    meta: {
      title: '商铺详情',
      noCache: true,
      activeMenu: '/shopsmanageterrace/shopslist'
    },
    hidden: true
  }, {
    path: 'shopsdeposit',
    component: function component() {
      return import('@/views/shopsmanageterrace/shopsdeposit.vue');
    },
    name: 'Shopsdeposit',
    meta: {
      title: '商铺提现',
      icon: 'peoples'
    }
  }]
};
export default shopsmanageterraceRouter;