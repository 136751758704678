import Layout from '@/layout';
var organizationRouter = {
  path: '/organization',
  component: Layout,
  redirect: '/organization/accredit',
  // 我们暂时不需要重定向 
  alwaysShow: true,
  name: 'Organization',
  meta: {
    title: '系统管理',
    icon: 'component'
  },
  children: [{
    path: '/organization/datashow',
    component: function component() {
      return import('@/views/organization/datashow/datashow.vue');
    },
    name: 'Datashow',
    meta: {
      title: '数据展示',
      icon: 'role'
    }
  },
  // {
  //   path: '/organization/accredit',
  //   component: () => import('@/views/organization/accredit'),
  //   name: 'Accredit',
  //   meta: { title: '授权管理', icon: 'role' }
  // },
  {
    path: 'gateway',
    component: function component() {
      return import('@/views/organization/gateway');
    },
    name: 'Gateway',
    meta: {
      title: '路由管理',
      icon: 'guide'
    }
  },
  // {
  //   path: '/organization/resource',
  //   component: () => import('@/views/organization/resource'),
  //   name: 'Resource',
  //   meta: { title: '资源管理', icon: 'role' }
  // },
  {
    path: 'menumanage',
    component: function component() {
      return import('@/views/organization/menumanage/menumanage.vue');
    },
    name: 'Menumanage',
    meta: {
      title: '菜单管理',
      icon: 'peoples'
    }
  }, {
    path: '/organization/characterManageList',
    component: function component() {
      return import('@/views/organization/charactermanage/characterManageList.vue');
    },
    name: 'CharacterManageList',
    meta: {
      title: '角色管理',
      icon: 'peoples'
    },
    hidden: true
  }, {
    path: '/organization/webLogs',
    component: function component() {
      return import('@/views/organization/webLogs.vue');
    },
    name: 'webSizeLog',
    meta: {
      title: '官网访问日志',
      icon: 'list'
    }
  }, {
    path: '/organization/charactermanage/addCharacter',
    component: function component() {
      return import('@/views/organization/charactermanage/addCharacter.vue');
    },
    name: 'AddCharacter',
    meta: {
      title: '新增角色',
      icon: 'edit',
      noCache: true,
      activeMenu: '/organization/userList'
    },
    hidden: true
  }, {
    path: '/organization/charactermanage/editCharacter',
    component: function component() {
      return import('@/views/organization/charactermanage/editCharacter.vue');
    },
    name: 'EditCharacter',
    meta: {
      title: '编辑角色',
      icon: 'edit',
      noCache: true,
      activeMenu: '/organization/userList'
    },
    hidden: true
  }, {
    path: '/organization/charactermanage/characterDetail',
    component: function component() {
      return import('@/views/organization/charactermanage/characterDetail.vue');
    },
    name: 'CharacterDetail',
    meta: {
      title: '角色权限',
      icon: 'edit',
      noCache: true,
      activeMenu: '/organization/userList'
    },
    hidden: true
  }, {
    path: 'userList',
    component: function component() {
      return import('@/views/organization/userList/list.vue');
    },
    name: 'Userlist',
    meta: {
      title: '用户列表',
      icon: 'peoples'
    }
  }, {
    path: 'accountList',
    component: function component() {
      return import('@/views/organization/userList/accountList.vue');
    },
    name: 'UserAccountList',
    meta: {
      title: '子账号列表',
      icon: 'peoples',
      activeMenu: '/organization/userList'
    },
    hidden: true
  }, {
    path: 'merchantsList',
    component: function component() {
      return import('@/views/organization/userList/merchantsList.vue');
    },
    name: 'UserMerchantsList',
    meta: {
      title: '招商专员列表',
      icon: 'peoples',
      activeMenu: '/organization/userList'
    },
    hidden: true
  }, {
    path: 'addList',
    component: function component() {
      return import('@/views/organization/userList/addList.vue');
    },
    name: 'UserAddList',
    meta: {
      title: '添加用户',
      icon: 'peoples',
      isCache: true,
      noCache: true,
      activeMenu: '/organization/userList'
    },
    hidden: true
  }, {
    path: 'deleteList',
    component: function component() {
      return import('@/views/organization/userList/deletedList.vue');
    },
    name: 'UserDeleteList',
    meta: {
      title: '删除账户列表',
      icon: 'peoples',
      activeMenu: '/organization/userList'
    },
    hidden: true
  }, {
    path: 'examine',
    component: function component() {
      return import('@/views/organization/examine/examine.vue');
    },
    name: 'Examine',
    meta: {
      title: '审批流',
      icon: 'peoples'
    }
  }, {
    path: 'examineCode',
    component: function component() {
      return import('@/views/organization/examine/examineCode.vue');
    },
    name: 'ExamineCode',
    meta: {
      title: '审批流编码'
    },
    hidden: true
  }, {
    path: 'setExamine',
    component: function component() {
      return import('@/views/organization/examine/setExamine.vue');
    },
    name: 'SetExamine',
    meta: {
      title: '设置'
    },
    hidden: true
  }, {
    path: 'express',
    component: function component() {
      return import('@/views/organization/express/express.vue');
    },
    name: 'Express',
    meta: {
      title: '物流设置',
      icon: 'peoples'
    }
  }, {
    path: 'frontmenu',
    component: function component() {
      return import('@/views/organization/frontmenu/frontmenu.vue');
    },
    name: 'Frontmenu',
    meta: {
      title: 'APP按钮',
      icon: 'peoples'
    }
  }, {
    path: 'integralsetting',
    component: function component() {
      return import('@/views/organization/integralsetting/integralsetting.vue');
    },
    name: 'Integralsetting',
    meta: {
      title: '积分悦鑫豆',
      icon: 'peoples'
    }
  }, {
    path: 'yuexinbean',
    component: function component() {
      return import('@/views/organization/yuexinbean/yuexinbean.vue');
    },
    name: 'Yuexinbean',
    meta: {
      title: '悦鑫豆封存设置',
      icon: 'peoples'
    },
    hidden: true
  }, {
    path: '/organization/payment',
    component: function component() {
      return import('@/views/organization/payment/payment.vue');
    },
    name: 'Payment',
    meta: {
      title: '支付管理',
      icon: 'peoples'
    }
  }, {
    path: '/organization/recharge',
    component: function component() {
      return import('@/views/organization/recharge/recharge.vue');
    },
    name: 'Recharge',
    meta: {
      title: '充值管理',
      icon: 'peoples'
    }
  }, {
    path: '/organization/version',
    component: function component() {
      return import('@/views/organization/version.vue');
    },
    name: 'VersionManagement',
    meta: {
      title: '版本管理',
      icon: 'peoples'
    }
  }, {
    path: '/organization/Business',
    component: function component() {
      return import('@/views/organization/payment/Business.vue');
    },
    name: 'Business',
    meta: {
      title: '商户信息',
      noCache: true,
      activeMenu: '/organization/payment'
    },
    hidden: true
  }, {
    path: '/organization/orgManagement',
    component: function component() {
      return import('@/views/organization/orgManagement/index.vue');
    },
    name: 'orgManagement',
    meta: {
      title: '组织架构',
      noCache: true,
      activeMenu: '/organization/orgManagement'
    },
    hidden: false
  }, {
    path: '/organization/orgManagementRole',
    component: function component() {
      return import('@/views/organization/orgManagement/role.vue');
    },
    name: 'orgManagementRole',
    meta: {
      title: '角色',
      noCache: true,
      activeMenu: '/organization/orgManagement'
    },
    hidden: true
  }, {
    path: 'orgManagementBusiness',
    component: function component() {
      return import('@/views/organization/orgManagement/business.vue');
    },
    name: 'orgManagementBusiness',
    meta: {
      title: '企业页面',
      activeMenu: '/organization/orgManagement'
    },
    hidden: true
  }, {
    path: 'orgManagementAgent',
    component: function component() {
      return import('@/views/organization/orgManagement/agent.vue');
    },
    name: 'orgManagementAgent',
    meta: {
      title: '站长页面',
      activeMenu: '/organization/orgManagement'
    },
    hidden: true
  }, {
    path: 'dataDictionary',
    component: function component() {
      return import('@/views/organization/dataDictionary/index.vue');
    },
    name: 'DictionaryType',
    meta: {
      title: '字典类型',
      activeMenu: '/organization/dataDictionary'
    },
    hidden: false
  }, {
    path: 'cancellationReject',
    component: function component() {
      return import('@/views/organization/dataDictionary/cancellationList');
    },
    name: 'Dictionary',
    meta: {
      title: '数据字典',
      activeMenu: '/organization/cancellationReject'
    },
    hidden: true
  }]
};
export default organizationRouter;