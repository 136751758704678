import request from '@/utils/request';

// 平台 获取平台 流媒体信息
function getStreamingText(id) {
  return request({
    url: "/smd/plf/enterprise/get/".concat(id),
    method: 'get'
  });
}
// 企业 获取企业 流媒体信息
function getEntStreamingText(id) {
  return request({
    url: "/smd/ent/enterprise/get",
    method: 'get'
  });
}
// 平台 企业列表
function getEnterpriseList(data) {
  return request({
    url: "/smd/plf/enterprise/page",
    method: 'post',
    data: data
  });
}
// 平台 企业列表 获取全平台的直播摄像头个数
function queryCountLiveCamera(data) {
  return request({
    url: "/smd/plf/live/queryCountLiveCamera",
    method: 'post',
    data: data
  });
}
// 平台 企业列表 下播
function closeLiveEnterprise(enterpriseId, type, liveAddressId) {
  return request({
    url: "/smd/".concat(type, "/live/closeLiveEnterprise/").concat(enterpriseId),
    method: 'post'
  });
}
// 平台/企业 直播列表  直播摄像头
function queryListLiveCamera(data, type) {
  return request({
    url: "/smd/".concat(type, "/live/queryListLiveCamera"),
    method: 'post',
    data: data
  });
}
// 平台 直播列表  摄像头下播
function closeLiveCamera(data, type) {
  return request({
    url: "/smd/".concat(type, "/live/closeLiveCamera"),
    method: 'post',
    data: data
  });
}

// 平台 直播列表  直播摄像头 获取直播地址
function getLiveUrl(id, type) {
  return request({
    url: "/smd/".concat(type, "/camera/getLiveUrl/").concat(id) + "?streamType=rtc",
    method: 'post'
  });
}

// 平台 企业列表 绑定/解绑摄像头
function bindCameras(data) {
  return request({
    url: "/smd/plf/enterprise/bindCameras",
    method: 'post',
    data: data
  });
}
// 平台 摄像头列表 分页
function getCamerasPage(data) {
  return request({
    url: "/smd/plf/camera/page",
    method: 'post',
    data: data
  });
}
// 平台 直播设置 分页
function getPlfTaskPage(data) {
  return request({
    url: "/smd/plf/live/task/page",
    method: 'post',
    data: data
  });
}
// 企业 直播设置 分页
function getTaskPage(data) {
  return request({
    url: "/smd/ent/live/task/page",
    method: 'post',
    data: data
  });
}
// 企业 摄像头列表 分页
function getentCameraPage(data) {
  return request({
    url: "/smd/ent/camera/page",
    method: 'post',
    data: data
  });
}
// 企业 直播列表 详情
function getLiveListDetail(id) {
  return request({
    url: "/smd/ent/live/task/get/".concat(id),
    method: 'get'
  });
}
// 企业 新增直播列表
function addLiveList(data) {
  return request({
    url: "/smd/ent/live/task/add",
    method: 'post',
    data: data
  });
}
// 平台 直播任务摄像头信息列表(分页)
function getPlfTaskCameraPage(data) {
  return request({
    url: "/smd/plf/live/task/camera/page",
    method: 'post',
    data: data
  });
}
// 企业 直播任务摄像头信息列表(分页)
function getTaskCameraPage(data) {
  return request({
    url: "/smd/ent/live/task/camera/page",
    method: 'post',
    data: data
  });
}
// 企业 直播设置 编辑修改
function liveTaskUpdate(data) {
  return request({
    url: "/smd/ent/live/task/update/".concat(data.id),
    method: 'put',
    data: data
  });
}
// 企业 直播设置 列表删除
function liveTaskDelete(data) {
  return request({
    url: "/smd/ent/live/task/delete/".concat(data.id),
    method: 'delete'
  });
}

// 平台 直播分类 不分页
function liveCategoryeList(data) {
  return request({
    url: "/smd/plf/live/category/list",
    method: 'post',
    data: data
  });
}
// 平台 直播信息 分页
function liveTextPage(data) {
  return request({
    url: "/smd/plf/live/page",
    method: 'post',
    data: data
  });
}
// 平台 直播信息 修改
function liveTextUpdate(data) {
  return request({
    url: "/smd/plf/enterprise/update/".concat(data.id),
    method: 'put',
    data: data
  });
}
// 企业 企业信息修改
function shopEnterprise(data) {
  return request({
    url: "/smd/ent/enterprise/update",
    method: 'put',
    data: data
  });
}
// 平台 摄像头列表 解绑/绑定
function bindEnterprise(data) {
  return request({
    url: "/smd/plf/camera/bindEnterprise",
    method: 'post',
    data: data
  });
}
// 平台 统计总览 摄像头区域分布
function getCameraArea(data) {
  return request({
    url: "/smd/plf/stat/getCameraArea",
    method: 'post',
    data: data
  });
}
// 平台 统计总览 观看用户区域分布
function getLiveUserArea(data) {
  return request({
    url: "/smd/plf/stat/getLiveUserArea",
    method: 'post',
    data: data
  });
}
// 平台 统计总览 平台的直播企业、摄像头数
function getLiveNum(data, type) {
  return request({
    url: "/smd/".concat(type, "/stat/getLiveNum"),
    method: 'post',
    data: data
  });
}
// 平台 统计总览 观看数据
function getLivePvUv(data, type) {
  return request({
    url: "/smd/".concat(type, "/stat/getLivePvUv"),
    method: 'post',
    data: data
  });
}
// 平台 统计总览 企业直播排行
function getLiveEnterpriseTop(data) {
  return request({
    url: "/smd/plf/stat/getLiveEnterpriseTop",
    method: 'post',
    data: data
  });
}
// 平台 统计总览 直播摄像头排行
function getLiveCameraTop(data, type) {
  return request({
    url: "/smd/".concat(type, "/stat/getLiveCameraTop"),
    method: 'post',
    data: data
  });
}
// 平台 统计总览 时间段分析
function getLiveTimeNum(data, type) {
  return request({
    url: "/smd/".concat(type, "/stat/getLiveTimeNum"),
    method: 'post',
    data: data
  });
}
// 获取省市区
function getAreaData(id) {
  return request({
    url: id ? "/sysadmin/system/area/query?parentId=".concat(id) : "/sysadmin/system/area/query",
    method: 'get'
  });
}
// 获取省市区
function cameraPtzControl(data) {
  return request({
    url: "/smd/plf/camera/ptzControl",
    method: 'post',
    data: data
  });
}
// 获取企业直播数
function queryCountLiveEnterprise() {
  return request({
    url: "/smd/plf/live/queryCountLiveEnterprise",
    method: 'post',
    data: {}
  });
}

// 禁播摄像头
function disableLiveCamera(data) {
  return request({
    url: "/smd/plf/live/disableLiveCamera",
    method: 'post',
    data: data
  });
}
// 解除禁播摄像头
function enableLiveCamera(data) {
  return request({
    url: "/smd/plf/live/enableLiveCamera",
    method: 'post',
    data: data
  });
}
// 恢复摄像头
function openLiveCamera(data, type) {
  return request({
    url: "/smd/".concat(type, "/live/openLiveCamera"),
    method: 'post',
    data: data
  });
}
var live = {
  getStreamingText: getStreamingText,
  getEntStreamingText: getEntStreamingText,
  getEnterpriseList: getEnterpriseList,
  bindCameras: bindCameras,
  getCamerasPage: getCamerasPage,
  getTaskPage: getTaskPage,
  getentCameraPage: getentCameraPage,
  getLiveListDetail: getLiveListDetail,
  addLiveList: addLiveList,
  getTaskCameraPage: getTaskCameraPage,
  getPlfTaskCameraPage: getPlfTaskCameraPage,
  liveTaskUpdate: liveTaskUpdate,
  liveTaskDelete: liveTaskDelete,
  liveCategoryeList: liveCategoryeList,
  liveTextPage: liveTextPage,
  liveTextUpdate: liveTextUpdate,
  shopEnterprise: shopEnterprise,
  getPlfTaskPage: getPlfTaskPage,
  bindEnterprise: bindEnterprise,
  queryCountLiveCamera: queryCountLiveCamera,
  closeLiveEnterprise: closeLiveEnterprise,
  queryListLiveCamera: queryListLiveCamera,
  getLiveUrl: getLiveUrl,
  closeLiveCamera: closeLiveCamera,
  getCameraArea: getCameraArea,
  getLiveNum: getLiveNum,
  getLivePvUv: getLivePvUv,
  getLiveEnterpriseTop: getLiveEnterpriseTop,
  getLiveCameraTop: getLiveCameraTop,
  getLiveTimeNum: getLiveTimeNum,
  getAreaData: getAreaData,
  getLiveUserArea: getLiveUserArea,
  cameraPtzControl: cameraPtzControl,
  disableLiveCamera: disableLiveCamera,
  enableLiveCamera: enableLiveCamera,
  openLiveCamera: openLiveCamera,
  queryCountLiveEnterprise: queryCountLiveEnterprise
};
export default live;