import Layout from '@/layout';
var orderServicePlatRouter = {
  path: '/orderServicePlat',
  component: Layout,
  redirect: '/orderPlat/order-service',
  name: 'OrderServicePlat',
  meta: {
    title: '售后管理',
    icon: 'component'
  },
  children: [{
    path: '/orderPlat/order-service',
    component: function component() {
      return import('@/views/orderPlat/order-service');
    },
    name: 'OrderServicePlatService',
    meta: {
      title: '售后原因',
      icon: 'tree'
    }
  }, {
    path: '/orderPlat/sales-set',
    component: function component() {
      return import('@/views/orderPlat/order-service/sales-set');
    },
    name: 'salesSetCode',
    meta: {
      title: '售后设置',
      icon: 'tree'
    }
  }, {
    path: '/orderPlat/order-service/drawback',
    component: function component() {
      return import('@/views/orderPlat/order-service/drawback');
    },
    name: 'OrderServicePlatDrawback',
    meta: {
      title: '退款申请',
      icon: 'tree'
    }
  }, {
    path: '/orderPlat/order-service/returned-goods',
    component: function component() {
      return import('@/views/orderPlat/order-service/returned-goods');
    },
    name: 'OrderServicePlatReturnedGoods',
    meta: {
      title: '退货申请',
      icon: 'tree'
    }
  }, {
    path: '/orderPlat/order-service/returnedComplain',
    component: function component() {
      return import('@/views/orderPlat/order-service/returned-complain');
    },
    name: 'ReturnedComplain',
    meta: {
      title: '退货申诉',
      noCache: true
    }
  }, {
    path: '/orderPlat/order-service/returnedComplainDetail',
    component: function component() {
      return import('@/views/orderPlat/order-service/returned-complain-detail');
    },
    name: 'ReturnedComplainDetail',
    meta: {
      title: '申诉详情',
      activeMenu: '/orderPlat/order-service/returnedComplainDetail',
      noCache: true
    },
    hidden: true
  }, {
    path: '/orderPlat/order-service/exchangeComplain',
    component: function component() {
      return import('@/views/orderPlat/order-service/exchange-complain');
    },
    name: 'ExchangeComplainPlat',
    meta: {
      title: '换货申诉',
      noCache: true
    }
  }, {
    path: '/orderPlat/order-service/exchangeComplainDetail',
    component: function component() {
      return import('@/views/orderPlat/order-service/exchangeComplainDetail');
    },
    name: 'ExchangeComplainPlatDetail',
    meta: {
      title: '申诉详情',
      noCache: true,
      activeMenu: '/orderPlat/order-service/exchangeComplain'
    },
    hidden: true
  }, {
    path: '/orderPlat/order-service/appealWater',
    component: function component() {
      return import('@/views/orderPlat/order-service/appealWater');
    },
    name: 'AppealWater',
    meta: {
      title: '申诉流水',
      activeMenu: '/orderPlat/order-service/appealWater'
    }
  }, {
    path: '/orderPlat/order-service/changed-goods',
    component: function component() {
      return import('@/views/orderPlat/order-service/changed-goods');
    },
    name: 'OrderServicePlatChangedGoods',
    meta: {
      title: '换货申请',
      icon: 'tree'
    }
  }, {
    path: '/orderPlat/order-service/drawback-detail',
    component: function component() {
      return import('@/views/orderPlat/order-service/drawback-detail');
    },
    name: 'OrderServicePlatDrawbackDetail',
    meta: {
      // title: '退款详情',
      noCache: true,
      activeMenu: '/orderPlat/order-service/drawback'
    },
    hidden: true
  }, {
    path: '/orderPlat/order-service/returned-goods-detail',
    component: function component() {
      return import('@/views/orderPlat/order-service/returned-goods-detail');
    },
    name: 'OrderServicePlatReturnedGoodsDetail',
    meta: {
      // title: '退货详情',
      noCache: true,
      activeMenu: '/orderPlat/order-service/returned-goods'
    },
    hidden: true
  }, {
    path: '/orderPlat/order-service/changed-goods-detail',
    component: function component() {
      return import('@/views/orderPlat/order-service/changed-goods-detail');
    },
    name: 'OrderServicePlatChangedGoodsDetail',
    meta: {
      // title: '换货详情',
      activeMenu: '/orderPlat/order-service/changed-goods'
    },
    hidden: true
  }, {
    path: '/orderPlat/order-service/store-aftermarket',
    component: function component() {
      return import('@/views/orderPlat/order-service/store-aftermarket');
    },
    name: 'OrderServicePlatStoreAftermarket',
    meta: {
      title: '门店售后',
      icon: 'tree'
    }
  }, {
    path: '/orderPlat/appeal-setting/index',
    component: function component() {
      return import('@/views/orderPlat/appeal-setting/index');
    },
    name: 'ApplySetting',
    meta: {
      title: '申诉设置',
      icon: 'list'
    }
  }, {
    path: '/orderPlat/order-service/aftermarket-reason',
    component: function component() {
      return import('@/views/orderPlat/order-service/aftermarket-reason');
    },
    name: 'OrderServicePlatStoreAftermarketReason',
    meta: {
      // title: '售后原因',
      activeMenu: '/orderPlat/order-service/store-aftermarket'
    },
    hidden: true
  }, {
    path: '/orderPlat/order-service/aftermarket-detail',
    component: function component() {
      return import('@/views/orderPlat/order-service/aftermarket-detail');
    },
    name: 'OrderServicePlatStoreDrawbackDetail',
    meta: {
      // title: '售后详情',
      activeMenu: '/orderPlat/order-service/store-aftermarket'
    },
    hidden: true
  }, {
    path: '/orderPlat/order-service/storeDeliveryAfterPlat',
    component: function component() {
      return import('@/views/orderPlat/order-service/storeDeliveryAfterPlat');
    },
    name: 'StoreDeliveryAfterPlat',
    meta: {
      title: '配送门店售后',
      icon: 'tree'
    }
  }, {
    path: '/orderPlat/order-service/storeDeliveryAfterPlatDetails',
    component: function component() {
      return import('@/views/orderPlat/order-service/storeDeliveryAfterPlatDetails');
    },
    name: 'StoreDeliveryAfterPlatDetails',
    meta: {
      title: '配送售后详情',
      activeMenu: '/orderPlat/order-service/storeDeliveryAfterPlat'
    },
    hidden: true
  }]
};
export default orderServicePlatRouter;