import request from '@/utils/request';
export function getCategoryList(params) {
  return request({
    url: '/wechat/service/group/list',
    method: 'get',
    params: params
  });
}
export function deleteCategory(id) {
  return request({
    url: '/wechat/service/group/' + id,
    method: 'delete'
  });
}
export function updateCategory(data) {
  return request({
    url: '/wechat/service/group',
    method: 'post',
    data: data
  });
}
export function addCategory(data) {
  return request({
    url: '/wechat/service/group',
    method: 'post',
    data: data
  });
}
// 成员管理 - 分组客服查询
export function getMemberListForChangeSession(id, shopId) {
  return request({
    url: '/wechat/service/group/' + id + '/?shopId=' + shopId,
    method: 'get'
  });
}
// 成员管理 - 分组客服查询
export function getMemberList(id) {
  return request({
    url: '/wechat/service/group/all/' + id,
    method: 'get'
  });
}
// 成员管理 - 分组客服查询-删除
export function deleteMemberList(id) {
  return request({
    url: '/wechat/service/group/remove/' + id,
    method: 'delete'
  });
}
// 批量增加
export function batAddMember(data) {
  return request({
    url: '/wechat/service/group/service/add',
    method: 'post',
    data: data
  });
}

//客服转接
export function serviceTransfer(data) {
  return request({
    url: '/wechat/lineUp/serviceTransfer',
    method: 'post',
    data: data
  });
}