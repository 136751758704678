/** When your routing table is too long, you can split it into small modules**/
// 流媒体管理-平台
import Layout from '@/layout';
var mediaRouter = {
  path: '/media',
  component: Layout,
  redirect: '/media/pushlist',
  name: 'Media',
  meta: {
    title: '流媒体管理-平台',
    icon: 'component'
  },
  children: [{
    path: '/media/pushlist',
    component: function component() {
      return import('@/views/media/pushlist/pushlist');
    },
    name: 'Push',
    meta: {
      title: '推流器列表',
      icon: 'peoples'
    }
  }, {
    path: '/media/cameralist',
    component: function component() {
      return import('@/views/media/cameralist/cameralist');
    },
    name: 'Camera',
    meta: {
      title: '摄像头列表',
      icon: 'role'
    }
  }, {
    path: '/media/companylist',
    component: function component() {
      return import('@/views/media/companylist/companylist');
    },
    name: 'Company',
    meta: {
      title: '企业列表',
      icon: 'group'
    }
  }, {
    path: '/media/categorylist',
    component: function component() {
      return import('@/views/media/categorylist/categorylist');
    },
    name: 'Category',
    meta: {
      title: '类别管理',
      icon: 'group'
    }
  }, {
    path: '/live/companylist',
    component: function component() {
      return import('@/views/live/companylist/companylist');
    },
    name: 'Companylist',
    meta: {
      title: '企业直播',
      icon: 'peoples'
    }
  }, {
    path: '/live/cameralist',
    component: function component() {
      return import('@/views/live/cameralist/cameralist');
    },
    name: 'Cameralist',
    meta: {
      title: '摄像头直播',
      icon: 'role'
    }
  }, {
    path: '/live/webPullUrl',
    component: function component() {
      return import('@/views/live/cameralist/webPullUrl');
    },
    hidden: true,
    name: 'WebPullUrl',
    meta: {
      title: '直播监控视频',
      icon: 'role'
    }
  }]
};
export default mediaRouter;