// 站长后台

import Layout from '@/layout';
var agencymanagementRouter = {
  path: '/agencymanagement',
  component: Layout,
  redirect: '/agencymanagement/attractlist',
  // alwaysShow: true,
  name: 'Agencymanagement',
  meta: {
    title: '站长后台',
    icon: 'role'
  },
  children: [{
    path: 'attractlist',
    component: function component() {
      return import('@/views/agencymanagement/attractlist.vue');
    },
    name: 'Attractlist',
    meta: {
      title: '招商专员',
      icon: 'peoples'
    }
  }, {
    path: 'particulars/:id',
    component: function component() {
      return import('@/views/agencymanagement/particulars.vue');
    },
    name: 'Particulars',
    meta: {
      title: '流水信息',
      noCache: true,
      activeMenu: '/agencymanagement/attractlist'
    },
    hidden: true
  }, {
    path: 'shopslist',
    component: function component() {
      return import('@/views/agencymanagement/shopslist.vue');
    },
    name: 'Shopslist',
    meta: {
      title: '商铺列表',
      noCache: true,
      activeMenu: '/agencymanagement/attractlist'
    },
    hidden: true
  }, {
    path: 'companyDetail',
    component: function component() {
      return import('@/views/companyPlat/companyDetail.vue');
    },
    name: 'AgencyAttachCompanyDetail',
    meta: {
      title: '企业详情',
      noCache: true,
      activeMenu: '/agencymanagement/shopslist'
    },
    hidden: true
  }, {
    path: 'shopDetail',
    component: function component() {
      return import('@/views/agencymanagement/shopsdetail.vue');
    },
    name: 'Shopsdetail',
    meta: {
      title: '商铺详情',
      noCache: true,
      activeMenu: '/agencymanagement/attractlist'
    },
    hidden: true
  }, {
    path: 'storeDetail',
    component: function component() {
      return import('@/views/agencymanagement/storeDetail.vue');
    },
    name: 'AgencyAttachStoreDetail',
    meta: {
      title: '门店详情',
      noCache: true,
      activeMenu: '/agencymanagement/attractlist'
    },
    hidden: true
  }]
};
export default agencymanagementRouter;