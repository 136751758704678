/** When your routing table is too long, you can split it into small modules **/
// 站长端-站长管理
import Layout from '@/layout';
var attractWalletRouter = {
  path: '/attractWallet',
  component: Layout,
  redirect: '/attractWallet/purseList',
  name: 'AttractWallet',
  meta: {
    title: '招商专员-钱包',
    icon: 'component'
  },
  children: [{
    path: 'purseList',
    component: function component() {
      return import('@/views/attractWallet/purseList.vue');
    },
    name: 'AttractPurseList',
    meta: {
      title: '钱包',
      icon: 'money',
      isCache: true,
      noCache: true
    }
  }, {
    path: 'transactionPassword',
    component: function component() {
      return import('@/views/attractWallet/transactionPassword.vue');
    },
    name: 'AttractTransactionPassword',
    meta: {
      title: '支付密码',
      icon: 'list',
      isCache: true,
      noCache: true
    },
    hidden: true
  }, {
    path: 'editPassword',
    component: function component() {
      return import('@/views/attractWallet/editPassword.vue');
    },
    name: 'AttractEditPassword',
    meta: {
      title: '修改支付密码',
      icon: 'list',
      isCache: true,
      noCache: true
    },
    hidden: true
  }, {
    path: 'attractgatheringaccount',
    component: function component() {
      return import('@/views/attractWallet/gatheringaccount.vue');
    },
    name: 'AttractWalletGatheringaccount',
    meta: {
      title: '收款账号',
      icon: 'list',
      isCache: true,
      noCache: true
    },
    hidden: true
  }]
};
export default attractWalletRouter;