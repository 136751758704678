// 门店端-首页
import Layout from '@/layout';
var storeHomeRouter = {
  path: '/store',
  component: Layout,
  redirect: '/store/home',
  name: 'Store',
  meta: {
    title: '首页-门店',
    icon: 'dashboard'
  },
  children: [{
    path: 'home',
    component: function component() {
      return import('@/views/storeHome/home');
    },
    name: 'storeHome',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }]
};
export default storeHomeRouter;