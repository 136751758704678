import request from '@/utils/request';

//获取融云token、
export function getToken(nearbyId) {
  // console.log('nearbyId,,,'+nearbyId)
  return request({
    url: '/sysadmin/chat/token' + (nearbyId ? '?nearbyId=' + nearbyId : ''),
    method: 'get'
  });
}

//获取用户信息
export function getUserByIds(params) {
  return request({
    url: '/wechat/serviceInfo/getUserInfoByUserId/' + params,
    method: 'get'
  });
}

//获取排队列表
export function getQueueList(data) {
  return request({
    url: '/wechat/lineUp/getList',
    method: 'get',
    params: data
  });
}
// 客服接入 (在排队队列中选择用户接入) 
export function serviceJoinUp(data) {
  return request({
    url: '/wechat/lineUp/serviceJoinUp',
    method: 'post',
    data: data
  });
}
// 获取排队人数
export function getCountp(shopId, shopType) {
  return request({
    url: "/wechat/lineUp/getCount?shopId=".concat(shopId, "&shopType=").concat(shopType),
    method: 'get'
  });
}
// 获取客服在线状态
//storeType  商铺类型（0平台端，1商铺端）
export function getServiceStatus(storeType) {
  return request({
    url: '/wechat/serviceInfo/getServiceStatus',
    method: 'post',
    data: {
      storeType: storeType
    }
  });
}
// 设置客服在线状态
export function setServiceStatus(id, serviceStatus, storeType) {
  // console.log("api 设置客服在线状态:",id,serviceStatus,storeType)
  return request({
    url: '/wechat/serviceInfo/updateServiceStatus',
    method: 'post',
    data: {
      id: id,
      serviceStatus: serviceStatus,
      storeType: storeType
    }
  });
}
// 更新客服会话状态
export function updateSession(id, status) {
  return request({
    url: '/wechat/recordSession/updateSession/' + status + "?id=" + id,
    method: 'put'
  });
}
// 点击左边会话，显示这个用户状态
export function checkSessionStatus(id, shopId, shopType) {
  return request({
    url: "/wechat/recordSession/checkSessionStatus/" + id + "/?shopId=".concat(shopId, "&shopType=").concat(shopType),
    method: 'get'
  });
}
// lineUp/getServiceTransferMsg/{sessionId}

// 获取客服转接消息
export function getServiceTransferMsg(id) {
  return request({
    url: '/wechat/lineUp/getServiceTransferMsg/' + id,
    method: 'get'
  });
}

// 获取客服排队消息
export function getLineUpOrOutLineMsg(sessionId, type) {
  return request({
    url: '/wechat/lineUp/getLineUpOrOutLineMsg',
    data: {
      sessionId: sessionId,
      type: type
    },
    method: 'post'
  });
}

//聊天对象是否加入黑名单
export function getBlacklistFindbyUserId(userId) {
  return request({
    url: '/sysadmin/blacklist/findbyUserId/' + userId,
    method: 'get'
  });
}

//返回客服未结束的会话
export function getCurrentSessionMemberList(userId) {
  return request({
    url: '/wechat/recordSession/getCurrentSessionMemberList',
    method: 'get'
  });
}

// 集成4个实时查询接口,查询排队数量/会话状态/正在聊天的会话集合/客服状态
export function getServiceResult(memberId, storeType, shopId, shopType) {
  var data = {
    memberId: memberId,
    storeType: storeType,
    shopId: shopId,
    shopType: shopType
  };
  return request({
    url: '/wechat/recordSession/getServiceResult',
    method: 'post',
    data: data
  });
}