import Layout from '@/layout';
var purseRouter = {
  path: '/purse',
  component: Layout,
  redirect: '/purse/list',
  // 我们暂时不需要重定向
  alwaysShow: true,
  name: 'Purse',
  meta: {
    title: '钱包-企业后台',
    icon: 'component'
  },
  children: [{
    path: 'purse/purseList',
    component: function component() {
      return import('@/views/purse/purseList.vue');
    },
    name: 'PurseList',
    meta: {
      title: '钱包',
      icon: 'list'
    }
  }, {
    path: 'purse/shopBills',
    component: function component() {
      return import('@/views/purse/shopBills.vue');
    },
    name: 'ShopBills',
    meta: {
      title: '店铺流水',
      icon: 'list'
    }
  }, {
    path: 'purse/storeBills',
    component: function component() {
      return import('@/views/purse/storeBills.vue');
    },
    name: 'StoreBills',
    meta: {
      title: '门店流水',
      icon: 'list'
    }
  }, {
    path: 'purse/transactionPassword',
    component: function component() {
      return import('@/views/purse/transactionPassword.vue');
    },
    name: 'TransactionPassword',
    meta: {
      title: '支付密码',
      icon: 'list',
      isCache: true,
      noCache: true
    },
    hidden: true
  }, {
    path: 'purse/editPassword',
    component: function component() {
      return import('@/views/purse/editPassword.vue');
    },
    name: 'EditPassword',
    meta: {
      title: '修改支付密码',
      icon: 'list',
      isCache: true,
      noCache: true
    },
    hidden: true
  }]
};
export default purseRouter;