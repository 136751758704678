// 商铺管理 
import Layout from '@/layout';
var companyPlatRouter = {
  path: '/companyPlat',
  component: Layout,
  redirect: '/companyPlat/companyList',
  alwaysShow: true,
  name: 'CompanyPlat',
  meta: {
    title: '企业管理-平台',
    icon: 'role'
  },
  children: [{
    path: 'companyList',
    component: function component() {
      return import('@/views/companyPlat/companyList');
    },
    name: 'CompanyList',
    meta: {
      title: '企业列表',
      icon: 'peoples'
    }
  }, {
    path: '/customerPool/list',
    component: function component() {
      return import('@/views/custormer/index');
    },
    name: 'customerList',
    meta: {
      title: '企业客户池',
      icon: 'list'
    }
  }, {
    path: '/custormer/contract',
    component: function component() {
      return import('@/views/companyPlat/concata');
    },
    name: 'ContractTemplate',
    meta: {
      title: '合同管理',
      icon: 'list'
    }
  }, {
    path: '/webAdmin/list',
    component: function component() {
      return import('@/views/custormer/webIndex');
    },
    name: 'CusIntention',
    meta: {
      title: '客户意向表',
      icon: 'list'
    }
  }, {
    path: 'companyListNew',
    component: function component() {
      return import('@/views/companyPlat/companyListNew');
    },
    name: 'CompanyListNew',
    meta: {
      title: '新企业列表',
      icon: 'peoples'
    }
  }, {
    path: 'shopClassifyList',
    component: function component() {
      return import('@/views/companyPlat/shopClassifyList.vue');
    },
    name: 'ShopClassifyList',
    meta: {
      title: '商铺分类',
      icon: 'peoples'
    }
  }, {
    path: 'addCompany',
    component: function component() {
      return import('@/views/companyPlat/addCompany');
    },
    name: 'AddCompany',
    meta: {
      title: '新增企业',
      noCache: true,
      isCache: true,
      activeMenu: '/companyPlat/companyList'
    },
    hidden: true
  }, {
    path: 'addCompanyNew',
    component: function component() {
      return import('@/views/companyPlat/addCompanyNew');
    },
    name: 'AddCompanyNew',
    meta: {
      title: '新增企业',
      noCache: true,
      isCache: true,
      activeMenu: '/companyPlat/companyListNew'
    },
    hidden: true
  }, {
    path: 'companyDetail',
    component: function component() {
      return import('@/views/companyPlat/companyDetail');
    },
    name: 'CompanyDetail',
    meta: {
      title: '企业详情',
      noCache: true,
      activeMenu: '/companyPlat/companyList'
    },
    hidden: true
  }, {
    path: 'companyDetailNew',
    component: function component() {
      return import('@/views/companyPlat/companyDetailNew');
    },
    name: 'CompanyDetailNew',
    meta: {
      title: '企业详情',
      noCache: true,
      activeMenu: '/companyPlat/companyListNew'
    },
    hidden: true
  }, {
    path: 'editCompany',
    component: function component() {
      return import('@/views/companyPlat/editCompany');
    },
    name: 'EditCompany',
    meta: {
      title: '编辑企业',
      noCache: true,
      isCache: true,
      activeMenu: '/companyPlat/companyList'
    },
    hidden: true
  }, {
    path: 'editCompanyNew',
    component: function component() {
      return import('@/views/companyPlat/editCompanyNew');
    },
    name: 'EditCompanyNew',
    meta: {
      title: '编辑企业',
      noCache: true,
      isCache: true,
      activeMenu: '/companyPlat/companyListNew'
    },
    hidden: true
  }, {
    path: 'editStore',
    component: function component() {
      return import('@/views/companyPlat/editStore');
    },
    name: 'EditStore',
    meta: {
      title: '编辑门店',
      noCache: true,
      activeMenu: '/companyPlat/companyList'
    },
    hidden: true
  }, {
    path: 'editLicense',
    component: function component() {
      return import('@/views/companyPlat/editLicense');
    },
    name: 'EditLicensePlat',
    meta: {
      title: '其他证照-编辑',
      noCache: true,
      activeMenu: '/companyPlat/companyList'
    },
    hidden: true
  }, {
    path: 'applyList',
    component: function component() {
      return import('@/views/companyPlat/applyList');
    },
    name: 'ApplyList',
    meta: {
      title: '企业申请',
      icon: 'tree',
      noCache: true
    }
  }, {
    path: 'applyListNew',
    component: function component() {
      return import('@/views/companyPlat/applyListNew');
    },
    name: 'ApplyListNew',
    meta: {
      title: '企业申请',
      noCache: true,
      icon: 'tree'
    }
  }, {
    path: '/companyPlat/companyApply',
    component: function component() {
      return import('@/views/companyPlat/companyApply');
    },
    name: 'CompanyApply',
    meta: {
      title: '企业审核',
      noCache: true,
      isCache: true,
      activeMenu: '/companyPlat/applyList'
    },
    hidden: true
  }, {
    path: '/companyPlat/shopApproval',
    component: function component() {
      return import('@/views/companyPlat/shopApproval');
    },
    name: 'CompanyShopApproval',
    meta: {
      title: '商铺审批',
      noCache: true,
      isCache: true,
      activeMenu: '/companyPlat/applyList'
    },
    hidden: true
  }, {
    path: '/companyPlat/auditDetail',
    component: function component() {
      return import('@/views/companyPlat/companyApply');
    },
    name: 'AuditDetail',
    meta: {
      title: '审核详情',
      noCache: true,
      activeMenu: '/companyPlat/applyList'
    },
    hidden: true
  }, {
    path: '/companyPlat/companyApplyNew',
    component: function component() {
      return import('@/views/companyPlat/companyApplyNew');
    },
    name: 'CompanyApplyNew',
    meta: {
      title: '企业审核',
      noCache: true,
      activeMenu: '/companyPlat/applyListNew'
    },
    hidden: true
  }, {
    path: '/companyPlat/auditDetailNew',
    component: function component() {
      return import('@/views/companyPlat/companyApplyNew');
    },
    name: 'AuditDetailNew',
    meta: {
      title: '审核详情',
      noCache: true,
      activeMenu: '/companyPlat/applyListNew'
    },
    hidden: true
  }, {
    path: 'flowRecord',
    component: function component() {
      return import('@/views/shopModulePlatform/flowRecord');
    },
    name: 'CompanyShopFlowRecord',
    meta: {
      title: '流水记录',
      icon: 'peoples'
    },
    hidden: true
  },
  // {
  //   path: 'flowRecordDetail',
  //   component: () => import('@/views/shopModulePlatform/flowRecordDetail.vue'),
  //   name: 'FlowRecordDetail',
  //   meta: { title: '流水详情', icon: 'peoples' },
  //   hidden: true
  // },
  {
    path: 'shopGrade',
    component: function component() {
      return import('@/views/shopGrade/shopGradeList.vue');
    },
    name: 'ShopGrade',
    meta: {
      title: '商铺等级',
      icon: 'peoples'
    }
  }, {
    path: 'editMediaDetail',
    component: function component() {
      return import('@/views/companyPlat/editMediaDetail');
    },
    name: 'EditMediaDetail',
    meta: {
      title: '编辑',
      noCache: true,
      activeMenu: '/companyPlat/companyList'
    },
    hidden: true
  }]
};
export default companyPlatRouter;