/** When your routing table is too long, you can split it into small modules **/
// 招商专员-首页
import Layout from '@/layout';
var agencyHomeRouter = {
  path: '/attract',
  component: Layout,
  redirect: '/attract/home',
  name: 'Attract',
  meta: {
    title: '首页-招商专员',
    icon: 'dashboard'
  },
  children: [{
    path: 'home',
    component: function component() {
      return import('@/views/agencyHome/home');
    },
    name: 'AttractHome',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }]
};
export default agencyHomeRouter;