import Layout from '@/layout';
var merchantInteractionPlatRouter = {
  path: '/merchantInteractionPlat',
  component: Layout,
  redirect: '/merchantInteractionPlat/dataStatistics',
  name: 'merchantInteractionPlat',
  meta: {
    title: '商家互动-企业',
    icon: 'component'
  },
  children: [{
    path: '/merchantInteractionPlat/indexIntPlat',
    component: function component() {
      return import('@/views/merchantInteractionPlat/indexIntPlat');
    },
    name: 'indexIntPlat',
    meta: {
      title: '商家互动',
      icon: 'list'
    }
  }, {
    path: '/merchantInteractionPlat/dataStatistics',
    component: function component() {
      return import('@/views/merchantInteractionPlat/dataStatistic/index.vue');
    },
    name: 'dataStatistics',
    meta: {
      title: '数据统计',
      icon: 'list'
    }
  }, {
    path: 'indexRecommendations',
    component: function component() {
      return import('@/views/merchantInteractionPlat/recommend/indexRecommendations');
    },
    name: 'indexRecommendations',
    hidden: true,
    meta: {
      title: '商品推荐',
      icon: 'list'
    }
  }, {
    path: '/merchantInteractionPlat/recommend/addMyrecommendation',
    component: function component() {
      return import('@/views/merchantInteractionPlat/recommend/addMyrecommendation');
    },
    name: 'addMyrecommendation',
    hidden: true,
    meta: {
      title: '添加我的推荐',
      icon: 'list'
    }
  }, {
    path: '/merchantInteractionPlat/recommend/addRecommendations',
    component: function component() {
      return import('@/views/merchantInteractionPlat/recommend/addRecommendations');
    },
    name: 'addRecommendations',
    hidden: true,
    meta: {
      title: '添加我在他人店铺的推荐',
      icon: 'list'
    }
  }, {
    path: 'indexActivity',
    component: function component() {
      return import('@/views/merchantInteractionPlat/activity/indexActivity');
    },
    name: 'indexActivity',
    hidden: true,
    meta: {
      title: '店铺互动',
      icon: 'list'
    }
  }, {
    path: '/merchantInteractionPlat/activity/othersActivitiesDetail/:id?',
    component: function component() {
      return import('@/views/merchantInteractionPlat/activity/othersActivitiesDetail');
    },
    name: 'othersActivitiesDetail',
    hidden: true,
    meta: {
      title: '我在他人店铺的活动详情',
      icon: 'list'
    }
  }, {
    path: '/merchantInteractionPlat/activity/myActivitiesDetail/:id?',
    component: function component() {
      return import('@/views/merchantInteractionPlat/activity/myActivitiesDetail');
    },
    name: 'myActivitiesDetail',
    hidden: true,
    meta: {
      title: '我的活动详情',
      icon: 'list'
    }
  }, {
    path: '/merchantInteractionPlat/activity/ActivitiesPreview/:id?:type?',
    component: function component() {
      return import('@/views/merchantInteractionPlat/activity/ActivitiesPreview');
    },
    name: 'ActivitiesPreview',
    hidden: true,
    meta: {
      title: '活动预览',
      icon: 'list'
    }
  }, {
    path: 'indexAuthorization',
    component: function component() {
      return import('@/views/merchantInteractionPlat/authorization/indexAuthorization');
    },
    name: 'indexAuthorization',
    hidden: true,
    meta: {
      title: '店铺授权',
      icon: 'list'
    }
  }, {
    path: 'messagesManagement',
    component: function component() {
      return import('@/views/merchantInteractionPlat/messagesManagement');
    },
    name: 'messagesManagement',
    hidden: true,
    meta: {
      title: '留言管理',
      icon: 'list'
    }
  }, {
    path: 'bulletManagement',
    component: function component() {
      return import('@/views/merchantInteractionPlat/bulletManagement');
    },
    name: 'bulletManagement',
    hidden: true,
    meta: {
      title: '弹幕管理',
      icon: 'list'
    }
  }, {
    path: 'messageSettingsPlat',
    component: function component() {
      return import('@/views/merchantInteractionPlat/messageSettings.vue');
    },
    name: 'messageSettingsPlat',
    meta: {
      title: '留言设置',
      icon: 'list',
      tabName: 'messageSettingsPlat'
    },
    hidden: true
  }, {
    path: 'merchantInteractionLikePlat',
    component: function component() {
      return import('@/views/merchantInteractionPlat/merchantInteractionLike.vue');
    },
    name: 'merchantInteractionLikePlat',
    meta: {
      title: '留言点赞',
      icon: 'list',
      tabName: 'merchantInteractionLikePlat'
    },
    hidden: true
  }, {
    path: 'messageReportTypePlat',
    component: function component() {
      return import('@/views/merchantInteractionPlat/messageReportType.vue');
    },
    name: 'messageReportTypePlat',
    meta: {
      title: '举报类型',
      icon: 'list',
      tabName: 'messageReportTypePlat'
    },
    hidden: true
  }, {
    path: 'messageBlackListPlat',
    component: function component() {
      return import('@/views/merchantInteractionPlat/messageBlackList.vue');
    },
    name: 'messageBlackListPlat',
    meta: {
      title: '拉黑管理',
      icon: 'list',
      tabName: 'messageBlackListPlat'
    },
    hidden: true
  }, {
    path: 'reportComplaintsPlat',
    component: function component() {
      return import('@/views/merchantInteractionPlat/reportComplaints/list.vue');
    },
    name: 'reportComplaintsPlat',
    meta: {
      title: '举报投诉',
      icon: 'list',
      tabName: 'reportComplaintsPlat'
    },
    hidden: true
  }, {
    path: 'reportComplaintsDetailPlat',
    component: function component() {
      return import('@/views/merchantInteractionPlat/reportComplaints/detail.vue');
    },
    name: 'reportComplaintsDetailPlat',
    meta: {
      title: '举报明细列表',
      icon: 'list',
      tabName: 'reportComplaintsDetailPlat'
    },
    hidden: true
  }, {
    path: 'prohibitionsLexiconPlat',
    component: function component() {
      return import('@/views/merchantInteractionPlat/lexicon/prohibitionsLexicon.vue');
    },
    name: 'prohibitionsLexiconPlat',
    meta: {
      title: '违禁词库',
      icon: 'list',
      tabName: 'prohibitionsLexiconPlat'
    },
    hidden: true
  }]
};
export default merchantInteractionPlatRouter;