import request from '@/utils/request';
export function login(data) {
  var form = {
    username: data.username,
    password: data.password,
    grant_type: 'password',
    scope: 'read',
    type: 'SYSADMIN'
  };
  return request({
    url: '/authorization-server/oauth/token',
    // url: '/gataway-server/oauth/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': data.Authorization ? data.Authorization : 'Basic dGVzdF9jbGllbnQ6dGVzdF9zZWNyZXQ='
      // 'Authorization': 'Basic dGVzdF9jbGllbnQ6dGVzdF9zZWNyZXQ='
    },
    method: 'post',
    params: form
  });
}
export function getInfo(id) {
  return request({
    url: "/sysadmin/user/".concat(id),
    method: 'get'
  });
}
export function logout() {
  return Promise.resolve({
    code: '000000',
    mesg: '成功',
    time: new Date()
  });
}
export function RefreshToken(token) {
  return request({
    url: "/authorization-server/oauth/token?grant_type=refresh_token&scope=read&client_id=test_client&client_secret=test_secret&refresh_token=".concat(token),
    method: 'post'
  });
}

// 根据用户ID获取对应路由
export function getRouter(id) {
  return request({
    url: "/sysadmin/menu/routeList/".concat(id),
    method: 'get'
  });
}
//获取上传签名
export function getUploadImageSign(params) {
  return request({
    url: '/opc/oss/policy',
    method: 'get',
    params: params
  });
}
export function getMediaPreview(params) {
  return request({
    url: '/wechat/message/getMediaPreview',
    method: 'get',
    params: params
  });
}
//上传直传
export function uploadFile(url, data) {
  return request({
    url: url,
    method: 'post',
    dataType: 'application/x-www-form-urlencoded',
    data: data
  });
}
//图片上传
export function uploadImage(data) {
  return request({
    url: '/opc/oss/upload',
    method: 'post',
    dataType: 'application/x-www-form-urlencoded',
    data: data
  });
}

//批量上传
export function batchUploadImage(data) {
  return request({
    url: '/opc/oss/batchUpload',
    method: 'post',
    dataType: 'application/x-www-form-urlencoded',
    data: data
  });
}

//获取区域
export function getAreaData(level) {
  return request({
    url: '/sysadmin/system/area/all?level=' + level,
    method: 'get'
  });
}

//验证商铺信息是否完整
export function verificationInfo(data) {
  return request({
    url: '/sysadmin/user/verify/shop',
    method: 'post',
    data: data
  });
}
// 查询商铺开关
export function verificationShopOnOff() {
  return request({
    url: '/sysadmin/shop/onOff/status',
    method: 'get'
  });
}
// 查询门店开关
export function verificationStoreOnOff() {
  return request({
    url: '/nearby/admin/shop/shopInfo',
    method: 'get'
  });
}

// 4个接口合成一个接口
export function openInfo4() {
  return request({
    url: '/sysadmin/shop/get/openInfo',
    method: 'get'
  });
}