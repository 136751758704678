// 评价管理-企业
import Layout from '@/layout';
var appraiseManage = {
  path: '',
  component: Layout,
  redirect: '/appraiseManage/ShopEvaluate',
  alwaysShow: true,
  name: 'AppraiseManage',
  meta: {
    title: '评价管理',
    icon: 'role'
  },
  children: [
  // 商品评价
  {
    path: '/storeManagement/goodsComment',
    component: function component() {
      return import('@/views/storeManagement/goodsComment/list');
    },
    name: 'ShopGoodsComment',
    meta: {
      title: '门店商品',
      icon: 'peoples'
    }
  }, {
    path: '/storeManagement/commentDetails',
    component: function component() {
      return import('@/views/storeManagement/goodsComment/commentDetails');
    },
    name: 'GoodsCommentDetails',
    hidden: true,
    meta: {
      title: '评论详情',
      icon: 'peoples',
      noCache: true
    }
  }, {
    path: '/product/appraise',
    component: function component() {
      return import('@/views/product/appraise/index');
    },
    name: 'Appraise',
    meta: {
      title: '商铺商品',
      icon: 'documentation'
    }
  }, {
    path: '/product/commentDetails',
    component: function component() {
      return import('@/views/product/appraise/commentDetails');
    },
    name: 'NearbyCommentDetails',
    hidden: true,
    meta: {
      title: '评论详情',
      icon: 'peoples',
      activeMenu: '/product/appraise',
      noCache: true
    }
  },
  // 评价申诉
  {
    path: '/storeManagement/storeManagementGoodsAppeal',
    component: function component() {
      return import('@/views/storeManagement/goodsAppeal/list');
    },
    name: 'StoreManagementGoodsAppeal',
    meta: {
      title: '评价申诉',
      icon: 'peoples'
    }
  },
  // 商铺售后评价
  {
    path: '/apptaiseManage/storeAfterSale',
    component: function component() {
      return import('@/views/apptaiseManage/storeAfterSale');
    },
    name: 'StoreAfterSale',
    meta: {
      title: '商铺售后',
      icon: 'peoples'
    }
  }]
};
export default appraiseManage;