import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.function.name";
import _objectSpread from "/root/.jenkins/workspace/yuexin_vue_239_backup/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { asyncRoutes, constantRoutes } from '@/router';
import { getRouter } from '@/api/user';
function IsPhone() {
  //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
  var info = navigator.userAgent;
  //通过正则表达式的test方法判断是否包含“Mobile”字符串
  var isPhone = /mobile/i.test(info);
  //如果包含“Mobile”（是手机设备）则返回true
  return isPhone;
}
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: [],
  homeType: '',
  homeRoute: ''
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    if (state.homeRoute == '/dashboard') {
      state.routes = constantRoutes.concat(routes);
    } else {
      var newConstantRoutes = JSON.parse(JSON.stringify(constantRoutes));
      for (var i = 0; i < newConstantRoutes.length; i++) {
        if (newConstantRoutes[i].redirect == '/dashboard') newConstantRoutes[i].hidden = true;
        state.routes = newConstantRoutes.concat(routes);
      }
    }
  },
  SET_HOME_TYPE: function SET_HOME_TYPE(state, homeType) {
    state.homeType = homeType;
    if (homeType == "Plat") {
      state.homeRoute = '/plat/home';
    } else if (homeType == "Shop") {
      state.homeRoute = '/shop/home';
    } else if (homeType == "Agency") {
      state.homeRoute = '/agency/home'; //13242312198 13343199916
    } else if (homeType == "Attract") {
      state.homeRoute = '/attract/home';
    } else if (homeType == "Store") {
      state.homeRoute = '/store/home';
    } else {
      // 默认首页   
      console.log('默认首页');
      if (IsPhone()) {
        state.homeRoute = '/msgList';
      } else {
        state.homeRoute = '/dashboard';
      }
    }
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, id) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      getRouter(id).then(function (response) {
        var res = response.data;
        // 返回的权限数据
        // console.log('路由response',res);
        // 路由模块信息
        // console.log(asyncRoutes);
        // 清空路由设置
        commit('SET_HOME_TYPE', '');
        var disposeRoutes = [];
        for (var k = 0; k < res.length; k++) {
          var needtitle = res[k].code; //获取响应匹配名称Organization
          var needchildren = res[k].children; //获取响应子数组
          // console.log("needchildren:",needchildren)
          for (var i = 0; i < asyncRoutes.length; i++) {
            if (asyncRoutes[i].name == needtitle) {
              //console.log(asyncRoutes[i]);
              var childrenRoutes = asyncRoutes[i].children;
              // console.log("childrenRoutes:",childrenRoutes)
              var disposechildren = [];
              // 判断是否勾选了特定首页：homeType用于登录后首页跳转、面包屑首页跳转、401/404回到首页按钮跳转等相关页面
              if (asyncRoutes[i].name == "Plat" || asyncRoutes[i].name == "Shop" || asyncRoutes[i].name == "Agency" || asyncRoutes[i].name == "Attract" || asyncRoutes[i].name == "Store") {
                commit('SET_HOME_TYPE', asyncRoutes[i].name);
              }
              for (var m = 0; m < childrenRoutes.length; m++) {
                for (var w = 0; w < needchildren.length; w++) {
                  // console.log(childrenRoutes[m].name+','+needchildren[w].code)
                  if (childrenRoutes[m].name == needchildren[w].code) {
                    // 处理子icon
                    childrenRoutes[m].meta.icon = needchildren[w].icon;
                    // 处理子title
                    childrenRoutes[m].meta.title = needchildren[w].title;
                    //追加needchildren内部无素一个属性用来保存当前的下标,用于后面的排序，解决二级菜单不按顺序的bug  ---fei 2021-4-1
                    childrenRoutes[m].meta.needchildrenIndex = w;
                    // 处理子数组
                    disposechildren.push(childrenRoutes[m]);
                  }
                }
              }
              // 处理父icon
              asyncRoutes[i].meta.icon = res[k].icon;
              // 处理父title
              asyncRoutes[i].meta.title = res[k].title.replace('-平台', '').replace('-商铺', '').replace('-企业', '').replace('-门店', '').replace('-站长', '');
              // console.log(res[k].title.replace('-平台', '').replace('-商铺', ''));
              //追加needchildren内部无素一个属性用来保存当前的下标,用于后面的排序，解决二级菜单不按顺序的bug  ---fei 2021-4-1
              disposechildren.sort(function (a, b) {
                return a.meta.needchildrenIndex - b.meta.needchildrenIndex;
              });
              // 处理子数组
              asyncRoutes[i].children = disposechildren;
              // console.log('处理', asyncRoutes[i]);
              disposeRoutes.push(asyncRoutes[i]);
              //console.log('最终', disposeRoutes);
            }
          }
        }
        // 处理极端
        disposeRoutes.push(asyncRoutes[asyncRoutes.length - 1]);
        // console.log(disposeRoutes)
        commit('SET_ROUTES', disposeRoutes);
        resolve(disposeRoutes);
      }).catch(function (accident) {
        console.log(accident);
      });
    });
  },
  clearHomeType: function clearHomeType(_ref2, view) {
    var commit = _ref2.commit,
      state = _ref2.state;
    return new Promise(function (resolve) {
      commit('SET_HOME_TYPE', view);
      resolve({
        homeType: state.homeType,
        homeRoute: state.homeRoute
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};