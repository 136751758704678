import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.function.name";
import _createForOfIteratorHelper from "/root/.jenkins/workspace/yuexin_vue_239_backup/node_modules/@babel/runtime-corejs2/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.regexp.search";
import _objectSpread from "/root/.jenkins/workspace/yuexin_vue_239_backup/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SideToolbar from "./SideToolbar";
import SideToolbarStore from "./SideToolbarStore";
import SideToolbarPlat from "./SideToolbarPlat";
import ChangSession from "./changSession";
import QueueList from "./queueList";
import chatRecord from "./chatRecord";
import mAudio from "@/components/audioMsg/audio";
import mp4Player from "@/components/mp4Player/index";
import uploadvideo from "./uploadvideo.vue";
import tool from '@/utils/tool';
import defaultSettings from '@/settings';
// import * as RongIMLib from '@ronglooud/imlib-v4'
// const RongIMLib = require('@ronglooud/imlib-v4')
var appkey = defaultSettings.ServiceChatAppKey;
// console.log('appkey', defaultSettings.appkey)
// const im = RongIMLib.init(appkey)
// RongIMLib.RongIMEmoji.init()
RongIMLib.RongIMEmoji.init();
import { getToken, getUserByIds, getCountp, updateSession, checkSessionStatus, getServiceTransferMsg, getLineUpOrOutLineMsg, getBlacklistFindbyUserId, getCurrentSessionMemberList, getServiceResult } from '@/api/chat';
import { getStore } from '@/utils/auth';
import service from '@/api/serviceManagement/serviceList';
import { uploadImage } from '@/api/user';
import { getDetails } from '@/api/vipManagement/vipList';
// import { set } from 'js-cookie'
// const Base64 = require('js-base64').Base64
// import RongIMLib from './voice'
//初始化
// RongIMLib.RongIMVoice.init()
import base64Black from "./black.js";
import Contextmenu from 'vue-context-menu';
import videoitem from "./videoitem";
import Notify from "@wcjiang/notify";
export default {
  name: 'Chat',
  components: {
    SideToolbarStore: SideToolbarStore,
    SideToolbar: SideToolbar,
    SideToolbarPlat: SideToolbarPlat,
    ChangSession: ChangSession,
    QueueList: QueueList,
    mAudio: mAudio,
    chatRecord: chatRecord,
    mp4Player: mp4Player,
    uploadvideo: uploadvideo,
    Contextmenu: Contextmenu,
    videoitem: videoitem
  },
  props: ['chatUserId', 'chatIndex', 'activeName'],
  filters: {
    /* 时间转换 */
    timeTranHM: function timeTranHM(value) {
      return !value ? '-' : tool.date('Y-m-d H:i', value);
    }
  },
  directives: {
    mdragresize: {
      bind: function bind(el, binding) {
        var maxW = 710;
        var maxH = 500;
        var THIS = binding.value;
        var oDiv = el; // 当前元素
        // let self = this // 上下文
        // 是否可以拖高
        var isDragHeight = function isDragHeight(c) {
          var h = Number(THIS.styleResize1.height.split("px")[0]);
          // console.log("isDragHeight:",h,THIS.styleResize1)
          var c1 = -180;
          //如果到达一个尺寸就不能缩小了
          if (h <= maxH) {
            c1 = 0;
          }
          if (c > c1) {
            // if(h>=maxH){
            return true;
          }
          return false;
        };
        var isDragWidth = function isDragWidth(c) {
          var w = Number(THIS.styleResize1.width.split("px")[0]);
          // console.log("isDragWidth:",w,THIS.styleResize1)
          var c1 = -151;
          //如果到达一个尺寸就不能缩小了
          if (w <= maxW) {
            c1 = 0;
          }
          if (c > c1) {
            // if(w>=maxW){
            return true;
          }
          return false;
        };
        // 是否可以拖宽
        oDiv.onmousedown = function (e) {
          // console.log("onmousedown styleResize1:",THIS.styleResize1)
          // console.log("45666 THIS.styleResize1:",THIS,THIS.styleResize1)
          var startTop = 0;
          var startLeft = 0;
          THIS.dragResizeHeight = 0;
          THIS.dragResizeWidth = 0;
          // 鼠标按下，计算当前元素距离可视区的距离
          var disX = e.clientX - oDiv.offsetLeft;
          var disY = e.clientY - oDiv.offsetTop;
          // console.log("onmousedown:",disX,disY)
          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离
            var l = e.clientX - disX;
            var t = e.clientY - disY;
            // 移动当前元素
            // oDiv.style.left = l + 'px'
            // oDiv.style.top = t + 'px'
            // console.log("THIS.styleResize1_initH:",THIS.styleResize1_initH)
            // console.log("THIS.styleResize1:",THIS.styleResize1)

            if (startTop == 0) {
              startTop = t;
            } else {
              var ct = t - startTop;
              if (isDragHeight(ct)) {
                // console.log("dragResizeHeight:",ct,610)
                THIS.dragResizeHeight = ct;
                var h1 = THIS.styleResize1_initH + ct;
                // if(h1<maxH) h1=maxH
                h1 = h1 + "px";
                THIS.styleResize1 = _objectSpread(_objectSpread({}, THIS.styleResize1), {}, {
                  height: h1
                });
                var h2 = THIS.styleResize2_initH + ct + "px";
                THIS.styleResize2 = {
                  height: h2
                };
                var h3 = THIS.styleResize3_initH + ct + "px";
                THIS.styleResize3 = {
                  height: h3
                };
                var h4 = THIS.styleResize4_initH + ct + "px";
                THIS.styleResize4 = {
                  height: h4
                };
              }
            }
            if (startLeft == 0) {
              startLeft = l;
            } else {
              var cl = l - startLeft;
              // console.log("cl:",cl)
              if (isDragWidth(cl)) {
                THIS.dragResizeWidth = cl;
                var w1 = THIS.styleResize1_initW + cl;
                if (THIS.openBroadside) {
                  if (w1 < 1044) w1 = 1044;
                } else {}
                w1 = w1 + "px";
                THIS.styleResize1 = _objectSpread(_objectSpread({}, THIS.styleResize1), {}, {
                  width: w1
                });
              }
            }
            // console.log("onmousemove:",l,t)
            // console.log("onmousemove styleResize1:",THIS.styleResize1)
          };
          document.onmouseup = function (e) {
            // console.log("onmouseup:")
            //将初始值，设置成当前位置
            THIS.styleResize1_initH = Number(THIS.styleResize1.height.split("px")[0]);
            THIS.styleResize1_initW = Number(THIS.styleResize1.width.split("px")[0]);
            THIS.styleResize2_initH = Number(THIS.styleResize2.height.split("px")[0]);
            THIS.styleResize3_initH = Number(THIS.styleResize3.height.split("px")[0]);
            THIS.styleResize4_initH = Number(THIS.styleResize4.height.split("px")[0]);
            THIS.dragResizeHeight = 0;
            THIS.dragResizeWidth = 0;
            document.onmousemove = null;
            document.onmouseup = null;
          };
          // return false不加的话可能导致黏连，就是拖到一个地方时div粘在鼠标上不下来，相当于onmouseup失效
          return false;
        };
      }
    }
  },
  data: function data() {
    return {
      notice: null,
      showMsgTooltip: false,
      base64Black: base64Black,
      //黑色图片
      currentSessionUserBlacklist: false,
      //当前会话用户是否在黑名单。
      activeNames: "1",
      //会话 Collapse 折叠面板
      centerDialogVisible: false,
      //上传图片旗帜
      search: '',
      //搜索
      isSearch: '',
      //是否正在搜索 暂时无用
      chatShow: false,
      //是否显示聊天窗口
      openBroadside: false,
      //是否显示聊天窗口右边的买家订单和常用回复
      nowID: 0,
      //当前聊天的人的id
      token: '',
      //聊天需要的token
      messageText: '',
      //发送的消息
      targetId: '',
      // 点击当前的id
      targetUserInfo: {},
      // 对面的用户信息
      myUserInfo: {},
      // 我的用户信息
      userId: '',
      //当前用户id
      memberId: '',
      //会员用户id
      // circleUrl:
      //   'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
      circleUrl: require("../../assets/nikeImg1.png"),
      conversationList: [],
      //当前会话列表
      messageByTargetList: [],
      //当前对话
      messageByTargetList_hasMore: false,
      //当前对话是否有更多聊天记录
      messageByTargetList_loading: false,
      setList: [],
      //商铺端-客服回复配置列表
      setPlatList: [],
      //平台端-客服回复配置列表
      servicerList: [],
      //客服信息
      msgText: '',
      //自定义回复消息
      senderId: '',
      //发送者ID
      sentTime: 0,
      //发送消息的时间戳
      addTime: 0,
      //某时间段的时间戳
      storeId: '',
      //商铺id
      imgList: [],
      //发送的图片
      imageUrl: '',
      //上传的图片链接
      imgBase: '',
      //图片的base64码
      imgBaseCode: '',
      messageImage: '',
      dialogContent: '',
      //确认是否发送图片的预览内容
      msgType: '',
      //消息类型
      emojiList: [],
      //表情包列表
      emojiPopoverVisible: false,
      //表情包弹窗旗帜
      clickIndex: -1,
      //左边的会话列表选中的下标
      userType: '',
      //用户角色类型：1为平台，3为商铺
      selfSendMsg: false,
      //是否为发送自动回复语和常用回复语
      endSessionStatus: 0,
      //是否已结束对话,0为未结束，1为已结束
      endMsgText: '',
      //结束语
      startSessionTime: '',
      //开始会话时间
      clientWidth: document.body.clientWidth,
      //网页可见区域宽
      totalUnreadCount: 0,
      //未读消息总数
      //在线状态
      onlineStateOptions: [{
        label: "在线",
        value: 1
      }, {
        label: "小歇",
        value: 2
      }],
      // 历史记录弹框
      chatRecordPadVisible: false,
      chatRecordPadList: [],
      //改变窗口尺寸
      styleResize1_initH: 705,
      styleResize1_initW: 850,
      styleResize1: {
        height: 705 + "px",
        width: 850 + "px"
      },
      //div class service-chat
      styleResize2_initH: 485,
      styleResize2: {
        height: 485 + "px"
      },
      //div class chat-msg-box
      styleResize3_initH: 524,
      styleResize3: {
        height: 524 + "px"
      },
      //div class group-user
      styleResize4_initH: 594 + 30,
      styleResize4: {
        height: 594 + 30 + "px"
      },
      //div class chat-left chat-right
      THIS: this,
      dragResizeHeight: 0,
      //拖动高度
      dragResizeWidth: 0,
      //拖动宽度
      queueCount: "",
      //排队人数
      identity: "",
      //平台: PLATFORM, 企业: ENTERPRISE, APP: APP
      currentSessionTargetId: "",
      //当前会话TargetId
      currentSessionId: "",
      //当前会话SessionId
      currentSessionStatus: false,
      //当前会话目标用户是否在线聊
      isAnCLose: false,
      //是否加入退出动画
      allowedSendMessage: false,
      //后台新增设置，如果设置true，currentSessionStatus==false,也可以发。
      currentOpenMenuMesg: null,
      currentOpenMenuMesg_idx: 0,
      //用于刷新，获取当前聊天记录
      current_getAllMessageByTargetId_item: null,
      current_getAllMessageByTargetId_index: -1,
      isShopUser: false,
      isStoreUser: false,
      shopUserId: null
    };
  },
  created: function created() {
    // 断开链接
    this.initDisconnect();
    //基本数据
    this.baseData();
    // token 链接
    this.resetInterlinkage(null);
  },
  mounted: function mounted() {
    this.identity = getStore('identity');
    console.log(this.identity);
    // console.log("ServiceChat mounted...")
    if (this.identity == "PLATFORM") {
      //this.getServiceSetPlat()
    } else {
      // this.getServiceSet()
      //this.getUserInfoByStoreId() //注：根据商铺ID,查询客服信息，好像没有什么用，先隐藏
      // this.getShopIdByUserId()
    }
    this.loopDo();
  },
  computed: {
    im: function im() {
      return this.$parent.map[this.chatIndex];
    },
    isDebug: function isDebug() {
      if (APP_ENV == 'prod' || APP_ENV == 'uat') {
        return false;
      }
      return this.$parent.chatShow;
    },
    onlineState: {
      get: function get() {
        // console.log("onlineState get:",this.$store.state)
        return this.$store.state.chat.onlineState;
      },
      set: function set(value) {
        // console.log("computed onlineState set:",value)
        this.$store.commit('chat/CHANG_OnlineStatus', value);
      }
    },
    filterConversationList: function filterConversationList() {
      var _this = this;
      // console.log("filterConversationList:",this.conversationList)
      var arr = [];
      if (this.search == "") {
        // console.log("filterConversationList:",JSON.stringify(this.conversationList))
        arr = this.conversationList.filter(function (item) {
          return item.targetId != '';
        });
      } else {
        arr = this.conversationList.filter(function (item) {
          return item.user && item.user.nickName.indexOf(_this.search) != -1;
        });
      }
      // console.log("filterConversationList:",arr)
      arr.sort(function (a, b) {
        var a1 = a.__isSesson ? 1 : 0;
        var b1 = b.__isSesson ? 1 : 0;
        return b1 - a1;
      });
      // console.log("排完序123123:",JSON.stringify(arr))
      return arr;
    },
    //当前会话是不是在线(可以发信息，转接出云，结束会话)
    isCurrentSessionChat: function isCurrentSessionChat() {
      // console.log(this.filterConversationList+',,this.currentSessionId ,'+this.currentSessionId,',,this.currentSessionStatus,,',this.currentSessionStatus)
      return this.filterConversationList.length > 0 && this.memberId && this.currentSessionId && this.currentSessionStatus;
    }
  },
  methods: {
    clickTab: function clickTab() {},
    // 基本数据
    baseData: function baseData() {
      var userInfo = JSON.parse(getStore('userInfo'));
      this.isDebug && console.log("created userInfo:", userInfo);
      var isShopUser = false;
      var isStoreUser = false;
      for (var i = 0; i < userInfo.roleNames.length; i++) {
        if (userInfo.roleNames[i] == '企业端-商铺角色') {
          isShopUser = true;
        }
        if (userInfo.roleNames[i] == '企业端-门店角色') {
          isStoreUser = true;
        }
      }
      this.isShopUser = isShopUser;
      this.isStoreUser = isStoreUser;
      this.userId = userInfo.id;
      this.userType = userInfo.userType;
      // console.log('created this.userId',this.userId)
      if (userInfo.shopId) {
        this.companyId = userInfo.shopId;
        this.storeId = this.companyId;
      }
      this.emojiList = RongIMLib.RongIMEmoji.list;
      var _iterator = _createForOfIteratorHelper(this.emojiList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var _i = _step.value;
          _i.emojiItem = RongIMLib.RongIMEmoji.symbolToEmoji(_i.emoji);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      this.emojiList.splice(3, 1);
    },
    // 初始化断开流程
    initDisconnect: function initDisconnect() {
      this.bus.$on('logoutStatus', function (data) {
        // console.log('logoutStatus',data)
        this.im.disconnect().then(function () {
          return console.log('断开链接成功');
        });
      });
    },
    // 重新连接
    resetInterlinkage: function resetInterlinkage() {
      var _this2 = this;
      this.shopUserId = this.chatUserId;
      new Promise(function (resolve, reject) {
        //获取用户token建立连接
        _this2.getUserToken(resolve, reject);
      }).then(function () {
        _this2.init();
      });
    },
    getMsgType: function getMsgType(type) {
      if (type == 'RC:TypSts' || type == 'RC:ImgTextMsg' || type == 'RC:FileMsg' || type == 'RC:LBSMsg' || type == 'RC:CombineMsg' || type == 'RC:UIUMsg' || type == 'RC:ContactNtf' || type == 'RC:CmdMsg' || type == 'RC:SRSMsg' || type == 'RC:RRReqMsg' || type == 'RC:RRRspMsg' || type == 'RC:ReadNtf') {
        return true;
      } else {
        return false;
      }
    },
    timeTranHM: function timeTranHM(value) {
      return !value ? '-' : tool.date('Y-m-d H:i', value);
    },
    //试着json解析，如果有message或content就是直接显示
    msg_TRANSFER: function msg_TRANSFER(msg) {
      // this.isDebug&&console.log("msg_TRANSFER:",msg)
      if (typeof msg == 'undefined') {
        return "";
      }
      try {
        var data = JSON.parse(msg);
        if (data.message) {
          return data.message;
        } else if (data.content.content) {
          return data.content.content;
        } else if (data.content) {
          return data.content;
        }
        return "";
      } catch (e) {
        this.isDebug && console.log("msg_TRANSFER catch:", e, 'msg:', msg);
        return "";
      }
    },
    playVideo: function playVideo(url) {
      this.isDebug && console.log("播放视频:" + url);
      this.$refs.mp4Player.open(url);
    },
    TranslationMsgContentLatestMessage: function TranslationMsgContentLatestMessage(item) {
      // console.log("item.latestMessage:",item.latestMessage)
      switch (item.latestMessage.messageType) {
        case "App:SimpleMsg":
        case "RC:TxtMsg":
          //如果是一个json格式，试着解析它
          var content = item.latestMessage.content.content;
          if (content && content.indexOf("{\"") != -1) {
            try {
              var obj = JSON.parse(content);
              if (obj.content) {
                content = obj.content.content || obj.content;
              } else {
                content = '[消息通知]';
              }
            } catch (e) {
              console.log("TranslationMsgContentLatestMessage 错误:", e, item.latestMessage.content.content);
            }
          }
          return content;
        case "WEB:TRANSFER":
          return "[转接信息]";
        case "RC:HQVCMsg":
          return "[音频]";
        case "App:YXGoodsMsg":
          return "[商品消息]";
        case "App:YXGoodsMsg":
          return "[订单消息]";
        case "RC:ImgMsg":
          return "[图片]";
        case "WEB:LineOrOutLine":
          return "[新消息]";
        default:
          return "[新消息]";
      }
    },
    Translation_YXProblem: function Translation_YXProblem(item, msg) {
      if (item.messageType == "App:YXProblem") {
        try {
          var c = JSON.parse(msg);
          var out = "\u7B49\u5F85\u7528\u6237\u9009\u62E9\uFF1A<ul style=\" padding-left: 7px;padding-top: 7px;\">";
          var other = "";
          if (c.length > 5) {
            c = c.slice(0, 5);
            other = "<li>...</li>";
          }
          out = out + c.map(function (item) {
            return "<li>" + item + "</li>";
          }).join("");
          out = out + other;
          out = out + "</ul>";
          return out;
        } catch (e) {
          return msg;
        }
      }
      return msg;
    },
    TranslationMsgContent: function TranslationMsgContent(item) {
      // console.log("--==!!!!!!!!!!!!!!!!!!!!!!!!!==-1--:",item)
      if (!item || !item.content || !item.content.content) return '';
      //如果 item.content.content 里面是类似这个格式  {"content":"欢迎光临","session":{"id":"1415918291367952385","status":1}}
      //if(item.content.content.indexOf('session')!=-1){
      try {
        var c = JSON.parse(item.content.content);
        //  console.log("--==!!!!!!!!!!!!!!!!!!!!!!!!!==---:",c.content.content,c.content)
        if (!c.content.content) {
          //  console.log("--==!!!!!!!!!!!!!!!!222!!!!!!!!!==---:",c.content)
          if (c.content.indexOf("content") == -1) {
            //下面一层是不是有json，要再编译
            return this.Translation_YXProblem(item, c.content);
          } else {
            var c1 = JSON.parse(c.content);
            return this.Translation_YXProblem(item, c1.content);
          }
        } else {
          return this.Translation_YXProblem(item, c.content.content);
        }
      } catch (e) {
        //  return "转接解析错误#1:"+JSON.stringify(e)+"解析的数据："+JSON.stringify(item.content.content)
        return this.Translation_YXProblem(item, item.content.content);
      }

      //}else{
      //  return item.content.content
      //}
    },
    TranslationMsgContent_InfoNtf: function TranslationMsgContent_InfoNtf(item) {
      var c = JSON.parse(item.content.content);
      return c.message;
    },
    //转接提示信息
    TranslationMsgContent_TRANSFER: function TranslationMsgContent_TRANSFER(item) {
      var extParam = item.content.extra;
      if (!extParam) {
        extParam = item.content.extParam;
        if (!extParam) {
          return ["转接解析错误:没有找到content.extra或content.extParam", null];
        }
      }
      var userNickname = extParam.userNickname;
      var sessionId = extParam.oldSessionId;
      var remarks = extParam.remarks || "";
      var r = "";
      if (remarks != "") {
        r = "<div style=\"margin-top: 10px;\">\u8F6C\u63A5\u5907\u6CE8:".concat(remarks, "</div>");
      }
      return ["\u6765\u81EA\u5BA2\u670D-".concat(userNickname, "\u7684\u8F6C\u63A5\u7528\u6237"), sessionId, r];
    },
    //  排队消息
    TranslationMsgContent_LineOrOutLine: function TranslationMsgContent_LineOrOutLine(item) {
      var extParam = item.content.extra;
      if (!extParam) {
        extParam = item.content.extParam;
        if (!extParam) {
          return ["转接解析错误:没有找到content.extra或content.extParam", null];
        }
      }
      var sessionId = extParam.sessionId;
      var type = extParam.type;
      return [sessionId, type];
    },
    // 轮询的代码都发到这里
    loopDo: function loopDo() {
      var _this3 = this;
      setTimeout(function () {
        var isDo = _this3.$parent.chatShow;
        // this.isDebug&&console.log("loopDo...isDo:"+isDo)
        //如果隐藏 就空轮询
        if (!isDo) {
          _this3.loopDo();
          return;
        }
        //  返回客服未结束的会话
        var do_getCurrentSessionMemberList = function do_getCurrentSessionMemberList(dataTem) {
          var arr = _this3.conversationList;
          if (arr && arr.length > 0) {
            if (dataTem && dataTem.length && dataTem.length > 0) {
              //如果有在线
              for (var i = 0; i < arr.length; i++) {
                var __isSesson = false;
                if (arr[i].user && arr[i].user.userId) {
                  for (var j = 0; j < dataTem.length; j++) {
                    var id = arr[i].user.userId + "";
                    if (id == dataTem[j]) {
                      __isSesson = true;
                      break;
                    }
                  }
                }
                arr[i].__isSesson = __isSesson;
              }
            } else {
              //如果都不在线
              for (var _i2 = 0; _i2 < arr.length; _i2++) {
                arr[_i2].__isSesson = false;
              }
            }
            _this3.conversationList = JSON.parse(JSON.stringify(arr));
            // console.log("排完序：",JSON.stringify(arr))
          }
        };

        //四个轮询合并的接口
        ///* 
        getServiceResult(_this3.memberId, _this3.identity == "PLATFORM" ? 0 : 1, _this3.$parent.shopId, _this3.$parent.shopType).then(function (response) {
          // console.log('四个轮询合并的接口:', response)

          if (_this3.$processingResData(response)) {
            var data1 = response.data;
            //合并1
            _this3.queueCount = data1.count;
            //合并2
            var serviceStatusForm = data1.serviceStatusForm;
            if (serviceStatusForm) {
              _this3.$store.commit('chat/CHANG_OnlineStatus', serviceStatusForm.serviceStatus);
              _this3.$store.commit('chat/CHANG_OnlineStatusId', serviceStatusForm.id);
            }
            //合并3
            var currentSessionIds = data1.currentSessionIds;
            currentSessionIds && do_getCurrentSessionMemberList(currentSessionIds);
            //合并4
            var recordSessionVo = data1.recordSessionVo;
            recordSessionVo && _this3.checkCurrentSessionStatus_do(recordSessionVo);
          }
          _this3.loopDo();
        }).catch(function (e) {
          console.log("四个轮询合并的接口:", e);
        });
      }, 5000);
    },
    onlineStateChange: function onlineStateChange(s) {
      var pdata = {
        id: this.$store.state.chat.onlineStateId,
        serviceStatus: s,
        storeType: this.identity == "PLATFORM" ? 0 : 1,
        _this: this
      };
      // console.log("onlineStateChange:",pdata)
      if (!this.$store.state.chat.onlineStateId) {
        this.$message.error("切换状态id为空");
        return;
      } else {
        this.$store.dispatch('chat/SetOnlineStatus', pdata);
      }
    },
    // 获取会员用户基本信息
    getUserInfo: function getUserInfo(targetId) {
      var _this4 = this;
      var parameter = {
        id: targetId
      };
      getDetails(parameter).then(function (response) {
        // console.log('会员用户基本信息', response)
        if (_this4.$processingResData(response)) {
          _this4.targetUserInfo.user.img = response.data.img;
        }
      }).catch(function (accident) {
        console.log(accident);
      });
    },
    //点击遮罩层隐藏客服窗口
    hideChatContainer: function hideChatContainer() {
      this.closeChatBox();
    },
    //平台端点击会员头像或昵称，可以跳转到会员详情
    linkVipDetial: function linkVipDetial(id) {
      if (this.userType == 1) {
        this.closeChatBox();
        this.$router.push({
          name: 'Vipdetail',
          params: {
            id: id
          }
        });
      }
    },
    // 聊天自定义商品消息，跳转到商品详情页
    goPage: function goPage(messageType, item, type, pageType) {
      this.closeChatBox();
      var url = '';
      // 商铺
      if (messageType == 'App:YXGoodsMsg') {
        url = this.userType == 1 ? '/productPlat/detailsProduct' : '/product/detailsProduct';
        this.$router.push({
          path: url,
          query: {
            id: item.goodsId,
            type: type,
            pageType: pageType
          }
        });
      } else {
        // 门店
        //  平台
        var _type = 2;
        if (this.userType == 1) {
          _type = 3;
          if (item.serviceMode == 2) {
            url = '/storeManagementPlat/StoreDeliveryGoodsDetailsPlat';
          } else {
            url = '/storeManagementPlat/storeGoodsDetails';
          }
          // 企业端
        } else {
          if (item.serviceMode == 2) {
            url = '/storeManagement/storeDeliveryGoodsDetails';
            _type = 2;
          } else {
            _type = 3;
            url = '/storeManagement/goodsDetail';
          }
        }
        this.$router.push({
          path: url,
          query: {
            id: item.id,
            type: _type,
            status: 1,
            serviceMode: item.serviceMode,
            page: 'goods'
          }
        });
      }
    },
    openList: function openList() {
      var _this5 = this;
      setTimeout(function () {
        if (_this5.identity == 'PLATFORM') {
          _this5.$refs.sideToolBoxPlat.updateOrderList(_this5.memberId);
        } else {
          if (_this5.$parent.shopType == 1) {
            if (_this5.$refs.sideToolBox) {
              _this5.$refs.sideToolBox.updateOrderList();
            }
          } else {
            if (_this5.$refs.sideToolBox2) {
              _this5.$refs.sideToolBox2.updateOrderList();
            }
          }
        }
      }, 1000);
    },
    // 打开聊天窗口右边的买家订单和常用回复
    openBroadsideBox: function openBroadsideBox() {
      this.openBroadside = !this.openBroadside;
      // console.log('this.$refs', this.$refs)
      if (this.openBroadside == false) {
        // this.$refs.chatServiceBox.style.width = '100%'
        // this.$refs.serviceChat.style.width =
        // this.$refs.chatChildrenBox.clientWidth + 'px'

        this.styleResize1 = {
          "height": "705px",
          "width": "790px"
        };
      } else {
        this.styleResize1 = {
          "height": "705px",
          "width": "1193px"
        };
        // this.$refs.serviceChat.style.width =
        // this.$refs.chatChildrenBox.clientWidth + 1 * 478 + 'px'
        // this.$refs.chatServiceBox.style.width = '76%'
        this.openList();
      }
      //展开的时间，重置宽度
      // this.styleResize1={"height":"705px","width":"1193px"}
      this.styleResize2 = {
        "height": "485px"
      };
      this.styleResize3 = {
        "height": "524px"
      };
      this.styleResize4 = {
        "height": "624px"
      };
    },
    handleClick: function handleClick() {},
    // 点击mask关闭我自己发的图片预览
    clickMyMaskClosePreview: function clickMyMaskClosePreview(e, index) {
      // console.log('图片e', this.$refs.MyPreview)
      // 使用addEventListener,会出现点击点击两次才触发的情况
      if (e.target.getAttribute('class') === 'el-image-viewer__mask') {
        this.$refs.MyPreview[index].showViewer = false;
      }
    },
    // 点击mask关闭对方发的图片预览
    clickYourMaskClosePreview: function clickYourMaskClosePreview(e, index) {
      // console.log('图片e', this.$refs.YourPreview)
      // 使用addEventListener,会出现点击点击两次才触发的情况
      if (e.target.getAttribute('class') === 'el-image-viewer__mask') {
        this.$refs.YourPreview[index].showViewer = false;
        // e.target.parentNode.style.visibility = 'hidden';
      }
    },
    // 点击选择图片
    imgFn: function imgFn() {
      var _this6 = this;
      if (!this.isCurrentSessionChat) {
        if (!this.allowedSendMessage) {
          this.$message.error("当前会话已结束");
          return;
        }
      }
      this.chekGetBlacklistFindbyUserId(function () {
        _this6.$refs.file.click();
      });
    },
    imgChange: function imgChange($event) {
      var _this7 = this;
      this.chekGetBlacklistFindbyUserId(function () {
        _this7._imgChange($event);
      });
    },
    _imgChange: function _imgChange($event) {
      if (!this.isCurrentSessionChat) {
        if (!this.allowedSendMessage) {
          this.$message.error("当前会话已结束");
          return;
        }
      }

      // console.log('$event', $event)
      var list = $event.target.files;
      // const list = this.$refs.file.files

      for (var i = 0; i < list.length; i++) {
        if (!this.isContain(list[i])) {
          var item = {
            name: list[i].name,
            size: list[i].size,
            file: list[i]
          };
          this.html5Reader(list[i], item);
          this.imgList.push(item);
        }
      }
      this.$refs.file.value = '';
    },
    // 将图片文件转成BASE64格式
    html5Reader: function html5Reader(list, item) {
      var _this8 = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        // console.log('e', e)
        _this8.$set(item, 'src', e.target.result);
        _this8.imgBase = reader.result; //这个就是base64的编码
        // console.log('this.imgBase', this.imgBase)
        var base64 = e.target.result; //转码过后的base64编码
        // console.log('压缩前', base64.length / 1024)
        //创建一个图片
        var newImage = new Image();
        var quality = 0.6; //压缩系数0-1之间，压缩到0.9以上会有bug，注意！（可以自行设置）
        newImage.src = base64;
        newImage.setAttribute('crossOrigin', 'Anonymous'); //url为外域时需要
        var imgWidth, imgHeight;
        var that = _this8;
        newImage.onload = function () {
          imgWidth = this.width;
          imgHeight = this.height;
          //给生成图片设置一个默认的宽度（可以自行设置）
          var myWidth = 800;
          //准备在画布上绘制图片
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          //判断上传的图片的宽高是否超过设置的默认宽度以及设置同比例的高
          if (Math.max(imgWidth, imgHeight) > myWidth) {
            if (imgWidth > imgHeight) {
              canvas.width = myWidth;
              canvas.height = myWidth * imgHeight / imgWidth;
            } else {
              canvas.height = myWidth;
              canvas.width = myWidth * imgWidth / imgHeight;
            }
          } else {
            canvas.width = imgWidth;
            canvas.height = imgHeight;
          }
          //清空画布
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          //开始绘制图片到画布上
          ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
          var newBase64 = canvas.toDataURL('image/jpeg', quality); //压缩图片大小（重点代码）
          // 获取到当前的图片的大小，然后调整成自己需要的大小，例如说需要小于128KB（可以自行设置，在此小于128kb是因为融云支持的每条消息大小不能128KB）
          while (newBase64.length / 1024 > 128) {
            quality -= 0.02;
            newBase64 = canvas.toDataURL('image/jpeg', quality);
          }
          // console.log('压缩后', newBase64.length / 1024)
          // //截取掉前面的内容“data:image/png;base64”，
          if (newBase64.split(',')[0].indexOf('base64') >= 0) {
            that.imgBaseCode = newBase64.split(',')[1];
            // console.log('that.imgBaseCode', that.imgBaseCode)
          }
          that.uploadImageAPI(item);
        };
        var fileSize = e.total; //不能超过128KB
        // console.log('fileSize', fileSize)
        /*if (fileSize > 128 * 1024) {
        
        }*/
      };
      reader.readAsDataURL(list);
    },
    uploadImageAPI: function uploadImageAPI(item) {
      var _this9 = this;
      if (this.imgList.length > 0) {
        var fileParam = new FormData();
        fileParam.append('file', this.imgList[0].file);
        fileParam.append('fileName', this.imgList[0].name);
        fileParam.append('type', 'shop');
        uploadImage(fileParam).then(function (res) {
          if (res.code === '000000') {
            // console.log('上传图片res', res)
            _this9.imageUrl = res.data.httpUrl;
            // this.$emit(
            //   'fileCallback',
            //   res.data.id,
            //   res.data.httpUrl,
            //   file.name,
            //   this.index
            // )
            _this9.dialogContent = _this9.imageUrl;
            _this9.centerDialogVisible = true;
            // this.sendImgMsgByRongYun(this.imageUrl)
            _this9.$set(item, 'src', '');
            _this9.imgList = [];
            _this9.$forceUpdate();
          } else {
            _this9.$message.error(res.mesg);
          }
        });
      }
    },
    isContain: function isContain(list) {
      return this.imgList.find(function (item) {
        return item.name === list.name && item.size === list.size;
      });
    },
    // 更新客服列表中的在线状态
    updateOnline: function updateOnline() {
      var _this10 = this;
      service.updateOnline({
        userId: this.userId
      }).then(function (res) {
        if (_this10.$processingResData(res)) {}
      });
    },
    // 根据用户获取商铺id
    /*getShopIdByUserId() {
      service.getShopIdByUserId({ userId: this.userId }).then((res) => {
        if (res.code === '000000') {
          // console.log('根据用户获取商铺id', res)
          if (res.data) {
            this.storeId = res.data
            console.log("!!!!!!!!!!!!!!!!!!!!!this.storeId:",this.storeId)
          } else {
            this.storeId = '0'
          }
        } else {
          this.$message({
            type: 'error',
            message: res.mesg,
          })
        }
      })
    },*/
    // 获取融云token
    getUserToken: function getUserToken(resolve, reject) {
      var _this11 = this;
      // console.log('获取融云token getUserTokentoken')
      getToken(this.shopUserId).then(function (res) {
        // console.log('获取融云token',res)
        if (res.code == '000000') {
          _this11.token = res.data;
          resolve();
          if (_this11.token) {
            resolve();
          } else {
            //获取token有误
            _this11.$emit('noService');
          }
        } else {
          //获取token有误
          _this11.$message.error("获取token#" + res.mesg);
          // console.log('客服token有问题')
          _this11.$emit('noService');
          reject();
        }
      });
    },
    // 新 会话列表有变更时触发
    newWatchconversation: function newWatchconversation(event) {
      var _this12 = this;
      // 注: !!!!!!!!!!!! 不能调用this.clearMessageNum()这个方法“清除当前会话未读数”，因为会重复触发 this.im.watch.conversation造成死循环。

      var conversationList = this.conversationList;
      var updatedConversationList = event.updatedConversationList;
      // 通过 this.im.Conversation.merge 计算最新的会话列表
      var latestConversationList = this.im.Conversation.merge({
        conversationList: conversationList,
        updatedConversationList: updatedConversationList
      });
      this.conversationList = latestConversationList;
      //检测有没有新会话,如果有新会话，把用户信息加上去。需要异步请求获取---start
      var newTargetIds = [];
      for (var j = 0; j < updatedConversationList.length; j++) {
        var newItem = updatedConversationList[j];
        var isOk = false;
        for (var i = 0; i < conversationList.length; i++) {
          //借用这个循环：如果打开的当前消息，那么设置当前消息的未读数量为0--start
          if (newItem.targetId == this.targetId) {
            //查找到用户会话，更新它的unreadMessageCount
            for (var k = 0; k < this.conversationList.length; k++) {
              if (this.conversationList[k].targetId == this.targetId) {
                this.conversationList[k].unreadMessageCount = 0;
                var _newItem = JSON.parse(JSON.stringify(this.conversationList[k]));
                this.$set(this.conversationList, k, _newItem);
                break;
              }
            }
            //this.getMessagesNum()
          }
          //借用这个循环：如果打开的当前消息，那么设置当前消息的未读数量为0--end 
          if (this.targetId == newItem.targetId) {
            isOk = true;
            break;
          }
        }
        if (!isOk) {
          newTargetIds.push(newItem.targetId);
        }
      }

      // console.log("newTargetIds:",newTargetIds,newTargetIds.length>0)
      if (newTargetIds.length > 0) {
        var _loop = function _loop() {
          var tid = newTargetIds[_i3];
          getUserByIds(tid).then(function (res) {
            var data = res.data;
            var user = res.data;
            //查找到当前新用户会话，更新它的用户信息
            for (var _k = 0; _k < _this12.conversationList.length; _k++) {
              if (_this12.conversationList[_k].targetId == tid) {
                if (user && user.length == 1) {
                  _this12.conversationList[_k].user = user[0];
                  var _newItem2 = JSON.parse(JSON.stringify(_this12.conversationList[_k]));
                  _this12.$set(_this12.conversationList, _k, _newItem2);
                } else {
                  _this12.isDebug && console.log("7777 ########getUserByIds 追加新增错误 user:", user);
                }
                break;
              }
            }
          });
        };
        for (var _i3 = 0; _i3 < newTargetIds.length; _i3++) {
          _loop();
        }
      }
      //检测有没有新会话,如果有新会话，把用户信息加上去。需要异步请求获取---start
    },
    init: function init() {
      var _this13 = this;
      // console.log('im,,,,',this.im)
      // 初始化
      this.$forceUpdate();
      var that = this;
      // console.log('初始化 init')
      if (window.sessionStorage.getItem('serviceOnline') && window.sessionStorage.getItem('serviceOnline') == 0) {
        this.im.disconnect();
        console.log('因为您的客服账号在其他电脑登录，所以您已被强制下线了');
      } else {
        // console.log('this.token:,,,',this.token)
        // this.im.disconnect()
        this.im.connect({
          token: this.token
        }).then(function (user) {
          // console.log('链接成功, 链接用户: ', user)
          //更新客服列表中的在线状态
          that.updateOnline();
          //获取我自己的用户头像与信息

          getUserByIds(user.id).then(function (res) {
            var data = res.data;
            if (data && data.length > 0) {
              //不准所有用后台的头像：img
              var temData = JSON.parse(JSON.stringify(data[0]));
              temData.img = that.$store.getters.avatar;
              that.myUserInfo = temData;

              //avatar
              // console.log("用户头像xxxxxxxxxxxx:",that.$store.getters.avatar,that.myUserInfo)
            } else {
              console.log("获取我自己的用户头像与信息 错误：", data);
            }
          });
          // console.log('链接成功')
          //成功后获取会话列表
          _this13.getExistedConversationList().then(function (res) {
            // console.log('res:,,,',res)
            if (!res || !res.length) return;
            _this13.conversationList = res;
            _this13.conversationList.length && _this13.getAllMessageByTargetId(_this13.conversationList[0], 0);
            // 打开的时候才能清除会话数
            if (_this13.activeName == _this13.chatIndex) {
              setTimeout(function () {
                _this13.clearMessageNum();
              }, 2000);
            }
          }).catch(function (err) {
            console.log('获取会话失败', err);
          });
        }).catch(function (error) {
          console.log('链接失败: ', error.code, error.msg);
        });
      }
      // console.log('准备监听')
      this.im.unwatch();
      // 添加事件监听
      this.im.watch({
        // 会话列表有变更时触发
        conversation: function conversation(event) {
          that.isDebug && console.log('--------------====会话列表有变更时触发 event', JSON.stringify(event));
          that.newWatchconversation(event);
          // console.log("---====新代码===----------------------------------------------------end")
        },
        // 接收消息时触发
        message: function message(event) {
          // var iN = new Notify().init()
          // var iN = new Notify({
          //   effect: "flash",
          //   interval: 500,
          //   message: "有消息拉！",

          //   notification: {
          //     title: "通知！",
          //     icon: "",
          //     body: "您来了一条新消息",
          //   },
          // })
          //   .setFavicon('悦鑫国际')
          //   .player();

          //弹出chrome通知，不传参数为预设值...
          // iN.notify();

          // iN.notify({
          //   title: "您来了一条新消息",
          //   body: event.message,
          // });

          // iN.setTitle('新通知')//闪烁新标题

          // that.isDebug&&console.log('--------------====接收消息时触发:', JSON.stringify(event))
          // setTimeout(() => { iN.setTitle() }, 5000)
          console.log('--------------====接收消息时触发:', JSON.stringify(event), "message:", event.message.messageType);
          if (event.message && event.message.messageType && event.message.messageType == "RC:RcCmd") {
            //如果有撤回消息，直接刷新
            console.log("接收到撤销..");
            that.getCurrentAllMessage();
            return;
          }
          var message = event.message;
          if (that.targetId == message.targetId) {
            //如果当前聊天 和 发送消息的聊天id一致时
            that.messageByTargetList.push(message);
            that.getUserInfo(event.message.targetId);
            that.checkCurrentSessionStatus();
            if (message.messageType != 'Web:EndMsg') {
              that.$emit('open');
            }
            setTimeout(function () {
              that.keepBottom();
            }, 300);
          } else {}
          var list = that.uniqueJsonByMessageUId(that.messageByTargetList);
          that.messageByTargetList = list;
          // that.$forceUpdate()
          that.$set(that.messageByTargetList);
          // if(!that.chatShow){
          //如果未点进来

          // }

          setTimeout(function () {
            that.clearMessageNum();
          }, 2000);
        },
        // 监听 IM 连接状态变化
        status: function status(event) {
          // console.log('connection status:', event.status)
          if (event.status == 6 && that.userType != 1) {
            window.sessionStorage.setItem('serviceOnline', 0);
            //已经有其他端登录了此账号导致本端被踢，本端是否要重新连接
            that.$emit('showRelink');
          }
          switch (event.status) {
            case RongIMLib.CONNECTION_STATUS.CONNECTED:
              //console.log('链接成功')
              break;
            case RongIMLib.CONNECTION_STATUS.CONNECTING:
              //console.log('正在连接中')
              break;
            case RongIMLib.CONNECTION_STATUS.DISCONNECTED:
              console.log('已主动断开连接');
              break;
            case RongIMLib.CONNECTION_STATUS.NETWORK_UNAVAILABLE:
              // console.log('网络不可用') // SDK 内部会自动进行重连
              that.$message({
                showClose: true,
                message: '客服：网络不可用',
                type: 'warning'
              });
              break;
            case RongIMLib.CONNECTION_STATUS.SOCKET_ERROR:
              // console.log('Socket 链接错误') // SDK 内部会自动进行重连
              that.$message({
                showClose: true,
                message: '客服：Socket 链接错误',
                type: 'warning'
              });
              break;
            case RongIMLib.CONNECTION_STATUS.KICKED_OFFLINE_BY_OTHER_CLIENT:
              // console.log(
              //   '被踢状态',
              //   RongIMLib.CONNECTION_STATUS.KICKED_OFFLINE_BY_OTHER_CLIENT
              // )
              that.$message({
                showClose: true,
                message: '客服：其他设备登录, 本端被踢',
                type: 'warning'
              });
              // console.log('其他设备登录, 本端被踢') // 己端被踢, 不可进行重连. 否则会造成多端循环互踢
              break;
            case RongIMLib.CONNECTION_STATUS.BLOCKED:
              // console.log('链接断开, 用户已被封禁')
              that.$message({
                showClose: true,
                message: '客服：链接断开, 用户已被封禁',
                type: 'warning'
              });
              break;
            default:
              console.log('链接状态变化为:', event.status);
              break;
          }
        },
        expansion: function expansion(event) {
          /**
           * 更新的消息拓展数据
           * @example {
           *    expansion: { key: 'value' },      // 设置或更新的扩展值
           *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
           * }
           */
          //const updatedExpansion = event.updatedExpansion
          /**
           * 删除的消息拓展数据
           * @example {
           *    deletedKeys: ['key1', 'key2'],    // 设置或更新的扩展值
           *    messageUId: 'URIT-URIT-ODMF-DURR' // 设置或更新扩展的消息 uid
           * }
           */
          //const deletedExpansion = event.deletedExpansion
        }
      });
    },
    // 重新开始对话
    reStartSession: function reStartSession(sender, recevier) {
      var _this14 = this;
      if (this.userType == 1) {
        var storeId = '0';
      } else {
        var storeId = this.storeId;
      }
      var data = {
        recevier: recevier,
        sender: sender,
        status: 1,
        storeId: storeId,
        type: 1
      };
      service.startSession(data).then(function (res) {
        if (res.code === '000000') {
          // console.log('客服重新开始会话res', res)
          _this14.endSessionStatus = 0;
          _this14.startSessionTime = res.data.dateTime;
        } else {
          _this14.$message({
            type: 'error',
            message: res.mesg
          });
        }
      });
    },
    sendEndMsg: function sendEndMsg(sender, recevier, msgText) {
      var _this15 = this;
      var data = {
        msgContent: msgText,
        msgType: '1',
        receiver: recevier,
        sender: sender
      };
      service.sendEndMsg(data).then(function (res) {
        if (res.code === '000000') {
          // console.log('发送结束语消息res', res)
        } else {
          _this15.$message({
            type: 'error',
            message: res.mesg
          });
        }
      });
    },
    // 去重messageUId
    uniqueJsonByMessageUId: function uniqueJsonByMessageUId(list) {
      for (var i = 0; i < list.length; i++) {
        for (var j = i + 1; j < list.length;) {
          if (list[i].messageUId == list[j].messageUId) {
            //通过id属性进行匹配；
            list.splice(j, 1); //去除重复的对象；
          } else {
            j++;
          }
        }
      }
      return list;
    },
    getExistedConversationList: function getExistedConversationList() {
      var _this16 = this;
      //获取会话列表
      return new Promise(function (resolve, reject) {
        _this16.im.Conversation.getList({
          count: 30,
          startTime: 0,
          order: 0
        }).then(function (conversationList) {
          // 去重
          conversationList = _this16.uniqueJsonByTargetId(conversationList);
          // console.log("conversationList:",conversationList)
          var userIds = conversationList.map(function (item) {
            return item.targetId;
          });
          // console.log('userIds', userIds)
          if (userIds.length) {
            userIds = userIds.join(',');
            // console.log("########getUserByIds:",userIds)
            //如果有用户
            getUserByIds(userIds).then(function (res) {
              //  console.log("########getUserByIds 返回结果:",res)
              //获取用户信息 头像名字
              var data = res.data;
              conversationList.map(function (item) {
                var user = data.find(function (item2) {
                  return item2.userId == item.targetId;
                });
                if (user) {
                  //如果有用户头像
                  item.user = user;
                  // console.log("########getUserByIds 追加 user:",user)
                }
              });
              // console.log('conversationList',conversationList)
              resolve(conversationList);
            });
          } else {
            //如果没用户
            reject();
          }
        });
      });
    },
    // 去重targetId
    uniqueJsonByTargetId: function uniqueJsonByTargetId(list) {
      var obj = {};
      var arr = list.reduce(function (cur, next) {
        obj[next.targetId] ? '' : obj[next.targetId] = true && cur.push(next);
        return cur;
      }, []);
      return arr;
    },
    //获取未读数
    getMessagesNum: function getMessagesNum() {
      var _this17 = this;
      var that = this;
      this.im.Conversation.getTotalUnreadCount().then(function (totalUnreadCount) {
        //  console.log('获取未读总数成功', totalUnreadCount)
        _this17.$store.commit('chat/set_read_num', {
          value: totalUnreadCount,
          index: _this17.chatIndex
        });
        _this17.$store.dispatch('chat/GetAllCount');
        that.$emit('showChatNum', totalUnreadCount);
        that.totalUnreadCount = totalUnreadCount;
      });
    },
    //清除当前会话未读数
    clearMessageNum: function clearMessageNum() {
      // console.log("清除当前会话未读数:")
      var that = this;
      // console.log(this.targetId)
      var conversation = this.im.Conversation.get({
        targetId: that.targetId,
        type: RongIMLib.CONVERSATION_TYPE.PRIVATE
      });
      conversation.read().then(function () {
        that.getMessagesNum();
      });
    },
    RcCmdDo: function RcCmdDo() {
      var _this18 = this;
      // console.log("RcCmdDo:",this.currentOpenMenuMesg)
      var sentTime = this.currentOpenMenuMesg.sentTime;
      var messageUId = this.currentOpenMenuMesg.messageUId;
      var targetId = this.currentOpenMenuMesg.targetId;
      ///*
      var conversation = this.im.Conversation.get({
        targetId: targetId,
        type: RongIMLib.CONVERSATION_TYPE.PRIVATE
      });
      conversation.recall({
        messageUId: messageUId,
        sentTime: sentTime
      }).then(function (message) {
        _this18.messageByTargetList.splice(_this18.currentOpenMenuMesg_idx, 1);
        _this18.messageByTargetList.push({
          "messageType": "RC:RcCmd",
          "sentTime": +new Date(),
          messageDirection: 1
        });
      }, function (message) {
        // console.log('撤回消息失败', message);
        _this18.$message.error("撤回消息失败");
      }); //*/
      //*/
    },
    //消息去到底部
    keepBottom: function keepBottom() {
      var _this19 = this;
      this.$nextTick(function () {
        // console.log("消息去到底部..")
        if (_this19.$refs.groupContainer && _this19.$refs.groupContainer.scrollTop < _this19.$refs.groupContainer.scrollHeight) {
          _this19.$refs.groupContainer.scrollTop = _this19.$refs.groupContainer.scrollHeight;
        }
      });
    },
    sendTextMessage: function sendTextMessage(e) {
      var _this20 = this;
      // 回车测试
      var messageText = this.messageText;
      var temMessageText = messageText.split("\n").join("").split(" ").join("");
      // console.log("#"+temMessageText+"#")
      if (temMessageText.length == 0) {
        this.messageText = "";
        this.$message.error("不能发空信息");
        return;
      }
      this.chekGetBlacklistFindbyUserId(function () {
        return _this20._sendTextMessage(e);
      });
    },
    sendVideomsg: function sendVideomsg(data) {
      var _this21 = this;
      if (!this.isCurrentSessionChat) {
        if (!this.allowedSendMessage) {
          this.$message.error("当前会话已结束");
          return;
        }
      }
      this.chekGetBlacklistFindbyUserId(function () {
        return _this21._sendVideomsg(data);
      });
    },
    //发送视频消息
    _sendVideomsg: function _sendVideomsg(data) {
      var _this22 = this;
      var conversation = this.im.Conversation.get({
        targetId: this.targetId,
        type: RongIMLib.CONVERSATION_TYPE.PRIVATE
      });
      data.allowedSendMessage = this.allowedSendMessage; //没有地方放。
      conversation.send({
        messageType: RongIMLib.MESSAGE_TYPE.SIGHT,
        // 'RC:SightMsg'
        content: data
      }).then(function (message) {
        _this22.messageByTargetList.push(message);
        console.log('发送小视频消息成功:', message);
        setTimeout(function () {
          _this22.keepBottom();
        }, 300);
      }, function (err) {
        console.log('发送小视频消息失败:', err);
      });
    },
    //发送文字消息
    _sendTextMessage: function _sendTextMessage(e) {
      var _this23 = this;
      if (!this.isCurrentSessionChat) {
        if (!this.allowedSendMessage) {
          this.$message.error("当前会话已结束");
          return;
        }
      }
      this.selfSendMsg = false;
      var targetId = this.targetId;
      var messageText = this.messageText;
      var temMessageText = messageText.split(" ").join("");
      if (temMessageText.length == 0) {
        this.$message.error("内容不能为空");
        this.messageText = "";
        return; // 如果都是空格
      }
      if (!messageText || messageText == "" || !this.targetId) return;
      var isOk = true; //如果键盘快捷发送 是否能发送
      if (e) {
        //如果键盘输入enter的时候
        isOk = false;
        if (e.ctrlKey && e.keyCode == 13) {
          isOk = false;
        } else if (e.shiftKey && e.keyCode == 13) {
          isOk = false;
        } else if (e.keyCode == 13) {
          isOk = true;
        }
      }
      if (!isOk) return;
      // 若使用后端的接口来发送文字消息
      if (this.userId != targetId) {
        var conversation = this.im.Conversation.get({
          targetId: targetId,
          type: RongIMLib.CONVERSATION_TYPE.PRIVATE
        });
        messageText = messageText.split("\n").join("").split("\r").join("");
        conversation.send({
          messageType: RongIMLib.MESSAGE_TYPE.TEXT,
          // 'RC:TxtMsg'
          content: {
            allowedSendMessage: this.allowedSendMessage,
            // 填写开发者定义的消息内容
            content: messageText
          }
        }).then(function (message) {
          // console.log('发送 s:person 文字消息成功', message)
          _this23.messageByTargetList.push(message);
          _this23.msgType = 'text';
          _this23.messageText = '';
          setTimeout(function () {
            _this23.keepBottom();
          }, 300);
          // this.$forceUpdate()
        }).catch(function (res) {
          console.log('发送失败', res);
          // console.log(res)
          if (res.code == 405 && res.msg == 'REJECTED_BY_BLACKLIST') {
            _this23.$message.warning('该用户已被拉黑');
            console.log('该用户已被拉黑');
          }
          _this23.reLink();
        });
      } else {
        // console.log('不能给自己发送消息')
      }
    },
    /*在光标处插入字符串 ,obj文本框对象的id ,str要插入的值*/insertStr: function insertStr(obj, str, newStr) {
      var $input = document.getElementById(obj) || obj;
      var cursurPosition = 0;
      if ($input.selectionStart) {
        //非IE
        cursurPosition = $input.selectionStart;
      } else {
        //IE
        try {
          var range = document.selection.createRange();
          range.moveStart('character', -$input.value.length);
          cursurPosition = range.text.length;
        } catch (e) {
          cursurPosition = 0;
        }
      }
      String.prototype.splice = function (start, insertStr) {
        return this.slice(0, start) + insertStr + this.slice(start);
      };
      return str.splice(cursurPosition, newStr);
    },
    // 往输入框中添加表情
    addEmojiMessage: function addEmojiMessage(emoji) {
      var _this24 = this;
      if (!this.isCurrentSessionChat) {
        if (!this.allowedSendMessage) {
          this.$message.error("当前会话已结束");
          return;
        }
      }
      this.chekGetBlacklistFindbyUserId(function () {
        _this24.messageText = _this24.insertStr('msg-input', _this24.messageText, emoji);
        _this24.emojiPopoverVisible = false;
      });
    },
    //发送图片消息
    sendImageMessage: function sendImageMessage() {
      var _this25 = this;
      var targetId = this.targetId;
      // let isOk = true //如果键盘快捷发送 是否能发送

      if (!this.imgBase || !this.imageUrl || !this.targetId) return;
      if (this.userId != targetId) {
        var temArr = this.imageUrl.split(".");
        var ftype = temArr[temArr.length - 1].toLowerCase();
        //  console.log("发送图片消息:",this.imageUrl,"类型：",ftype)
        if (ftype == "gif") {
          var conversation = this.im.Conversation.get({
            targetId: targetId,
            type: RongIMLib.CONVERSATION_TYPE.PRIVATE
          });
          conversation.send({
            messageType: RongIMLib.MESSAGE_TYPE.GIF,
            // 'RC:GIFMsg'
            content: {
              // gifDataSize:34563,
              // height:246,
              allowedSendMessage: this.allowedSendMessage,
              remoteUrl: this.imageUrl
              // width:263,
            }
          }).then(function (message) {
            // console.log('发送 s:person 图片消息成功', message)
            _this25.msgType = 'image';
            _this25.messageByTargetList.push(message);
            _this25.imgBase = '';
            _this25.imgBaseCode = '';
            _this25.imageUrl = '';
            _this25.messageImage = '';
            _this25.imgList = [];
            setTimeout(function () {
              _this25.keepBottom();
            }, 300);
            _this25.$forceUpdate();
          }).catch(function (res) {
            console.log('发送失败');
            console.log(res);
            _this25.reLink();
          });
        } else {
          var conversation = this.im.Conversation.get({
            targetId: targetId,
            type: RongIMLib.CONVERSATION_TYPE.PRIVATE
          });
          conversation.send({
            messageType: RongIMLib.MESSAGE_TYPE.IMAGE,
            // 'RC:ImgMsg'
            content: {
              allowedSendMessage: this.allowedSendMessage,
              content: this.imgBaseCode,
              // // 压缩后的 base64 略缩图, 用来快速展示图片
              imageUri: this.imageUrl // 上传到服务器的 url. 用来展示高清图片
            }
          }).then(function (message) {
            // console.log('发送 s:person 图片消息成功', message)
            _this25.msgType = 'image';
            _this25.messageByTargetList.push(message);
            _this25.imgBase = '';
            _this25.imgBaseCode = '';
            _this25.imageUrl = '';
            _this25.messageImage = '';
            _this25.imgList = [];
            setTimeout(function () {
              _this25.keepBottom();
            }, 300);
            _this25.$forceUpdate();
          }).catch(function (res) {
            console.log('发送失败');
            console.log(res);
            _this25.reLink();
          });
        }
      } else {
        // console.log('不能给自己发送消息')
      }
    },
    reLink: function reLink() {
      var _this26 = this;
      var that = this;
      this.im.connect({
        token: this.token
      }).then(function (user) {
        that.isDebug && console.log('链接成功, 链接用户信息为: ', user);
        window.sessionStorage.setItem('serviceOnline', 1);
        that.updateOnline();
        //获取我自己的用户头像与信息
        getUserByIds(user.id).then(function (res) {
          var data = res.data;
          // console.log('当前用户信息res', res)
          if (data.length) {
            var temData = JSON.parse(JSON.stringify(data[0]));
            temData.img = that.$store.getters.avatar;
            that.myUserInfo = temData;
          }
        });
        // console.log('链接成功')
        //成功后获取会话列表
        _this26.getExistedConversationList().then(function (res) {
          // console.log('获取会话列表res', res)
          if (!res || !res.length) return;
          _this26.conversationList = res;
          _this26.conversationList.length && _this26.getAllMessageByTargetId(_this26.conversationList[0], 0);
        }).catch(function (err) {
          console.log('获取会话失败', err);
        });
      }).catch(function (error) {
        console.log('链接失败: ', error.code, error.msg);
      });
    },
    dataURLtoBlob: function dataURLtoBlob(dataurl) {
      var arr = [];
      arr[0] = dataurl;
      // console.log('arr', arr)
      var mime = arr[0].match(/:(.*?);/);
      var bstr = atob(arr[0]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: mime
      });
    },
    //点击查看当前会话
    selectCurrentSession: function selectCurrentSession(item, index) {
      var _this27 = this;
      this.getAllMessageByTargetId(item, index);
      setTimeout(function () {
        _this27.clearMessageNum();
      }, 2000);
    },
    //刷新当前历记录消息
    getCurrentAllMessage: function getCurrentAllMessage() {
      this.getAllMessageByTargetId(this.current_getAllMessageByTargetId_item, this.current_getAllMessageByTargetId_index);
    },
    //获取当前用户历史消息
    getAllMessageByTargetId: function getAllMessageByTargetId(item, index) {
      var _this28 = this;
      // console.log("getAllMessageByTargetId:",item, index)
      this.current_getAllMessageByTargetId_item = item;
      this.current_getAllMessageByTargetId_index = index;
      this.clickIndex = index;
      //当前会话用户id 重置
      this.memberId = null;

      //当前会话id 重置
      this.currentSessionId = null;
      this.messageText = "";
      this.currentSessionStatus = false;
      if (item != undefined) {
        this.targetId = item.targetId;
        this.targetUserInfo = item; //用户数据
        // console.log('getAllMessageByTargetId 当前会话用户信息:', item)
        this.currentSessionTargetId = item.targetId;
        item.unreadMessageCount = 0;

        //点击左边会话，显示这个用户状态
        if (this.targetUserInfo && this.targetUserInfo.user) {
          this.memberId = this.targetUserInfo.user.userId;
          if (!this.memberId) {
            this.isDebug && console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!没有找到user.userId属性:", this.targetUserInfo);
            this.$message({
              type: 'error',
              message: "没有找到user.userId属性"
            });
          } else {
            //查询当前用户是不是黑名单
            this.currentSessionUserBlacklist = false;
            getBlacklistFindbyUserId(this.memberId).then(function (res) {
              if (_this28.$processingResData(res)) {
                _this28.currentSessionUserBlacklist = false;
              } else {
                _this28.currentSessionUserBlacklist = true;
              }
            });
            if (this.openBroadside == true) {
              // this.$refs.sideToolBox.updateOrderList()
              ///*
              if (this.identity == 'PLATFORM') {
                this.$refs.sideToolBoxPlat.updateOrderList(this.memberId);
              } else {
                this.$refs.sideToolBox.updateOrderList();
              } //*/
            }
          }
          this.checkCurrentSessionStatus();
        } else {
          this.isDebug && console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!没有找到user属性:", item);
        }
        // console.log("获取当前记录：item.targetId....:",item.targetId)
        var conversation = this.im.Conversation.get({
          targetId: item.targetId,
          type: RongIMLib.CONVERSATION_TYPE.PRIVATE
        });
        var option = {
          timestamp: +new Date(),
          count: 20
        };
        this.messageByTargetList_loading = true;
        conversation.getMessages(option).then(function (result) {
          // console.log(2222222)
          // console.log(result.list)
          _this28.messageByTargetList_loading = false;
          var list = result.list; // 历史消息列表
          var hasMore = result.hasMore; // 是否还有历史消息可以获取
          _this28.messageByTargetList = list;
          // console.log("当前会话信息:",JSON.stringify(this.messageByTargetList))
          _this28.messageByTargetList_hasMore = hasMore;
          setTimeout(function () {
            _this28.keepBottom();
          }, 300);
          // this.$forceUpdate()
        });
      }
    },
    //商铺端获取客服设置
    getServiceSet: function getServiceSet() {
      var _this29 = this;
      if (this.companyId == '') {
        var data = {};
      } else {
        var data = {
          storeId: this.companyId
        };
      }
      // console.log('客服设置data', data)
      service.getServiceSet(data).then(function (res) {
        if (res.code === '000000') {
          // console.log('商铺端获取客服设置res', res)
          _this29.setList = res.data;
        } else {
          _this29.$message({
            type: 'error',
            message: res.mesg
          });
        }
      });
    },
    //当前用户是不是黑名单
    chekGetBlacklistFindbyUserId: function chekGetBlacklistFindbyUserId(callback) {
      if (!this.currentSessionUserBlacklist) {
        callback && callback();
        return;
      } else {
        this.$message({
          type: 'error',
          message: "对方初列入黑名单"
        });
      }
    },
    checkCurrentSessionStatus_do: function checkCurrentSessionStatus_do(dataTem) {
      // this.isDebug&&console.log("获取当前会话在线状态信息 res:",res)
      if (!dataTem.id) {
        this.$message.error("未收到返回当前会话id");
        return;
      }
      if (typeof dataTem.sessionStatus == "undefined") {
        this.$message.error("未收到sessionStatus");
        return;
      }
      this.currentSessionId = dataTem.id;
      this.currentSessionStatus = !!dataTem.sessionStatus;
      this.allowedSendMessage = !!dataTem.allowedSendMessage;
    },
    //获取当前会话在线状态信息
    checkCurrentSessionStatus: function checkCurrentSessionStatus() {
      var _this30 = this;
      // console.log("获取当前会话在线状态信息...checkCurrentSessionStatus..")
      //点击左边会话，显示这个用户状态
      if (!this.memberId) {
        // this.$message.error("当前用户id为空")
        console.log("checkCurrentSessionStatus chek !this.memberId 当前用户id为空");
        return;
      }
      checkSessionStatus(this.memberId, this.$parent.shopId, this.$parent.shopType).then(function (res) {
        // console.log('点击左边会话，显示这个用户状态', res)
        if (_this30.$processingResData(res)) {
          var dataTem = res.data;
          _this30.checkCurrentSessionStatus_do(dataTem);
        }
      }).catch(function (e) {
        _this30.$message.error("请求当前会话服务器未响应.");
        console.log("没有sessionId:" + JSON.stringify(e));
      });
    },
    //平台端获取客服设置
    getServiceSetPlat: function getServiceSetPlat() {
      var _this31 = this;
      service.getServiceSet({
        storeId: 0
      }).then(function (res) {
        if (res.code === '000000') {
          _this31.isDebug && console.log('平台端获取客服设置res', JSON.stringify(res));
          _this31.setPlatList = res.data;
        } else {
          _this31.$message({
            type: 'error',
            message: res.mesg
          });
        }
      });
    },
    // 根据商铺ID,查询客服信息
    getUserInfoByStoreId: function getUserInfoByStoreId() {
      var _this32 = this;
      if (this.companyId == '') {
        var data = '';
      } else {
        var data = {
          storeId: this.companyId
        };
      }
      service.getUserInfoByStoreId(data).then(function (res) {
        if (res.code === '000000') {
          // console.log('商铺端-根据商铺ID,查询客服信息res', res)
          _this32.servicerList = res.data;
        } else {
          _this32.$message({
            type: 'error',
            message: res.mesg
          });
        }
      });
    },
    // 商铺端-更新客服会话状态
    updateSession: function updateSession(sender, status) {
      var _this33 = this;
      var data = {
        recevier: this.userId,
        sender: sender,
        status: status,
        storeId: this.companyId
      };
      service.updateSession(data).then(function (res) {
        if (res.code === '000000') {
          // console.log('商铺端-更新客服会话成功res', res)
        } else {
          _this33.$message({
            type: 'error',
            message: res.mesg
          });
        }
      });
    },
    // 平台端-更新客服会话状态
    updatePlatSession: function updatePlatSession(sender, status) {
      var _this34 = this;
      var data = {
        recevier: this.userId,
        sender: sender,
        status: status,
        storeId: 0
      };
      service.updateSession(data).then(function (res) {
        if (res.code === '000000') {
          // console.log('平台端-更新客服会话成功res', res)
        } else {
          _this34.$message({
            type: 'error',
            message: res.mesg
          });
        }
      });
    },
    // 发送常用语设置里面的内容
    addMsg: function addMsg(msg) {
      var _this35 = this;
      this.chekGetBlacklistFindbyUserId(function () {
        return _this35._addMsg(msg);
      });
    },
    _addMsg: function _addMsg(msg) {
      if (!this.isCurrentSessionChat) {
        if (!this.allowedSendMessage) {
          this.$message.error("当前会话已结束");
          return;
        }
      }
      // console.log('msg', msg)
      this.selfSendMsg = true;
      this.sendMsg(msg);
    },
    // 自动发送消息
    sendMsg: function sendMsg(messageText) {
      var _this36 = this;
      var targetId = this.targetId;
      if (!messageText || !this.targetId) return;
      var conversation = this.im.Conversation.get({
        targetId: targetId,
        type: RongIMLib.CONVERSATION_TYPE.PRIVATE
      });
      conversation.send({
        messageType: RongIMLib.MESSAGE_TYPE.TEXT,
        // 'RC:TxtMsg'
        content: {
          allowedSendMessage: this.allowedSendMessage,
          // 填写开发者定义的消息内容
          content: messageText
        }
      }).then(function (message) {
        // console.log('自动回复消息成功', message)
        _this36.messageByTargetList.push(message);
        setTimeout(function () {
          _this36.keepBottom();
        }, 300);
      }).catch(function (res) {
        console.log('发送失败res', res);
        _this36.reLink();
      });
    },
    //使用融云API发送结束语消息-自定义消息
    sendEndMsgByRongYun: function sendEndMsgByRongYun(messageText) {
      var _this37 = this;
      var targetId = this.targetId;
      if (!messageText || !this.targetId) return;
      var conversation = this.im.Conversation.get({
        targetId: targetId,
        type: RongIMLib.CONVERSATION_TYPE.PRIVATE
      });
      conversation.send({
        messageType: 'Web:EndMsg',
        // 'Web:EndMsg'
        content: {
          allowedSendMessage: this.allowedSendMessage,
          // 填写开发者定义的消息内容
          content: messageText
        }
      }).then(function (message) {
        // console.log('发送结束语消息成功', message)
        _this37.messageByTargetList.push(message);
        _this37.selfSendMsg = false;
        setTimeout(function () {
          _this37.keepBottom();
        }, 300);
      }).catch(function (res) {
        console.log('发送失败res', res);
        _this37.selfSendMsg = false;
        _this37.reLink();
      });
    },
    //使用融云API发送自定义图片消息
    sendImgMsgByRongYun: function sendImgMsgByRongYun(messageImg) {
      var _this38 = this;
      var targetId = this.targetId;
      if (!messageImg || !this.targetId) return;
      var conversation = this.im.Conversation.get({
        targetId: targetId,
        type: RongIMLib.CONVERSATION_TYPE.PRIVATE
      });
      conversation.send({
        messageType: 'Web:ImageMsg',
        // 'Web:ImageMsg'
        content: {
          allowedSendMessage: this.allowedSendMessage,
          // 填写开发者定义的消息内容
          content: messageImg
        }
      }).then(function (message) {
        // console.log('发送自定义图片消息成功', message)
        _this38.messageByTargetList.push(message);
        _this38.selfSendMsg = false;
        _this38.imageUrl = '';
        setTimeout(function () {
          _this38.keepBottom();
        }, 300);
      }).catch(function (res) {
        console.log('发送失败res', res);
        _this38.selfSendMsg = false;
        _this38.reLink();
      });
    },
    // 删除指定会话
    removeConversation: function removeConversation() {
      // conversation 会话实例
      var conversation = this.im.Conversation.get({
        targetId: this.targetId,
        type: RongIMLib.CONVERSATION_TYPE.PRIVATE
      });
      conversation.destory().then(function () {
        return console.log('删除会话成功');
      });
    },
    // 点击隐藏聊天窗口
    closeChatBox: function closeChatBox() {
      this.chatShow = false;
      // this.openBroadside = false
      this.$emit('close'); //*/
    },
    //点击结束对话
    endSession: function endSession() {
      var _this39 = this;
      this.$confirm('是否结束当前与客户的对话信息？', '提示', {
        confirmButtonClass: 'confirmButton',
        confirmButtonText: '结束',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        updateSession(_this39.currentSessionId, 4).then(function (res) {
          if (_this39.$processingResData(res)) {
            //这里要刷新一下当前会话状态
            _this39.checkCurrentSessionStatus();
          }
        });
      });
    },
    //转接
    changSession: function changSession() {
      this.$refs.changSessionPad.open();
    },
    //查看更多聊天记录
    showmoreRecord: function showmoreRecord() {
      var _this40 = this;
      var _this$messageByTarget = this.messageByTargetList[0],
        sentTime = _this$messageByTarget.sentTime,
        receivedTime = _this$messageByTarget.receivedTime;
      // console.log("查看列多聊天记录:",this.timeTranHM(sentTime),this.timeTranHM(receivedTime))
      var conversation = this.im.Conversation.get({
        targetId: this.targetId,
        type: RongIMLib.CONVERSATION_TYPE.PRIVATE
      });
      var option = {
        timestamp: sentTime,
        count: 20
      };
      this.messageByTargetList_loading = true;
      conversation.getMessages(option).then(function (result) {
        console.log(11111111);
        _this40.messageByTargetList_loading = false;
        // console.log("查看更多的聊天记录2:",result)
        var list = result.list; // 历史消息列表
        console.log(list);
        var hasMore = result.hasMore; // 是否还有历史消息可以获取
        _this40.messageByTargetList = list.concat(_this40.messageByTargetList);
        console.log(_this40.messageByTargetList);
        _this40.messageByTargetList_hasMore = hasMore;
      });
    },
    //打开排队的聊天记录
    chatRecordPadOpenLineOrOutLine: function chatRecordPadOpenLineOrOutLine(arr) {
      var _this41 = this;
      var sessionId = arr[0],
        type = arr[1];
      this.chatRecordPadVisible = true;
      // console.log("打开排队的聊天记录:",sessionId,type)
      // console.log("打开排队的聊天记录,当前用户昵称:",this.targetUserInfo.user.nickName)
      getLineUpOrOutLineMsg(sessionId, type).then(function (response) {
        // console.log('返回信息:', JSON.stringify(response.data))
        if (_this41.$processingResData(response)) {
          //加入左右的标识
          var chatRecordPadList = response.data;
          // console.log('打开聊天记录#1:', chatRecordPadList.length)
          for (var i = 0; i < chatRecordPadList.length; i++) {
            //  console.log('打开聊天记录#2:', chatRecordPadList[i].user)
            var nickName = chatRecordPadList[i].user.nickName || "";
            var isLeft = true;
            if (_this41.targetUserInfo.user.nickName == nickName) {
              //如果是当前用户就右
              isLeft = false;
            }
            chatRecordPadList[i].isLeft = isLeft;
          }
          _this41.chatRecordPadList = chatRecordPadList;

          // console.log('最终聊天记录#1:', chatRecordPadList)
        }
      }).catch(function (e) {
        console.log("获取排队数:", e);
      });
    },
    //打开聊天记录
    chatRecordPadOpen: function chatRecordPadOpen(sessionId) {
      var _this42 = this;
      this.chatRecordPadVisible = true;
      getServiceTransferMsg(sessionId).then(function (response) {
        // console.log('打开聊天记录:', JSON.stringify(response.data))
        if (_this42.$processingResData(response)) {
          //加入左右的标识
          var chatRecordPadList = response.data;
          // console.log('打开聊天记录#1:', chatRecordPadList.length)
          for (var i = 0; i < chatRecordPadList.length; i++) {
            //  console.log('打开聊天记录#2:', chatRecordPadList[i].user)
            var nickName = chatRecordPadList[i].user.nickName || "";
            var isLeft = true;
            if (_this42.targetUserInfo.user.nickName == nickName) {
              //如果是当前用户就右
              isLeft = false;
            }
            chatRecordPadList[i].isLeft = isLeft;
          }
          _this42.chatRecordPadList = chatRecordPadList;

          // console.log('最终聊天记录#2:', chatRecordPadList)
        }
      }).catch(function (e) {
        console.log("获取排队数1:", e);
      });
    },
    getChatPad: function getChatPad() {
      var h = this.$createElement;
      return h("div");
    },
    isSystem: function isSystem(item) {
      if (item.messageType == 'RC:InfoNtf') {
        return false;
      }
      if (item.messageType == 'WEB:TRANSFER') {
        return false;
      }
      if (item.messageType == 'WEB:LineOrOutLine') {
        return false;
      }
      return true;
    },
    openMenu: function openMenu(item, idx) {
      var now = new Date().getTime();
      var sentTime = item.sentTime;
      var miao = (now - sentTime) / 1000;
      // console.log("右键了..",item,'与当前时间相差:'+miao+"秒")
      this.currentOpenMenuMesg = item;
      this.currentOpenMenuMesg_idx = idx;
      if (miao <= 120) {
        this.$refs.contextmenu.open();
      }
    }
  },
  watch: {
    chatShow: function chatShow(flag) {
      var _this43 = this;
      if (flag == true) {
        getCountp(this.$parent.shopId, this.$parent.shopType).then(function (response) {
          // console.log('aaa获取排队数###:', response)
          if (_this43.$processingResData(response)) {
            _this43.queueCount = response.data;
          }
        }).catch(function (e) {
          console.log("获取排队数:", e);
        });
        this.updateOnline();
      }
    }
  }
};