/** When your routing table is too long, you can split it into small modules **/
// 客服管理
import Layout from '@/layout';
var serviceRouter = {
  path: '/serviceManagement',
  component: Layout,
  alwaysShow: true,
  redirect: '/serviceManagement/serviceList',
  name: 'serviceManagement',
  meta: {
    title: '客服管理-商铺',
    icon: 'peoples'
  },
  children: [{
    path: '/serviceManagement/serviceList',
    component: function component() {
      return import('@/views/serviceManagement/serviceList');
    },
    name: 'ServiceList',
    meta: {
      title: '客服列表',
      icon: 'tree'
    }
  }, {
    path: '/serviceManagement/chatRecord',
    component: function component() {
      return import('@/views/serviceManagement/chatRecord');
    },
    name: 'ChatRecord',
    meta: {
      title: '会话记录',
      noCache: true,
      activeMenu: '/serviceManagement/serviceList'
    },
    hidden: true
  }, {
    path: '/serviceManagement/serviceSet',
    component: function component() {
      return import('@/views/serviceManagement/serviceSet/index.vue');
    },
    name: 'ServiceSet',
    meta: {
      title: '客服设置',
      icon: 'tree'
    }
  }, {
    path: '/serviceManagement/commonSet',
    component: function component() {
      return import('@/views/serviceManagement/commonSet');
    },
    name: 'CommonSet',
    meta: {
      title: '常用语设置',
      noCache: true,
      activeMenu: '/serviceManagement/serviceSet'
    },
    hidden: true
  }, {
    path: '/serviceManagement/robotServiceSet',
    component: function component() {
      return import('@/views/serviceManagement/robotServiceSet/index.vue');
    },
    name: 'RobotServiceSet',
    meta: {
      title: '机器人设置',
      icon: 'tree'
    }
  }, {
    path: '/serviceManagement/robotServiceSet/knowledgeBaseDetail',
    component: function component() {
      return import('@/views/serviceManagement/robotServiceSet/knowledgeBaseDetail.vue');
    },
    name: 'KnowledgeBaseQuestionDetail',
    meta: {
      title: '知识库问题详情',
      noCache: true,
      icon: 'tree'
    },
    hidden: true
  }, {
    path: '/serviceManagement/group',
    component: function component() {
      return import('@/views/serviceManagementPlat/group/group.vue');
    },
    name: 'ServiceGroupManagement',
    meta: {
      title: '分组管理',
      icon: 'tree'
    }
  }, {
    path: '/serviceManagement/groupManagementTwo',
    component: function component() {
      return import('@/views/serviceManagement/groupManagementTwo');
    },
    name: 'GroupManagementTwo',
    meta: {
      title: '群组管理',
      icon: 'peoples'
    }
  },
  //创建粉丝群
  {
    path: '/serviceManagement/CreateFanBase',
    component: function component() {
      return import('@/views/serviceManagement/CreateFanBase');
    },
    name: 'CreateFanBase',
    meta: {
      title: '创建粉丝群'
    },
    hidden: true
  }, {
    path: '/serviceManagement/userManagementTwo',
    component: function component() {
      return import('@/views/serviceManagement/userManagementTwo');
    },
    name: 'UserManagementTwo',
    meta: {
      title: '用户管理',
      activeMenu: '/serviceManagement/groupManagementTwo'
    },
    hidden: true
  }
  // {
  //   path: '/serviceManagement/ServiceStatistics',
  //   component: () => import('@/views/serviceManagement/serviceStatistics/index.vue'),
  //   name: 'ServiceStatistics',
  //   meta: { title: '客服统计', icon: 'tree' }
  // },
  ]
};
export default serviceRouter;