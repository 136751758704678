/** When your routing table is too long, you can split it into small modules **/
// 站长管理
import Layout from '@/layout';
var limitRouter = {
  path: '/agency',
  component: Layout,
  redirect: '/agency/agencystatistics',
  name: 'Limit',
  meta: {
    title: '站长管理-平台',
    icon: 'monitor'
  },
  children: [{
    path: '/agency/agencystatistics',
    component: function component() {
      return import('@/views/agency/agencystatistics');
    },
    name: 'Agencystatistics',
    meta: {
      title: '站长数据统计',
      icon: 'component'
    }
  }, {
    path: '/agency/agencymanager',
    component: function component() {
      return import('@/views/agency/agencymanager');
    },
    name: 'Agencymanager',
    meta: {
      title: '站长区域管理',
      icon: 'component'
    }
  }, {
    path: '/agency/agencymerchant',
    component: function component() {
      return import('@/views/agency/agencymerchant');
    },
    name: 'Agencymerchant',
    meta: {
      title: '站长账号管理',
      icon: 'component'
    }
  }, {
    path: '/agency/promotion',
    component: function component() {
      return import('@/views/agency/promotion');
    },
    name: 'Promotion',
    meta: {
      title: '推广专员管理',
      icon: 'list'
    }
  }, {
    path: '/agency/agencylevel',
    component: function component() {
      return import('@/views/agency/agencylevel');
    },
    name: 'Agencylevel',
    meta: {
      title: '站长等级设置',
      icon: 'list'
    }
  }, {
    path: '/agency/agencylist',
    component: function component() {
      return import('@/views/agency/agencylist');
    },
    name: 'Agencylist',
    meta: {
      title: '站长审核列表',
      icon: 'list'
    }
  }]
};
export default limitRouter;