// 订单设置
import request from '@/utils/request';

// 获取订单设置
export function getOrderSetting(params) {
  return request({
    url: '/sysadmin/system/dictionary/orderSetting',
    method: 'get',
    params: params
  });
}

// 更新订单设置
export function updateOrderSetting(data) {
  return request({
    url: '/sysadmin/system/dictionary/orderSetting',
    method: 'post',
    data: data
  });
}

//查询-修改全局平台全局分润率
export function overallAPI(data) {
  return request({
    url: '/sysadmin/recommend/reward/proportion/queryAndUpdate/global',
    method: 'post',
    data: data
  });
}

// 新增
export function addOverallAPI(data) {
  return request({
    url: '/sysadmin/recommend/reward/proportion/set/PPCRP',
    method: 'post',
    data: data
  });
}
// 查询平台设置的商品分类分润率列表
export function queryOverallAPI(data) {
  return request({
    url: '/sysadmin/recommend/reward/proportion/page/PPCRP',
    method: 'post',
    data: data
  });
}