var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tool-container" }, [
    _c(
      "div",
      { staticClass: "tool-box" },
      [
        _c(
          "div",
          { staticClass: "title" },
          [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: {
                  "tab-click": function ($event) {
                    return _vm.handleClick(_vm.activeName)
                  },
                },
                model: {
                  value: _vm.activeName,
                  callback: function ($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName",
                },
              },
              [
                _c("el-tab-pane", {
                  attrs: { label: "买家订单", name: "first" },
                }),
                _vm._v(" "),
                _c("el-tab-pane", {
                  attrs: { label: "常用回复", name: "second" },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.activeName === "first"
          ? _c("div", { staticClass: "buyers-order" }, [
              _c(
                "div",
                { staticClass: "buyers-order-tabs" },
                [
                  _c(
                    "el-tabs",
                    {
                      staticStyle: { "margin-left": "10px" },
                      on: {
                        "tab-click": function ($event) {
                          return _vm.handleTabClick(_vm.orderActiveName)
                        },
                      },
                      model: {
                        value: _vm.orderActiveName,
                        callback: function ($$v) {
                          _vm.orderActiveName = $$v
                        },
                        expression: "orderActiveName",
                      },
                    },
                    [
                      _vm._l(_vm.orderOptions, function (item, index) {
                        return [
                          _c("el-tab-pane", {
                            key: index,
                            attrs: {
                              label: item.label + " (" + item.nums + ")",
                              name: item.orderStatus,
                              disabled: _vm.btnLoading,
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.refundOrder
                ? _c("div", [
                    _vm.list.length > 0
                      ? _c("div", { staticClass: "order-details" }, [
                          _c(
                            "div",
                            { staticClass: "order-lists-box" },
                            _vm._l(_vm.list, function (item) {
                              return _c(
                                "div",
                                { key: item.id, staticClass: "order-lists" },
                                [
                                  _c("div", { staticClass: "order" }, [
                                    _c(
                                      "div",
                                      { staticClass: "order-title flex" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "order-status-box flex",
                                          },
                                          [
                                            _c("div", {
                                              staticClass: "order-status-tag",
                                            }),
                                            _vm._v(" "),
                                            item.orderStatus == "1"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      color: "#909299",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "order-status-text",
                                                      },
                                                      [_vm._v("待付款")]
                                                    ),
                                                    _vm._v(
                                                      "\n                        订单将于\n                        " +
                                                        _vm._s(
                                                          item.orderOverTime
                                                        ) +
                                                        "自动关闭\n                      "
                                                    ),
                                                  ]
                                                )
                                              : _c("div", [
                                                  item.orderStatus == "3"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "order-status-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          待发货\n                        "
                                                          ),
                                                        ]
                                                      )
                                                    : item.orderStatus == "5"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "order-status-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          已发货\n                        "
                                                          ),
                                                        ]
                                                      )
                                                    : item.orderStatus == "6"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "order-status-text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          已完成\n                        "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    item.orderStatus != "8"
                                      ? _c(
                                          "div",
                                          { staticClass: "unrefund_order" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "goods-details" },
                                              [
                                                _vm._l(
                                                  item.items,
                                                  function (jtem, jndex) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: jtem.activityId,
                                                        staticClass: "goods",
                                                      },
                                                      [
                                                        _c("img", {
                                                          staticClass: "img",
                                                          attrs: {
                                                            src: jtem.goodsAttrImg,
                                                            alt: "",
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass: "text",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "goods-name two-row-hide",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            " +
                                                                    _vm._s(
                                                                      jtem.goodsName
                                                                    ) +
                                                                    "\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "text-color one-row-hide",
                                                                staticStyle: {
                                                                  "margin-top":
                                                                    "8px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                            商品规格: " +
                                                                    _vm._s(
                                                                      jtem.goodsAttrDetailName
                                                                    ) +
                                                                    "\n                          "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  display:
                                                                    "flex",
                                                                  "justify-content":
                                                                    "space-between",
                                                                  "margin-top":
                                                                    "8px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-color",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-right":
                                                                              "18px",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "数量：" +
                                                                            _vm._s(
                                                                              jtem.nums
                                                                            )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            color:
                                                                              "#1890ff",
                                                                          },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "¥" +
                                                                            _vm._s(
                                                                              jtem.salePrice
                                                                            )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                jndex ==
                                                                item.items
                                                                  .length -
                                                                  1
                                                                  ? _c(
                                                                      "div",
                                                                      {},
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              合计：¥" +
                                                                            _vm._s(
                                                                              item.orderAmount
                                                                            ) +
                                                                            "\n                            "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "price" },
                                                  [
                                                    _c("div", [
                                                      _vm._v("物流费"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "¥" +
                                                          _vm._s(
                                                            item.expressAmount
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "price" },
                                                  [
                                                    _c("div", [
                                                      _vm._v("订单金额"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "¥" +
                                                          _vm._s(
                                                            item.orderAmount
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "price" },
                                                  [
                                                    _c("div", [_vm._v("满减")]),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "¥" +
                                                          _vm._s(
                                                            item.discountAmount
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "price" },
                                                  [
                                                    _c("div", [
                                                      _vm._v("实际付款"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("div", [
                                                      _vm._v(
                                                        "¥" +
                                                          _vm._s(
                                                            item.orderPayAmount
                                                          )
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              2
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "replenish flex" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      cursor: "pointer",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.linkOrderDetail(
                                                          item.orderSn
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        订单编号："
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "#1890ff",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(item.orderSn)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _vm._v(
                                                    "下单时间：" +
                                                      _vm._s(item.createdTime)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pagination-container" },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  "hide-on-single-page": "",
                                  small: "",
                                  "hide-on-pager-count": "",
                                  background: "",
                                  "current-page": _vm.listQuery.current,
                                  "page-sizes": [10, 20, 30, 50],
                                  "page-size": _vm.listQuery.size,
                                  "pager-count": 5,
                                  layout: "prev, pager, next, sizes, jumper",
                                  total: _vm.total,
                                },
                                on: {
                                  "size-change": _vm.handleSizeChange,
                                  "current-change": _vm.handleCurrentChange,
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticClass: "order-details" }, [
                          _c("div", { staticClass: "none-data" }, [
                            _vm._v("暂无相关订单数据"),
                          ]),
                        ]),
                  ])
                : _vm.refundOrder
                ? _c("div", [
                    _vm.arr.length > 0
                      ? _c("div", { staticClass: "order-details" }, [
                          _c(
                            "div",
                            { staticClass: "order-lists-box" },
                            _vm._l(_vm.arr, function (item) {
                              return _c(
                                "div",
                                { key: item.id, staticClass: "order-lists" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "order" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "order-title flex",
                                          staticStyle: {
                                            "justify-content": "flex-start",
                                          },
                                        },
                                        [
                                          _vm._m(0, true),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: { color: "#909299" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.disposeRecord) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        item.saleServiceOrderList,
                                        function (jtem, jndex) {
                                          return _c(
                                            "div",
                                            {
                                              key: jtem.goodsId,
                                              staticClass: "refund_order",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "goods-details",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "goods" },
                                                    [
                                                      _c("img", {
                                                        staticClass: "img",
                                                        attrs: {
                                                          src: jtem.goodsAttrImg,
                                                          alt: "",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        { staticClass: "text" },
                                                        [
                                                          _c("div", [
                                                            _vm._v(
                                                              "\n                            " +
                                                                _vm._s(
                                                                  jtem.goodsName
                                                                ) +
                                                                "\n                          "
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-color",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "8px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            商品规格: " +
                                                                  _vm._s(
                                                                    jtem.goodsAttrDetailName
                                                                  ) +
                                                                  "\n                          "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "justify-content":
                                                                  "space-between",
                                                                "align-items":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "text-color",
                                                                  staticStyle: {
                                                                    "margin-top":
                                                                      "8px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "18px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "数量：" +
                                                                          _vm._s(
                                                                            jtem.goodsSum
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "#1890ff",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "¥" +
                                                                          _vm._s(
                                                                            jtem.goodsPrice
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              jndex ==
                                                              item
                                                                .saleServiceOrderList
                                                                .length -
                                                                1
                                                                ? _c("div", [
                                                                    _vm._v(
                                                                      "\n                              合计：¥" +
                                                                        _vm._s(
                                                                          jtem.refundMoney
                                                                        ) +
                                                                        "\n                            "
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "price" },
                                                    [
                                                      _c("div", [
                                                        _vm._v("订单金额"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _vm._v(
                                                          "¥" +
                                                            _vm._s(
                                                              jtem.refundMoney
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "price" },
                                                    [
                                                      _c("div", [
                                                        _vm._v("实际退款"),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("div", [
                                                        _vm._v(
                                                          "¥" +
                                                            _vm._s(
                                                              jtem.refundMoney
                                                            )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "replenish flex",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        cursor: "pointer",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          return _vm.linkRefundOrderDetail(
                                                            item.id,
                                                            item.type
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        售后订单："
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#1890ff",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.saleServiceSn
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _vm._v(
                                                      "下单时间：" +
                                                        _vm._s(item.createdTime)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "pagination-container" },
                            [
                              _c("el-pagination", {
                                attrs: {
                                  "hide-on-single-page": "",
                                  small: "",
                                  "hide-on-pager-count": "",
                                  background: "",
                                  "current-page": _vm.arrQuery.current,
                                  "page-sizes": [10, 20, 30, 50],
                                  "page-size": _vm.arrQuery.size,
                                  "pager-count": 5,
                                  layout: "prev, pager, next, sizes, jumper",
                                  total: _vm.total,
                                },
                                on: {
                                  "size-change": _vm.handleSizeReturn,
                                  "current-change": _vm.handleCurrentReturn,
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _c("div", { staticClass: "order-details" }, [
                          _c("div", { staticClass: "none-data" }, [
                            _vm._v("暂无相关订单数据"),
                          ]),
                        ]),
                  ])
                : _vm._e(),
            ])
          : _c(
              "div",
              { staticClass: "common-reply" },
              [
                _c(
                  "div",
                  { staticClass: "search-input-box" },
                  [
                    _c("i", {
                      staticClass: "el-icon-search",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleSearch($event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { clearable: "", placeholder: "请输入关键字" },
                      on: { change: _vm.handleSearch },
                      model: {
                        value: _vm.form.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "content", $$v)
                        },
                        expression: "form.content",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.listData, function (item) {
                  return _c(
                    "div",
                    { key: item.catgroyName },
                    [
                      _c("div", { staticClass: "common-title flex" }, [
                        _c(
                          "div",
                          { staticClass: "flex common-title-tag-box" },
                          [
                            _c("div", { staticClass: "common-title-tag" }),
                            _vm._v(
                              "\n              " +
                                _vm._s(item.catgroyName) +
                                "\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "delete",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addCommon(item)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-icon-circle-plus-outline",
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.isHuiFu(item.catgroyName)
                          ? _c(
                              "div",
                              {
                                staticClass: "delete",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.topCommon(item.id, 1)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: { src: require("./top.svg") },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isHuiFu(item.catgroyName)
                          ? _c(
                              "div",
                              {
                                staticClass: "delete",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.editCommon(item, true)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-edit-outline" })]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.isHuiFu(item.catgroyName)
                          ? _c(
                              "div",
                              {
                                staticClass: "delete",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteFastCatgory(item)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm._l(item.replys, function (jtem) {
                        return _c(
                          "div",
                          {
                            key: jtem.content,
                            staticClass: "respond",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.addMsg(jtem)
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "respond-content" }, [
                              _c("div", { staticClass: "respond-item" }, [
                                _vm._v(_vm._s(jtem.content)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "edit",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.topCommon(jtem.id, 2)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: { src: require("./top.svg") },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "edit",
                                staticStyle: {
                                  margin: "0 15px",
                                  "font-size": "14px",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.editCommonS(jtem)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-edit-outline" })]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "delete",
                                staticStyle: { "font-size": "14px" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.handleDelete(jtem)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-delete" })]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.dialogTitle,
              visible: _vm.popupVisible,
              width: "500px",
              "before-close": _vm.closeDialog,
              "append-to-body": "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.popupVisible = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "editForm",
                staticClass: "edit-form",
                attrs: {
                  model: _vm.editForm,
                  rules: _vm.editRules,
                  "label-width": "120px",
                  size: "small",
                },
              },
              [
                !_vm.isEditC
                  ? _c(
                      "el-form-item",
                      { attrs: { label: " 所属分类：", prop: "classify" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            on: {
                              change: function ($event) {
                                return _vm.changeCatgory()
                              },
                            },
                            model: {
                              value: _vm.editForm.classify,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "classify", $$v)
                              },
                              expression: "editForm.classify",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v("已有分类"),
                            ]),
                            _vm._v(" "),
                            !_vm.isEditC
                              ? _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("新增分类"),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isEditC
                  ? _c(
                      "el-form-item",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editForm.classify === 1,
                            expression: "editForm.classify === 1",
                          },
                        ],
                        attrs: { label: "分类名称：", prop: "catgroyId" },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              clearable: "",
                              size: "small",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                            model: {
                              value: _vm.editForm.catgroyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.editForm, "catgroyId", $$v)
                              },
                              expression: "editForm.catgroyId",
                            },
                          },
                          _vm._l(_vm.classifyOptions, function (dict) {
                            return _c("el-option", {
                              key: dict.id,
                              attrs: {
                                label: dict.catgroyName,
                                value: dict.id,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editForm.classify === 2,
                        expression: "editForm.classify === 2",
                      },
                    ],
                    attrs: { label: "分类名称：", prop: "catgroyName" },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        clearable: "",
                        placeholder: "请输入分类名称",
                        maxlength: "8",
                      },
                      on: {
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                      model: {
                        value: _vm.editForm.catgroyName,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "catgroyName", $$v)
                        },
                        expression: "editForm.catgroyName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isEditC
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "常用语内容：", prop: "content" } },
                      [
                        _c("el-input", {
                          staticClass: "text-area",
                          attrs: {
                            clearable: "",
                            type: "textarea",
                            rows: _vm.row,
                            placeholder: "请输入内容",
                          },
                          on: {
                            input: _vm.contentInputChange,
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                          model: {
                            value: _vm.editForm.content,
                            callback: function ($$v) {
                              _vm.$set(_vm.editForm, "content", $$v)
                            },
                            expression: "editForm.content",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "remain-word" }, [
                          _vm._v(
                            _vm._s(_vm.valueLength) +
                              "/" +
                              _vm._s(_vm.maxLength)
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn-list",
                staticStyle: { display: "flex", "justify-content": "center" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "40px" },
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.closeDialog($event)
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.btnLoading,
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.submitForm($event)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.submitBtnText))]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: _vm.dialogTitle,
              visible: _vm.popupVisible_cyu,
              width: "500px",
              "before-close": _vm.closeDialog,
              "append-to-body": "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.popupVisible_cyu = $event
              },
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "editForm",
                staticClass: "edit-form",
                attrs: {
                  model: _vm.editForm,
                  rules: _vm.editRules,
                  "label-width": "120px",
                  size: "small",
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editForm.classify === 1,
                        expression: "editForm.classify === 1",
                      },
                    ],
                    attrs: { label: "分类名称：", prop: "catgroyId" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          clearable: "",
                          size: "small",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                        model: {
                          value: _vm.editForm.catgroyId,
                          callback: function ($$v) {
                            _vm.$set(_vm.editForm, "catgroyId", $$v)
                          },
                          expression: "editForm.catgroyId",
                        },
                      },
                      _vm._l(_vm.classifyOptions, function (dict) {
                        return _c("el-option", {
                          key: dict.id,
                          attrs: { label: dict.catgroyName, value: dict.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "常用语内容：", prop: "content" } },
                  [
                    _c("el-input", {
                      staticClass: "text-area",
                      attrs: {
                        clearable: "",
                        type: "textarea",
                        rows: _vm.row,
                        placeholder: "请输入内容",
                      },
                      on: {
                        input: _vm.contentInputChange,
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                      model: {
                        value: _vm.editForm.content,
                        callback: function ($$v) {
                          _vm.$set(_vm.editForm, "content", $$v)
                        },
                        expression: "editForm.content",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "remain-word" }, [
                      _vm._v(
                        _vm._s(_vm.valueLength) + "/" + _vm._s(_vm.maxLength)
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "btn-list",
                staticStyle: { display: "flex", "justify-content": "center" },
              },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-right": "40px" },
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.closeDialog($event)
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.btnLoading,
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.submitForm($event)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.submitBtnText))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "order-status-box flex" }, [
      _c("div", { staticClass: "order-status-tag" }),
      _vm._v(" "),
      _c("div", { staticClass: "order-status-text" }, [_vm._v("售后")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }