import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { wechatOrderList as _wechatOrderList, wechatOrderListStatus as _wechatOrderListStatus } from '@/api/order/list/fetch';
import Item from "./orderlist/item";
import ItemPhone from "./orderlist/itemPhone";
import ItemPlane from "./orderlist/itemPlane";
import ItemTrain from "./orderlist/itemTrain";
export default {
  name: 'orderlist',
  components: {
    Item: Item,
    ItemPhone: ItemPhone,
    ItemPlane: ItemPlane,
    ItemTrain: ItemTrain
  },
  props: {},
  data: function data() {
    return {
      searchKeyWord: "",
      searchIn: false,
      //确定搜索框的图标，显示或隐藏
      userId: 0,
      // 订单 tab
      activeOrderName: '1',
      //商铺状态
      activeOrderNameShop: '1',
      subTabList: [],
      //tab状态可选列表
      subTabCurrent: '0',
      listData: [],
      pageSize: 5,
      currentPage: 1,
      pageCount: 0,
      total: 0,
      loading: false
    };
  },
  computed: {
    paginationShow: function paginationShow() {
      return this.searchKeyWord == '' && this.pageCount > 0;
    }
  },
  methods: {
    getWidth: function getWidth() {
      if (this.getOs() == "Firefox") {
        if (this.paginationShow) {
          return "96.5%";
        } else {
          return "100%";
        }
      } else {
        if (this.paginationShow) {
          return "99%";
        } else {
          return "100%";
        }
      }
    },
    getOs: function getOs() {
      if (navigator.userAgent.indexOf("MSIE") > 0) {
        return "MSIE";
      }
      if (navigator.userAgent.indexOf("Firefox") > 0) {
        return "Firefox";
      }
      if (navigator.userAgent.indexOf("Safari") > 0) {
        return "Safari";
      }
      if (navigator.userAgent.indexOf("Camino") > 0) {
        return "Camino";
      }
      if (navigator.userAgent.indexOf("Gecko/") > 0) {
        return "Gecko";
      }
    },
    focus: function focus() {
      this.searchIn = true;
    },
    blurSearch: function blurSearch() {
      if (this.searchKeyWord != '') {
        this.searchIn = true;
      } else {
        this.searchIn = false;
      }
    },
    searchData: function searchData() {
      console.log("搜索:", this.searchKeyWord);
      this.currentPage = 1;
      this.wechatOrderList();
    },
    list: function list(userId) {
      if (!userId || userId == "") {
        console.log("用户id不能为空~");
        return;
      }
      // console.log("list:",userId)
      this.userId = userId;
      /* 初始化 data里的变量---start */
      this.searchKeyWord = "";
      this.searchIn = false;
      this.activeOrderName = '1';
      this.activeOrderNameShop = '1';
      this.subTabList = [];
      this.subTabCurrent = '0';
      this.listData = [];
      this.pageSize = 5;
      this.currentPage = 1;
      this.pageCount = 0;
      this.total = 0;
      this.loading = false;
      /* 初始化 data里的变量---end */

      this.wechatOrderListStatus();
      this.wechatOrderList();
    },
    handleClick: function handleClick(tab) {
      if (tab == 1 || tab == 2 || tab == 5) {
        this.wechatOrderListStatus();
      }
      var orderType = parseInt(tab);
      if (orderType == 1 || orderType == 2) {
        //有子tab,默认第一个
        this.subTabCurrent = 0;
      } else if (orderType == 5) {
        this.subTabCurrent = 9;
      }
      this.searchKeyWord = "";
      this.currentPage = 1;
      this.wechatOrderList();
    },
    handleClickSub: function handleClickSub(tab) {
      this.currentPage = 1;
      // console.log("商铺当前订单状：",tab,this.activeOrderNameShop)
      this.wechatOrderList();
    },
    //pageSize改变
    handleSizeChange: function handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.wechatOrderList();
    },
    //当前页码改变
    handleCurrentChange: function handleCurrentChange(val) {
      this.currentPage = val;
      this.wechatOrderList();
    },
    wechatOrderList: function wechatOrderList() {
      var _this = this;
      //初始化
      this.pageCount = 0;
      this.total = 0;
      this.listData = [];
      this.$refs.orderlistsubContiner.scrollTop = 0;
      if (this.userId == "") {
        console.log("用户id不能为空");
        return;
      }
      //店铺 1 门店 2 飞机票 3 火车票 4 话费充值 5
      var orderType = parseInt(this.activeOrderName);
      var data = {
        orderType: orderType,
        userId: this.userId,
        current: this.currentPage,
        size: this.pageSize
      };
      if (orderType == 1 || orderType == 2 || orderType == 5) {
        data.orderStatusType = parseInt(this.subTabCurrent);
      }
      if (this.searchKeyWord != "") {
        //如果是搜索
        data = {
          orderSn: this.searchKeyWord,
          userId: this.userId,
          current: 1
        };
      }

      // orderStatusType:0
      //如果是这几种类型，会要传状态
      this.loading = true;
      _wechatOrderList(data).then(function (res) {
        _this.loading = false;
        //  console.log('订单列表：', res)
        if (res.code === '000000') {
          var records = res.data.records;
          if (!records || records.length == 0) return;

          //如果是搜索，需要根据结果，设置是哪种订单类型
          if (_this.searchKeyWord != "") {
            if (typeof records[0].type == "undefined") {
              _this.$message.error('缺少字段type');
              return;
            }
            _this.activeOrderName = records[0].type + '';
          }
          //toData
          //如果是飞机票，火车票 就要组装一个数据,为排版用
          var activeOrderName = Number(_this.activeOrderName);
          if (activeOrderName == 3) {
            for (var i = 0; i < records.length; i++) {
              var tem = records[i];
              records[i].toData = {
                left: {
                  c1: tem.dptAirport,
                  c2: tem.travelEndTime.substring(0, 5),
                  c3: tem.dptCity
                },
                center: {
                  c1: tem.tripType,
                  c2: tem.startTime + ' ' + tem.weekDay
                },
                right: {
                  c1: tem.arrAirport,
                  c2: tem.travelStartTime.substring(0, 5),
                  c3: tem.arrCity
                }
              };
            }
          }
          if (activeOrderName == 4) {
            for (var _i = 0; _i < records.length; _i++) {
              var _tem = records[_i];
              records[_i].toData = {
                left: {
                  c1: _tem.fromStationName,
                  c2: _tem.departTime.substring(0, 5),
                  c3: ''
                },
                center: {
                  c1: _tem.trainNo,
                  c2: _tem.departDate + ' ' + _tem.departWeekDate
                },
                right: {
                  c1: _tem.toStationName,
                  c2: _tem.arrivalTime.substring(0, 5),
                  c3: ''
                }
              };
            }
          }

          // console.log('订单列表#2：', records)

          _this.listData = records;
          _this.pageCount = res.data.pages;
          _this.currentPage = res.data.current;
          _this.total = res.data.total;
        } else {
          _this.$message.error(res.mesg);
        }
      });
    },
    wechatOrderListStatus: function wechatOrderListStatus() {
      var _this2 = this;
      if (this.userId == "") {
        console.log("用户id不能为空");
        return;
      }
      var data = {
        orderType: parseInt(this.activeOrderName),
        userId: this.userId,
        current: 1,
        size: 10,
        orderSn: ""
      };
      this.loading = true;
      _wechatOrderListStatus(data).then(function (res) {
        _this2.loading = false;
        // console.log('订单状态列表：', res)
        if (res.code === '000000') {
          var arr = res.data;
          var subTabList = [];
          for (var i = 0; i < arr.length; i++) {
            var tem = arr[i];
            subTabList.push({
              nums: tem.nums,
              orderStatus: tem.orderStatus + '',
              //转换字符串，避免报错。
              statusName: tem.statusName
            });
          }
          _this2.subTabList = subTabList;
          _this2.subTabCurrent = _this2.subTabList[0].orderStatus;
        } else {
          _this2.$message.error(res.mesg);
        }
      });
    }
  },
  filters: {
    /* 时间转换 */
    timeTranHM: function timeTranHM(value) {
      return !value ? '-' : tool.date('Y-m-d H:i', value);
    }
  }
};