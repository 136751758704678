// 批发管理
import Layout from '@/layout';
var WholesaleManagement = {
  path: '/WholesaleMan',
  component: Layout,
  redirect: '/WholesaleMan',
  alwaysShow: true,
  name: 'WholesaleMan',
  meta: {
    title: '批发管理',
    icon: 'role'
  },
  children: [{
    path: 'wholesaleDetail',
    component: function component() {
      return import('@/views/storeManagement/purchase/wholesaleDetail');
    },
    name: 'wholesaleDetail',
    meta: {
      title: '进货详情',
      activeMenu: '/storeManagement/wholesaleRecord'
    },
    hidden: true
  }, {
    path: 'onePiece',
    component: function component() {
      return import('@/views/storeManagement/purchase/onePiece');
    },
    name: 'onePiece',
    meta: {
      title: '代发审核',
      activeMenu: '/storeManagement/onePiece'
    }
  }, {
    path: 'onePieceDetail',
    component: function component() {
      return import('@/views/storeManagement/purchase/wholesaleDetail');
    },
    name: 'onePieceDetail',
    meta: {
      title: '审核详情',
      activeMenu: '/storeManagement/onePiece'
    },
    hidden: true
  }, {
    path: 'returnRecord',
    component: function component() {
      return import('@/views/storeManagement/purchase/returnRecord');
    },
    name: 'returnRecord',
    meta: {
      title: '退货退款记录',
      activeMenu: '/storeManagement/purchaseList'
    },
    hidden: true
  }, {
    path: 'returnReview',
    component: function component() {
      return import('@/views/storeManagement/purchase/returnReview');
    },
    name: 'returnReview',
    meta: {
      title: '退货退款审核',
      activeMenu: '/storeManagement/returnReview'
    }
  }]
};
export default WholesaleManagement;