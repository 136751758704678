import Layout from '@/layout';
var orderServiceRouter = {
  path: '/orderService',
  component: Layout,
  redirect: '/order/order-service/drawback',
  name: 'OrderService',
  meta: {
    title: '售后管理',
    icon: 'component'
  },
  children: [{
    path: '/order-service/drawback',
    component: function component() {
      return import('@/views/order/order-service/drawback');
    },
    name: 'drawback',
    meta: {
      title: '退款申请',
      icon: 'tree'
    }
  }, {
    path: '/order-service/returned-goods',
    component: function component() {
      return import('@/views/order/order-service/returned-goods');
    },
    name: 'returnedGoods',
    meta: {
      title: '退货申请',
      icon: 'tree'
    }
  }, {
    path: '/order-service/returnedComplain',
    component: function component() {
      return import('@/views/order/order-service/returned-complain');
    },
    name: 'shopReturnedComplain',
    meta: {
      title: '退货申诉'
    }
  }, {
    path: '/order-service/returnedComplainDetail',
    component: function component() {
      return import('@/views/order/order-service/returned-complain-detail');
    },
    name: 'shopReturnedComplainDetail',
    meta: {
      title: '申诉详情',
      activeMenu: '/order/order-service/returnedComplain',
      noCache: true
    },
    hidden: true
  }, {
    path: '/order-service/changed-goods',
    component: function component() {
      return import('@/views/order/order-service/changed-goods');
    },
    name: 'changedGoods',
    meta: {
      title: '换货申请',
      icon: 'tree'
    }
  }, {
    path: '/order-service/drawback-detail',
    component: function component() {
      return import('@/views/order/order-service/drawback-detail');
    },
    name: 'drawbackDetail',
    meta: {
      // title: '退款详情',
      activeMenu: '/order-service/drawback'
    },
    hidden: true
  }, {
    path: '/order-service/exchangeComplain',
    component: function component() {
      return import('@/views/order/order-service/exchange-complain');
    },
    name: 'ExchangeComplain',
    meta: {
      title: '换货申诉',
      noCache: true,
      icon: 'tree'
    }
  }, {
    path: '/order-service/exchangeComplainDetail',
    component: function component() {
      return import('@/views/orderPlat/order-service/exchangeComplainDetail');
    },
    name: 'ExchangeComplainDetail',
    meta: {
      title: '申诉详情',
      noCache: true,
      activeMenu: '/order-service/exchangeComplain'
    },
    hidden: true
  }, {
    path: '/order-service/returned-goods-detail',
    component: function component() {
      return import('@/views/order/order-service/returned-goods-detail');
    },
    name: 'returnedGoodsDetail',
    meta: {
      // title: '退货详情',
      noCache: true,
      activeMenu: '/order-service/returned-goods'
    },
    hidden: true
  }, {
    path: '/order-service/changed-goods-detail',
    component: function component() {
      return import('@/views/order/order-service/changed-goods-detail');
    },
    name: 'changedGoodsDetail',
    meta: {
      // title: '换货详情',
      activeMenu: '/order-service/changed-goods'
    },
    hidden: true
  }, {
    path: '/order-service/store',
    component: function component() {
      return import('@/views/order/order-service/storeAfter');
    },
    name: 'StoreAftermarket',
    meta: {
      title: '门店售后',
      icon: 'tree'
    }
  }, {
    path: '/order-service/storeSaleDetail',
    component: function component() {
      return import('@/views/orderPlat/order-service/aftermarket-detail');
    },
    name: 'StoreAftermarketDetail',
    meta: {
      title: '门店售后详情',
      activeMenu: '/order-service/store'
    },
    hidden: true
  },
  // {
  //   path: '/order-service/storeDeliveryAfter',
  //   component: () =>
  //     import('@/views/order/order-service/storeDeliveryAfter'),
  //   name: 'StoreDeliveryAfter',
  //   meta: {
  //     title: '配送门店售后',
  //     icon: 'tree'
  //   }
  // },
  {
    path: '/order-service/storeDeliveryAfterDetails',
    component: function component() {
      return import('@/views/order/order-service/storeDeliveryAfterDetails');
    },
    name: 'StoreDeliveryAfterDetails',
    meta: {
      title: '配送门店售后详情',
      icon: 'tree'
    },
    hidden: true
  }]
};
export default orderServiceRouter;