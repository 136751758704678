//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '悦鑫国际',
      logo: require('@/assets/Logo.png'),
      homeRoute: this.$store.state.permission.homeRoute
    };
  }
};