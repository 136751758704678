/** When your routing table is too long, you can split it into small modules **/
// 平台端-溯源
import Layout from '@/layout';
var traceabilityPlatRouter = {
  path: '/traceabilityPlat',
  component: Layout,
  redirect: '/traceabilityPlat/traceabilityList',
  name: 'TraceabilityPlat',
  meta: {
    title: '溯源',
    icon: 'dashboard'
  },
  children: [{
    path: 'traceabilityList',
    component: function component() {
      return import('@/views/traceability/traceabilityListPlat');
    },
    name: 'TraceabilityListPlat',
    meta: {
      title: '商品溯源',
      icon: 'dashboard'
    }
  }, {
    path: 'traceabilityCompany',
    component: function component() {
      return import('@/views/traceability/traceabilityCompany');
    },
    name: 'TraceabilityCompanyPlat',
    meta: {
      title: '溯源企业',
      isCache: true,
      noCache: true,
      activeMenu: '/traceabilityPlat/traceabilityList'
    },
    hidden: true
  }, {
    path: 'traceabilityCode',
    component: function component() {
      return import('@/views/traceability/traceabilityCode');
    },
    name: 'TraceabilityCodePlat',
    meta: {
      title: '溯源码列表',
      noCache: true,
      activeMenu: '/traceabilityPlat/traceabilityList'
    },
    hidden: true
  }, {
    path: 'editTraceability',
    component: function component() {
      return import('@/views/traceability/editTraceability');
    },
    name: 'EditTraceabilityPlat',
    meta: {
      title: '编辑信息',
      isCache: true,
      noCache: true,
      activeMenu: '/traceabilityPlat/traceabilityList'
    },
    hidden: true
  }, {
    path: 'traceabilityInfo',
    component: function component() {
      return import('@/views/traceability/editTraceability');
    },
    name: 'TraceabilityInfoPlat',
    meta: {
      title: '详情',
      noCache: true,
      activeMenu: '/traceabilityPlat/traceabilityList'
    },
    hidden: true
  }]
};
export default traceabilityPlatRouter;