// 内容管理 - 平台端
import Layout from '@/layout';
var contentPlatRouter = {
  path: '/contentPlat',
  component: Layout,
  redirect: '/contentPlat/protocolType',
  name: 'ContentPlat',
  alwaysShow: true,
  meta: {
    title: '内容管理-平台',
    icon: 'documentation'
  },
  children: [{
    path: '/contentPlat/protocolType',
    component: function component() {
      return import('@/views/contentPlat/agreement/protocolType');
    },
    name: 'ProtocolType',
    hidden: true,
    //2.1上线
    meta: {
      title: '协议类型',
      icon: 'documentation'
    }
  }, {
    path: '/contentPlat/list',
    component: function component() {
      return import('@/views/contentPlat/agreement/index');
    },
    name: 'ContentPlatList',
    meta: {
      title: '协议列表',
      icon: 'list'
    }
  }, {
    path: '/contentPlat/addList',
    component: function component() {
      return import('@/views/contentPlat/agreement/component/addList');
    },
    hidden: true,
    name: 'AddList',
    meta: {
      title: '协议',
      isCache: true,
      noCache: true,
      activeMenu: '/contentPlat/list'
    }
  }, {
    path: '/contentPlat/problem',
    component: function component() {
      return import('@/views/contentPlat/problem/index');
    },
    name: 'ContentPlatProblem',
    meta: {
      title: '常见问题',
      icon: 'list'
    }
  }, {
    path: '/contentPlat/addProblem',
    component: function component() {
      return import('@/views/contentPlat/problem/component/addProblem');
    },
    hidden: true,
    name: 'AddProblem',
    meta: {
      isCache: true,
      noCache: true,
      activeMenu: '/contentPlat/problem'
    }
  }, {
    path: '/contentPlat/headline',
    component: function component() {
      return import('@/views/contentPlat/headline/index');
    },
    name: 'ContentPlatHeadline',
    meta: {
      title: '鑫头条',
      icon: 'list'
    }
  }, {
    path: '/contentPlat/addHeadline',
    component: function component() {
      return import('@/views/contentPlat/headline/component/addHeadline');
    },
    hidden: true,
    name: 'AddHeadline',
    meta: {
      isCache: true,
      noCache: true,
      activeMenu: '/contentPlat/headline'
    }
  }, {
    path: '/contentPlat/feedback',
    component: function component() {
      return import('@/views/contentPlat/feedback/index');
    },
    name: 'ContentPlatFeedback',
    meta: {
      title: '意见反馈',
      icon: 'list'
    }
  }, {
    path: '/contentPlat/ticketingList',
    component: function component() {
      return import('@/views/contentPlat/ticketing/list');
    },
    name: 'ContentPlatTicketingList',
    meta: {
      title: '票务说明',
      icon: 'list'
    }
  }, {
    path: '/contentPlat/ticketingCreate',
    component: function component() {
      return import('@/views/contentPlat/ticketing/create');
    },
    hidden: true,
    name: 'ContentPlatTicketingCreate',
    meta: {
      isCache: true,
      noCache: true,
      activeMenu: '/contentPlat/ticketingList'
    }
  }, {
    path: '/contentPlat/otherList',
    component: function component() {
      return import('@/views/contentPlat/other/list');
    },
    name: 'ContentPlatOtherList',
    meta: {
      title: '其他说明',
      icon: 'list'
    }
  }, {
    path: '/contentPlat/otherCreate',
    component: function component() {
      return import('@/views/contentPlat/other/create');
    },
    hidden: true,
    name: 'ContentPlatOtherCreate',
    meta: {
      isCache: true,
      noCache: true,
      activeMenu: '/contentPlat/otherList'
    }
  }, {
    path: '/contentPlat/hotCity',
    component: function component() {
      return import('@/views/contentPlat/hotCity');
    },
    name: 'ContentHotCity',
    meta: {
      title: '热门城市',
      icon: 'list'
    }
  }, {
    path: '/contentPlat/editHot',
    component: function component() {
      return import('@/views/contentPlat/hotCityEdit');
    },
    name: 'ContentHotCityEdit',
    meta: {
      title: '编辑热门城市',
      isCache: true,
      noCache: true,
      activeMenu: '/contentPlat/hotCity'
    },
    hidden: true
  }, {
    path: '/contentPlat/hotDetail',
    component: function component() {
      return import('@/views/contentPlat/hotCityEdit');
    },
    name: 'ContentHotCityDetail',
    meta: {
      title: '热门城市详情',
      noCache: true,
      activeMenu: '/contentPlat/hotCity'
    },
    hidden: true
  }]
};
export default contentPlatRouter;