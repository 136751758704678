//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/*
  单个图片
  @click="$showImage(url)"   //传入url就可以了
  多张图片
*/
export default {
  name: 'ImageView',
  components: {},
  props: {
    imgSrc: {
      type: String,
      default: ''
    }
  },
  watch: {
    dialogImageUrl: function dialogImageUrl() {
      console.log("watch dialogImageUrl:", this.dialogImageUrl);
    }
  },
  data: function data() {
    return {
      imglist: [],
      imglistIdx: 0,
      dialogVisible: false,
      dialogImageUrl: this.imgSrc
    };
  },
  methods: {
    setIdx: function setIdx(url) {
      for (var i = 0; i < this.imglist.length; i++) {
        if (url == this.imglist[i]) {
          this.imglistIdx = i;
          return;
        }
      }
      this.imglistIdx = 0;
    },
    pre: function pre() {
      if (this.imglistIdx - 1 >= 0) {
        this.imglistIdx--;
      }
    },
    nxt: function nxt() {
      if (this.imglistIdx + 1 <= this.imglist.length - 1) {
        this.imglistIdx++;
      }
    }
  }
};