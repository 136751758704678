/** When your routing table is too long, you can split it into small modules **/
// 站长管理
import Layout from '@/layout';
var agentRouter = {
  path: '/agentManagement',
  component: Layout,
  alwaysShow: true,
  redirect: '/agentManagement/agentList',
  name: 'agentManagement',
  meta: {
    title: '站长管理-平台',
    icon: 'peoples'
  },
  children: [{
    path: '/agentManagement/agentList',
    component: function component() {
      return import('@/views/agentManagementPlat/agentList');
    },
    name: 'agentList',
    meta: {
      title: '站长列表',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/fans',
    component: function component() {
      return import('@/views/find2/fans/index');
    },
    name: 'F2SiteFans',
    meta: {
      title: '粉丝列表',
      icon: 'tree'
    },
    hidden: true
  }, {
    path: '/agentManagement/addAgent',
    component: function component() {
      return import('@/views/agentManagementPlat/addAgent');
    },
    name: 'AddAgent',
    meta: {
      title: '新增/编辑站长',
      noCache: true,
      isCache: true,
      activeMenu: '/agentManagement/agentList'
    },
    hidden: true
  }, {
    path: '/agentManagement/agentFlow',
    component: function component() {
      return import('@/views/agentManagementPlat/flowRecord');
    },
    name: 'AgentFlow',
    meta: {
      title: '站长流水记录',
      noCache: true,
      activeMenu: '/agentManagement/agentList'
    },
    hidden: true
  }, {
    path: '/agentManagement/attachList',
    component: function component() {
      return import('@/views/agentManagementPlat/attachList');
    },
    name: 'AttachList',
    meta: {
      title: '招商专员列表',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/flowRecord',
    component: function component() {
      return import('@/views/agentManagementPlat/flowRecord');
    },
    name: 'AgentShopFlowRecord',
    meta: {
      title: '流水记录',
      noCache: true,
      activeMenu: '/agentManagement/attachList'
    },
    hidden: true
  }, {
    path: '/agentManagement/attachWithdraw',
    component: function component() {
      return import('@/views/agentManagementPlat/attachWithdraw');
    },
    name: 'AttachWithdraw',
    meta: {
      title: '招商专员提现',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/withdraw',
    component: function component() {
      return import('@/views/agentManagementPlat/withdrawList');
    },
    name: 'AgentCashManagement',
    meta: {
      title: '提现管理',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/shopList',
    component: function component() {
      return import('@/views/agentManagementPlat/shop/shopList');
    },
    name: 'AgentShopList',
    meta: {
      title: '商铺列表',
      noCache: true,
      activeMenu: '/agentManagement/agentList'
    },
    hidden: true
  }, {
    path: '/agentManagement/shopDetail',
    component: function component() {
      return import('@/views/agentManagementPlat/shop/shopDetail');
    },
    name: 'AgentManagementShopsDetail',
    meta: {
      title: '商铺详情',
      noCache: true,
      activeMenu: '/agentManagement/agentList'
    },
    hidden: true
  }, {
    path: '/agentManagement/storeDetail',
    component: function component() {
      return import('@/views/agentManagementPlat/shop/storeDetail');
    },
    name: 'AgentStoreDetail',
    meta: {
      title: '门店详情',
      noCache: true,
      activeMenu: '/agentManagement/agentList'
    },
    hidden: true
  }, {
    path: '/agentManagement/attachShop',
    component: function component() {
      return import('@/views/agentManagementPlat/shop/shopList');
    },
    name: 'AttachShopList',
    meta: {
      title: '招商专员-商铺列表',
      noCache: true,
      activeMenu: '/agentManagement/attachList'
    },
    hidden: true
  }, {
    path: '/agentManagement/attachShopDetail',
    component: function component() {
      return import('@/views/agentManagementPlat/shop/shopDetail');
    },
    name: 'AttachShopDetail',
    meta: {
      title: '招商专员-商铺详情',
      noCache: true,
      activeMenu: '/agentManagement/attachList'
    },
    hidden: true
  }, {
    path: '/agentManagement/attachStoreDetail',
    component: function component() {
      return import('@/views/agentManagementPlat/shop/storeDetail');
    },
    name: 'AttachStoreDetailPlat',
    meta: {
      title: '招商专员-门店详情',
      noCache: true,
      activeMenu: '/agentManagement/attachList'
    },
    hidden: true
  }, {
    path: '/agentManagement/levelSet',
    component: function component() {
      return import('@/views/agentManagementPlat/agentLevelSet');
    },
    name: 'LevelSet',
    meta: {
      title: '站长等级设置',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/withdrawSet',
    component: function component() {
      return import('@/views/agentManagementPlat/withdrawSet');
    },
    name: 'WithdrawSet',
    meta: {
      title: '设置管理',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/areaList',
    component: function component() {
      return import('@/views/agentManagementPlat/areaList');
    },
    name: 'AreaList',
    meta: {
      title: '区域管理',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/auditList',
    component: function component() {
      return import('@/views/agentManagementPlat/auditList');
    },
    name: 'AuditList',
    meta: {
      title: '站长审核',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/set',
    component: function component() {
      return import('@/views/find2');
    },
    name: 'F2FindSet',
    meta: {
      title: '内容设置',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/contentListSite/index',
    component: function component() {
      return import('@/views/find2/contentListSite/index');
    },
    name: 'F2ContentListSite',
    meta: {
      title: '站长风采'
    }
  }, {
    path: '/agentManagement/contentList/index',
    component: function component() {
      return import('@/views/find2/contentList/index');
    },
    name: 'F2ContentList',
    meta: {
      title: '运营内容',
      noCache: true
    },
    hidden: true
  }, {
    path: '/agentManagement/like',
    component: function component() {
      return import('@/views/find2/like');
    },
    name: 'F2Like',
    meta: {
      title: '点赞列表'
    },
    hidden: true
  }, {
    path: '/agentManagement/comment',
    component: function component() {
      return import('@/views/find2/comment/comment');
    },
    name: 'F2Comment',
    meta: {
      title: '内容评论',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/content',
    component: function component() {
      return import('@/views/find2/classify/content');
    },
    name: 'F2Classify',
    meta: {
      title: '内容分类',
      icon: 'tree'
    }
  }, {
    path: '/agentManagement/lexicon',
    component: function component() {
      return import('@/views/find2/lexicon/list');
    },
    name: 'F2Lexicon',
    meta: {
      title: '违禁词库',
      icon: 'tree'
    }
  }]
};
export default agentRouter;