/** When your routing table is too long, you can split it into small modules **/
// 站长端-首页
import Layout from '@/layout';
var agencyHomeRouter = {
  path: '/agency',
  component: Layout,
  redirect: '/agency/home',
  name: 'Agency',
  meta: {
    title: '首页-站长',
    icon: 'dashboard'
  },
  children: [{
    path: 'home',
    component: function component() {
      return import('@/views/agencyHome/home');
    },
    name: 'AgencyHome',
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: true
    }
  }]
};
export default agencyHomeRouter;