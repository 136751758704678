var api = {
  state: {
    // 注册中心
    // nacosServer: 'http://localhost:8848/nacos',
    // sentinelDashboard: 'http://localhost:8021',
    // mossServer: 'http://localhost:8022',
    nacosServer: '127.0.0.1:8848/nacos',
    sentinelDashboard: '127.0.0.1:8021',
    mossServer: '127.0.0.1:8022',
    // swagger
    swaggerApi: '/swagger-ui.html'
  }
};
export default api;