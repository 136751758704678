/** When your routing table is too long, you can split it into small modules **/
// 店铺管理
import Layout from '@/layout';
var shopmanageRouter = {
  path: '/shopmanage',
  component: Layout,
  redirect: '/shopmanage/shoplist',
  name: 'Shopmanage',
  meta: {
    title: '商铺管理-商铺',
    icon: 'shopping'
  },
  children: [{
    path: '/shop/shopInfo',
    component: function component() {
      return import('@/views/shopModulePlatform/editShop');
    },
    name: 'ShopInfoDetail',
    meta: {
      title: '商铺详情',
      noCache: true
    }
  }, {
    path: '/shop/flowRecord',
    component: function component() {
      return import('@/views/shopmanage/flowRecord');
    },
    name: 'ShopFlowRecord',
    meta: {
      title: '流水记录',
      noCache: true
    }
    // hidden: true
  }, {
    path: '/shop/addShop',
    component: function component() {
      return import('@/views/shopModulePlatform/editShop');
    },
    name: 'CreateShop',
    meta: {
      title: '创建商铺',
      noCache: true,
      activeMenu: '/shop/shopInfo'
    },
    hidden: true
  }, {
    path: '/shop/editShop',
    component: function component() {
      return import('@/views/shopModulePlatform/editShop');
    },
    name: 'ShopInfoEdit',
    meta: {
      title: '编辑商铺',
      noCache: true,
      activeMenu: '/shop/shopInfo'
    },
    hidden: true
  }, {
    path: '/shopmanage/shopclass',
    component: function component() {
      return import('@/views/shopmanage/shopclass');
    },
    name: 'Shopclass',
    meta: {
      title: '商铺分类',
      icon: 'tree'
    }
  }, {
    path: 'withdrawdeposit',
    component: function component() {
      return import('@/views/shopmanage/withdrawdeposit.vue');
    },
    name: 'Withdrawdeposit',
    meta: {
      title: '提现列表',
      icon: 'money',
      activeMenu: '/shop/flowRecord'
    },
    hidden: true
  }, {
    path: 'collectionaccount',
    component: function component() {
      return import('@/views/shopmanage/collectionaccount.vue');
    },
    name: 'Collectionaccount',
    meta: {
      title: '收款账号',
      noCache: true,
      activeMenu: '/shopmanage/withdrawdeposit'
    },
    hidden: true
  }, {
    path: '/shopmanage/bobyTypeList',
    component: function component() {
      return import('@/views/shopmanage/bobyTypeList');
    },
    name: 'bobyTypeList',
    meta: {
      title: '宝贝分类',
      icon: 'shopping'
    }
  },
  // {
  //   path: '/shopmanage/subAccountList',
  //   component: () => import('@/views/shopmanage/subAccountList'),
  //   name: 'subAccountList',
  //   meta: {
  //     title: '子账号管理',
  //     icon: 'shopping'
  //   }
  // },
  // {
  //   path: '/shopmanage/role',
  //   component: () => import('@/views/shopmanage/roleList'),
  //   name: 'roleList',
  //   meta: {
  //     title: '角色管理',
  //     icon: 'shopping'
  //   }
  // },
  {
    path: '/shopmanage/applyCategories',
    component: function component() {
      return import('@/views/shopmanage/applyCategories');
    },
    name: 'ApplyCategories',
    meta: {
      title: '申请分类',
      icon: 'shopping'
    }
  }]
};
export default shopmanageRouter;