import Layout from '@/layout';
var orderRouter = {
  path: '/order',
  component: Layout,
  redirect: '/order/order-list',
  name: 'Order',
  meta: {
    title: '订单管理',
    icon: 'component'
  },
  children: [{
    path: '/order/order-list',
    component: function component() {
      return import('@/views/order/order-list');
    },
    name: 'List',
    meta: {
      title: '订单列表',
      icon: 'list'
      // noCache: true
    }
  }, {
    path: '/order-detail',
    component: function component() {
      return import('@/views/order/order-list/order-detail');
    },
    name: 'orderDetail',
    meta: {
      activeMenu: '/order/order-list'
    },
    hidden: true
  }, {
    path: '/order-traver',
    component: function component() {
      return import('@/views/order/order-list/component/order-traver');
    },
    name: 'orderTraver',
    meta: {
      // title: '订单追踪',
    },
    hidden: true
  }, {
    path: '/order-cancel',
    component: function component() {
      return import('@/views/order/order-list/order-cancel');
    },
    name: 'orderCancel',
    meta: {
      // title: '取消订单',
    },
    hidden: true
  }, {
    path: '/lot-size-order-out',
    component: function component() {
      return import('@/views/order/order-list/lot-size-order-out');
    },
    name: 'orderLotSizeOrderOut',
    meta: {
      // title: '批量发货',
      activeMenu: '/order/order-list'
    },
    hidden: true
  },
  // {
  //   path: '/order/traceability',
  //   component: () => import('@/views/order/order-list/traceability'),
  //   name: 'OrderTraceability',
  //   meta: {
  //     title: '商品列表',
  //     activeMenu: '/order/order-list'
  //   },
  //   hidden: true
  // },
  {
    path: '/order/order-apply',
    component: function component() {
      return import('@/views/order/order-apply/index');
    },
    name: 'orderApply',
    meta: {
      title: '发票申请',
      icon: 'role'
    }
  }]
};
export default orderRouter;