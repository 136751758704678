// 招商专员-企业审核

import Layout from '@/layout';
var attractCompanyRouter = {
  path: '/attractEnterpriseAudit',
  component: Layout,
  name: 'AttractEnterpriseAudit',
  meta: {
    title: '企业审核',
    icon: 'role'
  },
  children: [{
    path: 'attractEnterpriseAuditList',
    component: function component() {
      return import('@/views/replacemanage/attractEnterpriseAuditList.vue');
    },
    name: 'AttractEnterpriseAuditList',
    meta: {
      title: '企业审核'
    }
  }, {
    path: 'attractEnterpriseAuditAdd',
    component: function component() {
      return import('@/views/replacemanage/attractEnterpriseAuditAdd.vue');
    },
    name: 'AttractEnterpriseAuditAdd',
    meta: {
      title: '新增',
      noCache: true,
      isCache: true,
      activeMenu: '/attractEnterpriseAudit/AttractEnterpriseAuditList'
    },
    hidden: true
  }, {
    path: 'attractEnterpriseAuditUpdate',
    component: function component() {
      return import('@/views/replacemanage/attractEnterpriseAuditUpdate.vue');
    },
    name: 'AttractEnterpriseAuditUpdate',
    meta: {
      title: '重新审核',
      noCache: true,
      activeMenu: '/attractEnterpriseAudit/AttractEnterpriseAuditList'
    },
    hidden: true
  }]
};
export default attractCompanyRouter;