var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "rightPanel",
        staticClass: "rightPanel-container",
        class: { show: _vm.show },
      },
      [
        _c("div", { staticClass: "rightPanel-background" }),
        _vm._v(" "),
        _c("div", { staticClass: "rightPanel" }, [
          _vm.serviceStatus
            ? _c(
                "div",
                {
                  directives: [{ name: "mdrag", rawName: "v-mdrag" }],
                  ref: "chatButton",
                  staticClass: "chat-button",
                  class: [
                    _vm.theme == "#cccccc" ? "chat-downline" : "chat-online",
                    _vm.isAnimation ? "dd" : "",
                  ],
                  style: { top: _vm.buttonTop + 70 + "px" },
                  on: {
                    click: function ($event) {
                      return _vm.setChatShow()
                    },
                  },
                },
                [
                  _c("svg-icon", { attrs: { "icon-class": "kefu" } }),
                  _vm._v(" "),
                  _c("el-badge", {
                    staticClass: "item",
                    class: [_vm.theme == "#cccccc" ? "downline-num" : ""],
                    attrs: {
                      value: _vm.chatNum,
                      max: 99,
                      hidden: _vm.chatNum == 0,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "rightPanel-items" },
            [_vm._t("default")],
            2
          ),
        ]),
        _vm._v(" "),
        _vm.serviceStatus
          ? [
              _c("Chat", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.chatShow,
                    expression: "chatShow",
                  },
                ],
                ref: "serviceChat",
                on: {
                  open: function ($event) {
                    _vm.openTime == 0
                      ? (_vm.chatShow = true)
                      : (_vm.chatShow = false)
                  },
                  close: _vm.closeChat,
                  showChatNum: _vm.showChatNum,
                  showRelink: _vm.showRelink,
                  noService: _vm.noService,
                },
              }),
            ]
          : _vm._e(),
        _vm._v(" "),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "客服消息",
              visible: _vm.serviceVisible,
              width: "30%",
              "before-close": _vm.handleClose,
            },
            on: {
              "update:visible": function ($event) {
                _vm.serviceVisible = $event
              },
            },
          },
          [
            _c("span", [
              _vm._v(
                "您的客服账号在其他电脑登录，您已被强制下线。如非您本人登录，请您尽快修改账号密码，是否重连客服？"
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        ;(_vm.serviceVisible = false), _vm.unRelink()
                      },
                    },
                  },
                  [_vm._v("关闭连接")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        ;(_vm.serviceVisible = false),
                          (_vm.serviceVisibleNoTip = true)
                      },
                    },
                  },
                  [_vm._v("不用提示了")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.isReLine != 0 },
                    on: {
                      click: function ($event) {
                        _vm.relink(), (_vm.isReLine = 3)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "重新连接 " +
                        _vm._s(_vm.isReLine == 0 ? "" : _vm.isReLine) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }