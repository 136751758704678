import Layout from '@/layout';
var walletPlatRouter = {
  path: '/walletPlat',
  component: Layout,
  redirect: '/walletPlat/list',
  // 我们暂时不需要重定向
  alwaysShow: true,
  name: 'walletPlat',
  meta: {
    title: '钱包-平台端',
    icon: 'component'
  },
  children: [{
    path: 'walletPlat/list',
    component: function component() {
      return import('@/views/walletPlat/walletList.vue');
    },
    name: 'WalletPlatList',
    meta: {
      title: '钱包',
      icon: 'list'
    }
  }, {
    path: 'walletPlat/fenrun',
    component: function component() {
      return import('@/views/walletPlat/fenrun.vue');
    },
    name: 'fenrun',
    meta: {
      title: '平台收入',
      icon: 'list'
    }
  }]
};
export default walletPlatRouter;