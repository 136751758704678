var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderlist-item-container" }, [
    _c("div", { staticClass: "row1" }, [
      _c("div", { staticClass: "title" }, [
        _c("img", {
          staticClass: "s-ico",
          attrs: { src: require("./svg/dp.svg") },
        }),
        _vm._v(" " + _vm._s(_vm.data.shopName) + "\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "stat" }, [
        _vm._v("\n      " + _vm._s(_vm.data.orderStatusName) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row1" }, [
      _c(
        "div",
        {
          staticClass: "sn",
          staticStyle: { width: "250px", float: "left", display: "block" },
        },
        [
          _vm._v(
            _vm._s(_vm.data.orderOrSaleType == 2 ? "售后订单：" : "订单号：") +
              "\n      "
          ),
          _c(
            "span",
            {
              staticClass: "goDetails",
              on: {
                click: function ($event) {
                  return _vm.goDetails(_vm.data, _vm.data.orderOrSaleType)
                },
              },
            },
            [_vm._v(_vm._s(_vm.data.orderSn))]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.data.orderOrSaleType == 2
        ? _c(
            "div",
            {
              staticStyle: {
                width: "179px",
                float: "right",
                "text-align": "right",
              },
            },
            [_vm._v("提交时间：" + _vm._s(_vm.data.createdTime))]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.data.items && _vm.data.items.length == 1
      ? _c("div", { staticClass: "row1" }, [
          _c(
            "div",
            {
              staticClass: "img-container",
              staticStyle: { cursor: "pointer" },
              on: {
                click: function ($event) {
                  return _vm.goGDetails(
                    _vm.data,
                    _vm.data.items[0],
                    _vm.isStore
                  )
                },
              },
            },
            [
              !_vm.isStore
                ? _c("div", {
                    staticClass: "img",
                    style:
                      "background-image: url(" +
                      _vm.data.items[0].goodsAttrImg +
                      "?x-oss-process=image/resize,m_fixed,h_75,w_75);",
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isStore
                ? _c("div", {
                    staticClass: "img",
                    style:
                      "background-image: url(" +
                      _vm.data.items[0].coverUrl +
                      "?x-oss-process=image/resize,m_fixed,h_75,w_75);",
                  })
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "txt-container", staticStyle: { width: "80%" } },
            [
              _c("div", { staticClass: "row2" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.data.items[0].goodsName || _vm.data.items[0].name
                      ) +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row2" }, [
                !_vm.isStore
                  ? _c("div", { staticClass: "atr" }, [
                      _vm._v(
                        "\n          ￥" +
                          _vm._s(_vm.data.items[0].goodsPrice) +
                          "x " +
                          _vm._s(_vm.data.items[0].nums) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.isStore
                  ? _c("div", { staticClass: "atr" }, [
                      _c(
                        "div",
                        { staticStyle: { float: "left", width: "120px" } },
                        [
                          _vm._v(
                            "￥" +
                              _vm._s(_vm.data.items[0].salePrice) +
                              "x" +
                              _vm._s(_vm.data.items[0].nums)
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            width: "100px",
                            float: "right",
                            "text-align": "right",
                            color: "#000",
                          },
                        },
                        [
                          _vm._v(
                            " 合计 ￥" +
                              _vm._s(_vm.data.items[0].salePrice) +
                              "\n          "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.data.items && _vm.data.items.length > 1
      ? _c(
          "div",
          { staticClass: "row1" },
          [
            _vm._l(_vm.data.items, function (item, idx) {
              return _c("div", { key: idx, staticClass: "img-container" }, [
                idx < 4
                  ? _c("div", {
                      staticClass: "img",
                      style:
                        "background-image: url(" +
                        item.goodsAttrImg +
                        ";cursor: pointer;",
                      on: {
                        click: function ($event) {
                          return _vm.goGDetails(_vm.data, item, false)
                        },
                      },
                    })
                  : _vm._e(),
              ])
            }),
            _vm._v(" "),
            _c("div", { staticClass: "txt-container-arg" }, [
              _c("div", [_vm._v("￥" + _vm._s(_vm.data.items[0].goodsPrice))]),
              _vm._v(" "),
              _c("div", [_vm._v("x " + _vm._s(_vm.data.items.length))]),
            ]),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row1" }, [
      _c("div", { staticClass: "label" }, [_vm._v("订单金额")]),
      _vm._v(" "),
      _c("div", { staticClass: "v" }, [
        _vm._v(
          "￥" +
            _vm._s(_vm.data.orderAmount) +
            _vm._s(_vm.data.points ? "+积分:" + _vm.data.points + "" : "")
        ),
      ]),
    ]),
    _vm._v(" "),
    !_vm.isStore && _vm.data.orderOrSaleType != 2
      ? _c("div", { staticClass: "row1" }, [
          _c("div", { staticClass: "label" }, [_vm._v("物流费")]),
          _vm._v(" "),
          _c("div", { staticClass: "v" }, [
            _vm._v("￥" + _vm._s(_vm.data.expressAmount)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.data.orderOrSaleType != 2
      ? _c("div", { staticClass: "row1" }, [
          _c("div", { staticClass: "label" }, [_vm._v("优惠")]),
          _vm._v(" "),
          _c("div", { staticClass: "v" }, [
            _vm._v(
              _vm._s(
                _vm.data.couponAmount ? "-￥" + _vm.data.couponAmount : "￥0"
              )
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row1" }, [
      _c("div", { staticClass: "label" }, [_vm._v("实际付款")]),
      _vm._v(" "),
      _c("div", { staticClass: "v" }, [
        _vm._v("￥" + _vm._s(_vm.data.orderPayAmount)),
      ]),
    ]),
    _vm._v(" "),
    _vm.data.orderOrSaleType == 1
      ? _c("div", { staticClass: "row1 down-line" }, [
          _c("div", { staticClass: "downt" }, [
            _vm._v("下单时间：" + _vm._s(_vm.data.createdTime)),
          ]),
          _vm._v(" "),
          _vm.data.automaticEndTime
            ? _c("div", { staticClass: "downtip" }, [
                _vm._v(
                  "订单将于" + _vm._s(_vm.data.automaticEndTime) + " 自动关闭"
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }