// 商铺端-门店管理
import Layout from '@/layout';
var storeManagement = {
  path: '/storeManagement',
  component: Layout,
  redirect: '/storeManagement',
  alwaysShow: true,
  name: 'StoreManagement',
  meta: {
    title: '门店管理',
    icon: 'role'
  },
  children: [{
    path: 'storeList',
    component: function component() {
      return import('@/views/storeManagementPlat/storeList/storeList');
    },
    name: 'enStoreList',
    meta: {
      title: '门店列表',
      activeMenu: '/storeManagement/storeList'
    }
  }, {
    path: 'supplierList',
    component: function component() {
      return import('@/views/storeManagement/supplier/index');
    },
    name: 'supplierList',
    meta: {
      title: '供应商列表',
      activeMenu: '/storeManagement/supplierList'
    }
  }, {
    path: 'addSupplier',
    component: function component() {
      return import('@/views/storeManagement/supplier/addSupplier');
    },
    name: 'addSupplier',
    meta: {
      title: '新增供应商',
      activeMenu: '/storeManagement/purchaseList'
    },
    hidden: true
  }, {
    path: 'wholesalePage',
    component: function component() {
      return import('@/views/storeManagement/goodsList/component/wholesale');
    },
    name: 'wholesalePage',
    meta: {
      title: '批发进货 ',
      activeMenu: '/storeManagement/wholesalePage'
    },
    hidden: true
  }, {
    path: 'wholesaleRecord',
    component: function component() {
      return import('@/views/storeManagement/purchase/wholesaleRecord');
    },
    name: 'wholesaleRecord',
    meta: {
      title: '进货记录 ',
      activeMenu: '/storeManagement/wholesaleRecord'
    }
  }, {
    path: 'editSupplier',
    component: function component() {
      return import('@/views/storeManagement/supplier/addSupplier');
    },
    name: 'editSupplier',
    meta: {
      title: '编辑供应商',
      activeMenu: '/storeManagement/purchaseList'
    },
    hidden: true
  }, {
    path: 'purchaseList',
    component: function component() {
      return import('@/views/storeManagement/purchase/index');
    },
    name: 'purchaseList',
    meta: {
      title: '采购列表',
      activeMenu: '/storeManagement/purchaseList'
    }
  }, {
    path: 'addPurchase',
    component: function component() {
      return import('@/views/storeManagement/purchase/addProduct');
    },
    name: 'addPurchase',
    meta: {
      title: '新增采购',
      activeMenu: '/storeManagement/purchaseList'
    },
    hidden: true
  }, {
    path: 'editPurchase',
    component: function component() {
      return import('@/views/storeManagement/purchase/addProduct');
    },
    name: 'editPurchase',
    meta: {
      title: '编辑采购',
      activeMenu: '/storeManagement/purchaseList'
    },
    hidden: true
  }, {
    path: 'pusrchaseShopList',
    component: function component() {
      return import('@/views/storeManagement/purchase/shopList');
    },
    name: 'pusrchaseShopList',
    meta: {
      title: '采购记录',
      activeMenu: '/storeManagement/purchaseList'
    },
    hidden: true
  }, {
    path: 'addLogList',
    component: function component() {
      return import('@/views/storeManagement/purchase/addLogProduct');
    },
    name: 'addLogList',
    meta: {
      title: '新增采购记录',
      activeMenu: '/storeManagement/purchaseList'
    },
    hidden: true
  }, {
    path: 'editLogList',
    component: function component() {
      return import('@/views/storeManagement/purchase/addLogProduct');
    },
    name: 'editLogList',
    meta: {
      title: '编辑采购记录',
      activeMenu: '/storeManagement/purchaseList'
    },
    hidden: true
  },
  // 门店列表 
  {
    path: 'storeList',
    component: function component() {
      return import('@/views/storeManagement/storeList/list');
    },
    name: 'ShopStoreList',
    meta: {
      title: '门店列表',
      icon: 'peoples'
    }
  }, {
    path: 'storeCreate',
    component: function component() {
      return import('@/views/storeManagement/storeList/create');
    },
    name: 'ShopStoreCreate',
    meta: {
      title: '创建门店',
      activeMenu: '/storeManagement/storeList'
    },
    hidden: true
  }, {
    path: 'lot-size-order-out-new',
    component: function component() {
      return import('@/views/order/order-list/lot-size-order-out-new');
    },
    name: 'orderFh',
    meta: {
      title: '订单发货',
      activeMenu: '/storeManagement/storeList'
    },
    hidden: true
  }, {
    path: 'storeUpdate',
    component: function component() {
      return import('@/views/companyPlat/editStore');
    },
    name: 'ShopStoreUpdate',
    meta: {
      title: '编辑门店',
      activeMenu: '/storeManagement/storeInfo'
    },
    hidden: true
  }, {
    path: 'storeInfo',
    component: function component() {
      return import('@/views/companyPlat/editStore');
    },
    name: 'StoreDetail',
    meta: {
      title: '门店信息'
    }
  }, {
    path: 'storeRecord',
    component: function component() {
      return import('@/views/storeManagement/storeList/record');
    },
    name: 'ShopStoreRecord',
    meta: {
      title: '流水记录'
    }
    // hidden: true
  }, {
    path: 'storeWithdraw',
    component: function component() {
      return import('@/views/storeManagement/storeList/record');
    },
    name: 'ShopStoreWithdraw',
    meta: {
      title: '提现列表',
      activeMenu: '/storeManagement/storeRecord'
    },
    hidden: true
  }, {
    path: 'storeShroffAccount',
    component: function component() {
      return import('@/views/shopmanage/collectionaccount');
    },
    name: 'ShopStoreShroffAccount',
    meta: {
      title: '收款账号',
      activeMenu: '/storeManagement/storeRecord'
    },
    hidden: true
  },
  // 门店订单
  {
    path: 'storeOrder',
    component: function component() {
      return import('@/views/storeManagement/storeOrder/listCopyNewName');
    },
    name: 'ShopStoreOrder',
    meta: {
      title: '门店订单',
      icon: 'peoples'
    }
  }, {
    path: 'storeOrderDetail',
    component: function component() {
      return import('@/views/storeManagement/storeOrder/detail');
    },
    name: 'ShopStoreOrderDetail',
    meta: {
      title: '订单详情',
      activeMenu: '/storeManagement/storeOrder'
    },
    hidden: true
  },
  // 商品分类
  {
    path: 'goodsClassify',
    component: function component() {
      return import('@/views/storeManagement/goodsClassify/list');
    },
    name: 'ShopGoodsClassify',
    meta: {
      title: '商品分类',
      icon: 'peoples'
    }
  },
  // 上架申请
  {
    path: 'applyForList',
    component: function component() {
      return import('@/views/storeManagement/goodsApplyFor/list');
    },
    name: 'ShopApplyForList',
    meta: {
      title: '上架申请',
      icon: 'peoples'
    }
  }, {
    path: 'applyForCreate',
    component: function component() {
      return import('@/views/storeManagement/goodsApplyFor/create');
    },
    name: 'ShopApplyForCreate',
    hidden: true,
    meta: {
      title: '新增商品上架',
      noCache: true,
      isCache: true,
      activeMenu: '/storeManagement/applyForList'
    }
  }, {
    path: 'applyForUpdate',
    component: function component() {
      return import('@/views/storeManagement/goodsApplyFor/create');
    },
    name: 'ShopApplyForUpdate',
    meta: {
      title: '编辑商品上架',
      noCache: true,
      isCache: true,
      activeMenu: '/storeManagement/applyForList'
    },
    hidden: true
  }, {
    path: 'applyForDetail',
    component: function component() {
      return import('@/views/storeManagement/goodsApplyFor/detail');
    },
    name: 'ShopApplyForDetail',
    meta: {
      title: '审核详情',
      noCache: true,
      activeMenu: '/storeManagement/applyForList'
    },
    hidden: true
  },
  // 商品列表
  {
    path: 'goodsList',
    component: function component() {
      return import('@/views/storeManagement/goodsList/list');
    },
    name: 'ShopGoodsList',
    meta: {
      title: '商品列表',
      icon: 'peoples'
    }
  }, {
    path: 'goodsCreate',
    component: function component() {
      return import('@/views/storeManagement/goodsList/create');
    },
    name: 'ShopGoodsCreate',
    meta: {
      title: '新增商品',
      activeMenu: '/storeManagement/goodsList'
    },
    hidden: true
  }, {
    path: 'goodsUpdate',
    component: function component() {
      return import('@/views/storeManagement/goodsList/create');
    },
    name: 'ShopGoodsUpdate',
    meta: {
      title: '编辑商品',
      noCache: true,
      isCache: true,
      activeMenu: '/storeManagement/goodsList'
    },
    hidden: true
  }, {
    path: 'storeDeliveryGoodsAdd',
    component: function component() {
      return import('@/views/storeManagement/goodsList/addEdite/addProduct');
    },
    name: 'StoreDeliveryGoodsAdd',
    meta: {
      title: '新增商品',
      activeMenu: '/storeManagement/goodsList'
    },
    hidden: true
  }, {
    path: 'storeDeliveryGoodsEdit',
    component: function component() {
      return import('@/views/storeManagement/goodsList/addEdite/updateProduct');
    },
    name: 'StoreDeliveryGoodsEdit',
    meta: {
      title: '编辑商品',
      noCache: true,
      isCache: true,
      activeMenu: '/storeManagement/goodsList'
    },
    hidden: true
  },
  // views/storeManagement/goodsList/detail
  {
    path: 'goodsDetail',
    component: function component() {
      return import('@/views/storeManagement/goodsList/detail');
    },
    name: 'ShopGoodsDetail',
    meta: {
      title: '商品详情',
      activeMenu: '/storeManagement/goodsList'
    },
    hidden: true
  }, {
    path: 'storeDeliveryGoodsDetails',
    component: function component() {
      return import('@/views/storeManagement/goodsList/storeDeliveryGoodsDetails');
    },
    name: 'StoreDeliveryGoodsDetails',
    meta: {
      title: '商品详情',
      activeMenu: '/storeManagement/goodsList'
    },
    hidden: true
  }, {
    path: 'storeDelivery/commentList',
    component: function component() {
      return import('@/views/storeManagement/goodsComment/list');
    },
    name: 'goodsConmenList',
    meta: {
      title: '商品评论',
      activeMenu: '/storeManagement/goodsList'
    },
    hidden: true
  }, {
    path: 'storeDelivery/commentDetail',
    component: function component() {
      return import('@/views/storeManagement/goodsComment/commentDetails');
    },
    name: 'goodsDetailsConmen',
    meta: {
      title: '评论详情',
      activeMenu: '/storeManagement/goodsList'
    },
    hidden: true
  },
  // 门店外卖订单
  {
    path: 'deliveryOrder',
    component: function component() {
      return import('@/views/storeManagement/deliveryOrder/list');
    },
    name: 'DeliveryOrder',
    meta: {
      title: '订单详情',
      icon: 'peoples'
    },
    hidden: true
  },
  // 门店外卖详情
  {
    path: 'deliveyrDetail',
    component: function component() {
      return import('@/views/storeManagement/deliveryOrder/detail');
    },
    name: 'SDeliveryOrderDetail',
    meta: {
      title: '订单详情',
      activeMenu: '/storeManagement/storeOrder'
    },
    hidden: true
  }, {
    path: 'shopGoodsLog',
    component: function component() {
      return import('@/views/storeManagementPlat/storeGoods/storeGoodsLog');
    },
    name: 'ShopGoodsLog',
    hidden: true,
    meta: {
      title: '商品日志',
      activeMenu: '/storeManagement/goodsList'
    }
  },
  // 手动核销 
  {
    path: 'couponCancellationResult',
    component: function component() {
      return import('@/views/storeManagement/couponCancellation/result');
    },
    name: 'ShopCouponCancellationResult',
    meta: {
      title: '手动核销',
      icon: 'peoples'
    }
  },
  // 核销记录 
  {
    path: 'couponCancellationRecord',
    component: function component() {
      return import('@/views/storeManagement/couponCancellation/record');
    },
    name: 'ShopCouponCancellationRecord',
    meta: {
      title: '核销记录',
      noCache: true,
      icon: 'peoples'
    }
  },
  // 回收站
  {
    path: 'storeManagementRecycle',
    component: function component() {
      return import('@/views/storeManagement/recycle/list');
    },
    name: 'StoreManagementRecycle',
    meta: {
      title: '回收站',
      icon: 'peoples'
    }
  },
  // 草稿箱
  {
    path: 'storeManagementDrafts',
    component: function component() {
      return import('@/views/storeManagement/drafts/list');
    },
    name: 'StoreManagementDrafts',
    meta: {
      title: '草稿箱',
      icon: 'peoples'
    }
  },
  // 库存预警
  {
    path: 'storeManagementGoodsInventory',
    component: function component() {
      return import('@/views/storeManagement/goodsInventory/list');
    },
    name: 'StoreManagementGoodsInventory',
    meta: {
      title: '库存预警',
      noCache: true,
      isCache: true,
      icon: 'peoples',
      AttC: 0
    }
  },
  // 预警设置
  {
    path: 'storeManagementSetWarning',
    component: function component() {
      return import('@/views/storeManagement/goodsInventory/setWarning');
    },
    name: 'StoreManagementSetWarning',
    meta: {
      title: '预警设置',
      icon: 'peoples',
      activeMenu: '/storeManagementPlat/storeManagementGoodsInventory'
    },
    hidden: true
  }, {
    path: 'storeAnnouncement',
    component: function component() {
      return import('@/views/storeManagement/storeAnnouncement/list');
    },
    name: 'StoreAnnouncement',
    meta: {
      title: '门店公告',
      icon: 'peoples'
      //,noCache: true 
    }
  }]
};
export default storeManagement;