var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker" },
    [
      _c("el-date-picker", {
        attrs: {
          type: "daterange",
          "value-format": "yyyy-MM-dd HH:mm:ss",
          "default-time": ["00:00:00", "23:59:59"],
          "picker-options": _vm.pickerOptions,
          "range-separator": "至",
          "start-placeholder": "开始日期",
          "end-placeholder": "结束日期",
        },
        on: { change: _vm.handleSearch },
        model: {
          value: _vm.dateValue,
          callback: function ($$v) {
            _vm.dateValue = $$v
          },
          expression: "dateValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }