/** When your routing table is too long, you can split it into small modules **/
// 平台端-交易数据
import Layout from '@/layout';
var platStatisticsRouter = {
  path: '/platStatistics',
  component: Layout,
  redirect: '/platStatistics/statistics',
  name: 'PlatStatistics',
  meta: {
    title: '交易数据-平台',
    icon: 'dashboard'
  },
  hidden: true,
  children: [{
    path: 'statistics',
    component: function component() {
      return import('@/views/platHome/statistics');
    },
    name: 'PlatStatisticsCenter',
    meta: {
      title: '交易数据',
      icon: 'dashboard'
    },
    hidden: true
  }]
};
export default platStatisticsRouter;