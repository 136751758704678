//
//
//
//
//
//
//

import base64Black from "./black.js";
import { videoSnapshot } from '@/utils/videoSnapshot.js';
export default {
  name: 'videoitem',
  props: ['item'],
  data: function data() {
    return {
      bakimg: 'data:image/jpg;base64,' + base64Black
    };
  },
  mounted: function mounted() {
    //通过视频播放,截图.
    /*videoSnapshot(this.item.content.sightUrl,2,false).then((data)=>{
        this.bakimg=data
    })*/
    this.bakimg = "data:image/jpg;base64," + this.item.content.content;
    // console.log("mounted item 通过视频播放:",this.item.content)
  },
  watch: {
    /*item(p,old){
      videoSnapshot(this.item.content.sightUrl,2).then((data)=>{
        this.bakimg=data
      })
    }*/
  },
  methods: {
    c1: function c1() {
      this.$emit("clickme");
    }
  }
};