// 招商专员-商铺列表

import Layout from '@/layout';
var attractpersonnelRouter = {
  path: '/attractpersonnel',
  component: Layout,
  redirect: '/attractpersonnel/shopslist',
  name: 'Attractpersonnel',
  meta: {
    title: '商铺列表',
    icon: 'role',
    noCache: true
  },
  children: [{
    path: 'shopslist',
    component: function component() {
      return import('@/views/replacemanage/shopslist.vue');
    },
    name: 'AttractShopList',
    meta: {
      title: '商铺列表',
      icon: 'peoples'
    }
  },
  // {
  //   path: 'particulars',
  //   component: () => import('@/views/replacemanage/shopsdetailchildren/particulars.vue'),
  //   name: 'Attractparticulars',
  //   meta: { title: '流水信息', noCache: true, activeMenu: '/attractpersonnel/shopslist' },
  //   hidden: true
  // },
  {
    path: 'shopDetail',
    component: function component() {
      return import('@/views/replacemanage/shopDetail.vue');
    },
    name: 'AttractShopDetail',
    meta: {
      title: '商铺详情',
      noCache: true,
      activeMenu: '/attractpersonnel/shopslist'
    },
    hidden: true
  }, {
    path: 'storeDetail',
    component: function component() {
      return import('@/views/replacemanage/storeDetail.vue');
    },
    name: 'AttachStoreDetail',
    meta: {
      title: '门店详情',
      noCache: true,
      activeMenu: '/attractpersonnel/shopslist'
    },
    hidden: true
  }, {
    path: 'companyDetail',
    component: function component() {
      return import('@/views/companyPlat/companyDetail.vue');
    },
    name: 'AttachCompanyDetail',
    meta: {
      title: '企业详情',
      noCache: true,
      activeMenu: '/attractpersonnel/shopslist'
    },
    hidden: true
  }, {
    path: 'addshops',
    component: function component() {
      return import('@/views/replacemanage/addCompany.vue');
    },
    name: 'Attractaddshops',
    meta: {
      title: '新增企业',
      noCache: true,
      activeMenu: '/attractpersonnel/shopslist'
    },
    hidden: true
  }]
};
export default attractpersonnelRouter;